import React from 'react';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import { countryNames } from '../../../utils/countrylist';

export function GeoMap({ setTooltipContent, data, isDesktop, progress }) {
  const getRandomColor = name => {
    const color = countryNames.find(r =>
      name.includes(r.name.toLowerCase())
    )?.color;

    return color;
  };

  const filterCountry = name => {
    let value = progress?.find(x =>
      name.toLowerCase().includes(x.country.toLowerCase())
    );
    setTooltipContent(`${name} ${value ? Math.round(value.count) : '0'}%`);
  };

  const getCountry = name => {
    let findCountry = progress?.find(x =>
      name?.toLowerCase().includes(x.country.toLowerCase())
    );
    if (name?.toLowerCase().includes(findCountry?.country.toLowerCase())) {
      return name;
    }
  };

  const getColour = name => {
    let country = getCountry(name);
    if (country === name) {
      return getRandomColor(name.toLowerCase());
    } else {
      return 'rgba(160, 182, 243, 0.48)';
    }
  };

  return (
    <div data-tip=''>
      <ComposableMap
        projectionConfig={{
          center: isDesktop ? [10, -150] : [15, -100],
          scale: isDesktop ? 150 : 115,
        }}
      >
        <Geographies geography={data}>
          {({ geographies }) =>
            geographies.map(geo => (
              <Geography
                key={geo.rsmKey}
                geography={geo}
                onMouseEnter={() => {
                  filterCountry(`${geo.properties.name}`);
                }}
                onMouseLeave={() => {
                  filterCountry('');
                }}
                style={{
                  default: {
                    fill: getColour(`${geo.properties.name}`),
                    stroke: '0.124791px solid rgba(160, 182, 243, 0.48)',
                    strokeWidth: 0.75,
                    outline: 'none',
                  },
                  hover: {
                    fill: getColour(`${geo.properties.name}`),
                    stroke: '#adb5bd',
                    strokeWidth: 0.75,
                    outline: 'none',
                  },
                  // pressed: {
                  //   fill: '#3f6ad8',
                  //   stroke: '#adb5bd',
                  //   strokeWidth: 0.75,
                  //   outline: 'none',
                  // },
                }}
              />
            ))
          }
        </Geographies>
      </ComposableMap>
    </div>
  );
}
