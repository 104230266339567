import {
  Avatar,
  Box,
  Button,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTheme } from '@mui/material/styles';
import TokenModal from './tokenModal';
import MouseOverPopover from '../popover';

export function InputToken({
  currency,
  value,
  setValue,
  list,
  readOnly,
  setCurrency,
  web3States,
  fromUsdRate,
  valueToSent,
  fromCurrency,
  setSwitchState,
  switchState,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between(320, 480));
  const [open, setOpen] = useState(false);

  React.useEffect(() => {
    const unloadCallback = event => {
      event.preventDefault();
      event.returnValue = '';
      return '';
    };
    window.addEventListener('beforeunload', unloadCallback);
    return () => window.removeEventListener('beforeunload', unloadCallback);
  }, [open]);

  return (
    <div>
      <TokenModal
        list={list}
        setOpen={setOpen}
        open={open}
        setCurrency={setCurrency}
        readOnly={readOnly}
        setSwitchState={setSwitchState}
        switchState={switchState}
      />
      <div
        style={{
          display: 'flex',
          background: '#222141',
          height: isMobile ? '3.5rem' : '2.4rem',
          justifyContent: 'space-around',
          alignItems: 'center',
          borderRadius: '8px',
          width: '90%',
          margin: 'auto',
          padding: isMobile && '10px',
        }}
      >
        <TextField
          variant='standard'
          InputProps={{
            disableUnderline: true,
            readOnly: Boolean(readOnly) || currency.includes('Select'),
            style: { fontSize: 12, width: isMobile ? '46vw' : '16vw' },
          }}
          value={value}
          onChange={e => setValue(e.target.value)}
          type='number'
        />
        <Button
          onClick={() => {
            setOpen(true);
          }}
          disabled={fromCurrency?.includes('Select') && readOnly}
          sx={{
            background:
              'linear-gradient(0deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12)), linear-gradient(0deg, rgba(242, 243, 245, 0.16), rgba(242, 243, 245, 0.16))',
            color: 'white',
            border: '0.5px solid #0000001F',
            borderRadius: '8px',
            height: isMobile ? '45px' : '29px',
            padding: '6px 10px 6px 10px',
            minWidth: '5vw',
            fontSize: isMobile ? '14px' : '10px',
            position: 'relative',
            left: '10px',
            '&:hover': {
              background:
                'linear-gradient(0deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12)), linear-gradient(0deg, rgba(242, 243, 245, 0.16), rgba(242, 243, 245, 0.16))',
              color: 'white',
              border: '0.5px solid #0000001F',
              height: '29px',
              borderRadius: '8px',
            },
          }}
          endIcon={<KeyboardArrowDownIcon sx={{ marginLeft: '0px' }} />}
          startIcon={
            !currency.includes('Select') && (
              <Avatar
                src={`./images/crypto-icons/${currency.toLocaleLowerCase()}.svg`}
                alt='icon'
                sx={{ width: '25px', height: '25px' }}
              />
            )
          }
          variant='contained'
        >
          {currency}
        </Button>
      </div>
      <Box
        component='div'
        sx={{
          display: 'flex',
          width: '88%',
          justifyContent: 'space-between',
          margin: isMobile ? '8px 20px 8px 20px' : 'auto',
        }}
      >
        <MouseOverPopover
          value={
            !currency.includes('Select') && value
              ? fromUsdRate * parseFloat(readOnly ? valueToSent : value)
              : '0.00'
          }
        >
          <Typography
            sx={{
              fontSize: '10px',
              opacity: '0.5',
              lineHeight: '17px',
              letterSpacing: '0em',
            }}
          >
            $
            {!currency.includes('Select') && value
              ? (
                  fromUsdRate * parseFloat(readOnly ? valueToSent : value)
                ).toFixed(2)
              : '0.00'}
          </Typography>
        </MouseOverPopover>

        {!readOnly && (
          <MouseOverPopover
            value={
              web3States.coin &&
              web3States.coin.toLowerCase() === currency.toLowerCase()
                ? `${web3States.balanceWei}${web3States.coin}`
                : `0.00`
            }
          >
            <Typography
              sx={{
                display: readOnly && 'none',
                fontSize: '10px',
                lineHeight: '17px',
                letterSpacing: '0em',
                textAlign: 'right',
                opacity: '0.5',
              }}
            >
              Balance:{' '}
              {web3States.coin &&
              web3States.coin.toLowerCase() === currency.toLowerCase()
                ? `${parseFloat(web3States.balanceWei).toFixed(4)}${
                    web3States.coin
                  }`
                : `0.00`}
            </Typography>
          </MouseOverPopover>
        )}
      </Box>
    </div>
  );
}
