import React from 'react';

import BigNumber from 'bignumber.js';
import { Avatar, Box, Typography, Stack } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

// interface AssetProps {
//   addressAsset: AddressAsset;
//   chainId?: number;
// }

const getAssetValue = ({ quantity, asset }) => {
  // if (chainId === 56 || chainId === 97) {
  //   let price = quantity ? parseFloat(quantity) / 10 ** 18 : 0;
  //   return price;
  // } else {
  //   return new BigNumber(quantity).shiftedBy(0 - asset.decimals);
  // }
  return new BigNumber(quantity).shiftedBy(0 - asset.decimals);
};

const getAssetValueBNB = ({ quantity, asset }) => {
  // if (chainId === 56 || chainId === 97) {
  //   let price = quantity ? parseFloat(quantity) / 10 ** 18 : 0;
  //   return price;
  // } else {
  //   return new BigNumber(quantity).shiftedBy(0 - asset.decimals);
  // } quantity && quantity ? parseFloat(quantity) / 10 ** 18
  return quantity && quantity ? parseFloat(quantity) / 10 ** 18 : 0;
};

const getAssetPrice = ({ quantity, asset }) => {
  if (asset.price?.value) {
    let result = getAssetValue({ quantity, asset }).times(asset.price?.value);
    // console.log('resultt = ', result);
    return result;
  }
  return new BigNumber(0);
};

const get24hDiff = ({ quantity, asset }) => {
  if (asset.price?.relative_change_24h) {
    return getAssetPrice({ quantity, asset }).times(
      asset.price?.relative_change_24h / 100
    );
  }
  return 0;
};
export const Asset = ({ addressAsset, chainId }) => {
  const { asset } = addressAsset;

  return (
    <div
      style={{
        marginTop: '10px',
        display:
          getAssetPrice(addressAsset).toFixed(2) !== '0.00' ? 'flex' : 'none',
        alignItems: 'center',
        paddingLeft: '25px',
        paddingRight: '25px',
        marginBottom: '5px',
      }}
    >
      <div style={{ width: '50%' }}>
        <Stack direction='row' spacing={1}>
          <Avatar
            style={{ width: '20px', height: '20px' }}
            src={asset.icon_url || ''}
            alt={asset.symbol?.slice(0, 3).toUpperCase() || 'TOK'}
          />
          {/* </div>
        <div style={{ width: '50%' }}> */}
          <div>
            <Typography sx={{ fontWeight: '600', fontSize: '12px' }}>
              {asset.symbol}
            </Typography>
            <Typography
              sx={{ color: '#7A7B87', fontWeight: '400', fontSize: '10px' }}
            >{`${
              chainId && chainId === 56
                ? getAssetValueBNB(addressAsset).toFixed(6)
                : getAssetValue(addressAsset).toFixed(6)
            }${asset.symbol}`}</Typography>
          </div>
        </Stack>
      </div>
      <div style={{ width: '50%', textAlign: 'end' }}>
        <Typography sx={{ fontSize: '12px' }}>
          ${`${getAssetPrice(addressAsset).toFixed(2)}`}
        </Typography>

        {get24hDiff(addressAsset) > 0 ? (
          <Box
            sx={{
              color: '#5dd165',
              display: 'flex',
              alignItems: 'center',
              flexFlow: 'row-reverse',
              fontSize: '10px',
            }}
            component='div'
          >
            ${`${get24hDiff(addressAsset).toFixed(2)}`}
            <ArrowDropUpIcon sx={{ color: '#5dd165', fontSize: '12px' }} />
          </Box>
        ) : (
          <Box
            sx={{
              color: '#ff4068',
              display: 'flex',
              alignItems: 'center',
              flexFlow: 'row-reverse',
              fontSize: '10px',
            }}
            component='div'
          >
            ${`${get24hDiff(addressAsset).toFixed(2)}`}
            <ArrowDropDownIcon sx={{ color: '#ff4068', fontSize: '12px' }} />
          </Box>
        )}
      </div>
    </div>
  );
};
