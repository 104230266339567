import { capitalize } from './helpers/utilities';

export const ROPSTEN = 'ropsten';
export const RINKEBY = 'rinkeby';
export const KOVAN = 'kovan';
export const MAINNET = 'mainnet';
export const GOERLI = 'goerli';
export const LOCALHOST = 'localhost';
export const NETWORK_TYPE_RPC = 'rpc';

export const ETH_SYMBOL = 'ETH';
export const WETH_SYMBOL = 'WETH';
export const TEST_ETH_SYMBOL = 'TESTETH';
export const BNB_SYMBOL = 'BNB';
export const MATIC_SYMBOL = 'MATIC';
export const AVALANCHE_SYMBOL = 'AVAX';
export const FANTOM_SYMBOL = 'FTM';
export const CELO_SYMBOL = 'CELO';
export const OP_SYMBOL = 'op';

export const MAINNET_CHAIN_ID = '1';
export const ROPSTEN_CHAIN_ID = '3';
export const RINKEBY_CHAIN_ID = '4';
export const GOERLI_CHAIN_ID = '5';
export const KOVAN_CHAIN_ID = '42';
export const LOCALHOST_CHAIN_ID = '0x539';
export const BSC_CHAIN_ID = '56';
export const BSC_TEST_CHAIN_ID = '97';
export const OPTIMISM_CHAIN_ID = '10';
export const OPTIMISM_TESTNET_CHAIN_ID = '0x45';
export const POLYGON_CHAIN_ID = '137';
export const AVALANCHE_CHAIN_ID = '43114';
export const AVALANCHE_TEST_CHAIN_ID = '43113';
export const SOLANA_CHAIN_ID = '1399811149';
export const FANTOM_CHAIN_ID = '250';
export const CELO_CHAIN_ID = '0xa4ec';
export const TRONELINK = '999999999';
export const ARBITUM = '42161';
export const AURORA = '1313161554';
export const BUYABLE_CHAIN_ETHEREUM_NETWORK_NAME = 'eth';

export const TEST_NETWORK_TICKER_MAP = {
  [ROPSTEN]: `${ETH_SYMBOL}`,
  [RINKEBY]: `${ETH_SYMBOL}`,
  [KOVAN]: `${ETH_SYMBOL}`,
  [GOERLI]: `${ETH_SYMBOL}`,
};

export const BUYABLE_CHAINS_MAP = {
  [OPTIMISM_CHAIN_ID]: {
    nativeCurrency: OP_SYMBOL,
    network: BUYABLE_CHAIN_ETHEREUM_NETWORK_NAME,
  },
  [MAINNET_CHAIN_ID]: {
    nativeCurrency: ETH_SYMBOL,
    network: BUYABLE_CHAIN_ETHEREUM_NETWORK_NAME,
  },
  [ROPSTEN_CHAIN_ID]: {
    nativeCurrency: TEST_NETWORK_TICKER_MAP[ROPSTEN],
    network: BUYABLE_CHAIN_ETHEREUM_NETWORK_NAME,
  },
  [RINKEBY_CHAIN_ID]: {
    nativeCurrency: TEST_NETWORK_TICKER_MAP[RINKEBY],
    network: BUYABLE_CHAIN_ETHEREUM_NETWORK_NAME,
  },
  [GOERLI_CHAIN_ID]: {
    nativeCurrency: TEST_NETWORK_TICKER_MAP[GOERLI],
    network: BUYABLE_CHAIN_ETHEREUM_NETWORK_NAME,
  },
  [KOVAN_CHAIN_ID]: {
    nativeCurrency: TEST_NETWORK_TICKER_MAP[KOVAN],
    network: BUYABLE_CHAIN_ETHEREUM_NETWORK_NAME,
  },
  [BSC_CHAIN_ID]: {
    nativeCurrency: BNB_SYMBOL,
    network: 'bsc',
  },
  [BSC_TEST_CHAIN_ID]: {
    nativeCurrency: BNB_SYMBOL,
    network: 'bsc',
  },
  [POLYGON_CHAIN_ID]: {
    nativeCurrency: MATIC_SYMBOL,
    network: 'polygon',
  },
  [AVALANCHE_CHAIN_ID]: {
    nativeCurrency: AVALANCHE_SYMBOL,
    network: 'avaxc',
  },

  [AVALANCHE_TEST_CHAIN_ID]: {
    nativeCurrency: AVALANCHE_SYMBOL,
    network: 'avaxc',
  },

  [FANTOM_CHAIN_ID]: {
    nativeCurrency: FANTOM_SYMBOL,
    network: 'fantom',
  },
  [CELO_CHAIN_ID]: {
    nativeCurrency: CELO_SYMBOL,
    network: 'celo',
  },
  [SOLANA_CHAIN_ID]: {
    nativeCurrency: 'SOL',
    network: 'sol',
  },
  [TRONELINK]: {
    nativeCurrency: 'TRX',
    network: 'trx',
  },

  [ARBITUM]: {
    nativeCurrency: 'ETH',
    network: 'eth',
  },

  [AURORA]: {
    nativeCurrency: 'ETH',
    network: 'eth',
  },
};
