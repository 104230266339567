export const BSC_CHAIN = 56;
export const ETH_CHAIN = 1;
export const FTM_CHAIN = 250;
export const AVAX_CHAIN = 43114;
export const POLYGON_CHAIN = 137;
export const TRONLINK = 999999999;
export const SOLANA = 1399811149;
export const OPTIMISM_CHAIN_ID = 10;
export const AURORA = 1313161554;
export const ARBITUM = 42161;

export const TOKEN_ADDRESS_ALL = {
  AVAX: {
    [BSC_CHAIN]: { address: '0x1CE0c2827e2eF14D5C4f29a091d735A204794041' },
    [ETH_CHAIN]: { address: '' },
    [FTM_CHAIN]: { address: '0x511D35c52a3C244E7b8bd92c0C297755FbD89212' },
    [AVAX_CHAIN]: { address: '' },
    [POLYGON_CHAIN]: { address: '0x2c89bbc92bd86f8075d1decc58c7f4e0107f286b' },
    [TRONLINK]: { address: 'TWv9yPxrPSUawymhCTXXGbHj9ts5pNHCAj', decimals: 18 },
    [SOLANA]: {
      address: 'AUrMpCDYYcPuHhyNX8gEEqbmDPFUpBpHrNW3vPeCFn5Z',
      decimals: 9,
    },
    [OPTIMISM_CHAIN_ID]: { address: '', decimals: 18 },
    [AURORA]: { address: '', decimals: 18 },
    [ARBITUM]: { address: '', decimals: 18 },
  },
  ETH: {
    [BSC_CHAIN]: { address: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8' },
    [ETH_CHAIN]: { address: '' },
    [FTM_CHAIN]: { address: '0x74b23882a30290451A17c44f4F05243b6b58C76d' },
    [AVAX_CHAIN]: { address: '0xf20d962a6c8f70c731bd838a3a388D7d48fA6e15' },
    [POLYGON_CHAIN]: { address: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174' },
    [TRONLINK]: { address: 'THb4CqiFdwNHsWsQCs4JhzwjMWys4aqCbF', decimals: 18 },
    [SOLANA]: { address: 'THb4CqiFdwNHsWsQCs4JhzwjMWys4aqCbF', decimals: 18 },
    [OPTIMISM_CHAIN_ID]: { address: '', decimals: 18 },
    [AURORA]: { address: '', decimals: 18 },
    [ARBITUM]: { address: '', decimals: 18 },
  },
  BNB: {
    [BSC_CHAIN]: { address: '' },
    [ETH_CHAIN]: { address: '0xB8c77482e45F1F44dE1745F52C74426C631bDD52' },
    [FTM_CHAIN]: { address: '' },
    [AVAX_CHAIN]: { address: '0x264c1383EA520f73dd837F915ef3a732e204a493' },
    [POLYGON_CHAIN]: { address: '0x3BA4c387f786bFEE076A58914F5Bd38d668B42c3' },
    [TRONLINK]: { address: 'TDgkC3ZZBgaDqkteSgx9F14rPfqRgktyzh', decimals: 6 },
    [SOLANA]: { address: 'THb4CqiFdwNHsWsQCs4JhzwjMWys4aqCbF', decimals: 18 },
    [OPTIMISM_CHAIN_ID]: { address: '', decimals: 18 },
    [AURORA]: {
      address: '0x2bF9b864cdc97b08B6D79ad4663e71B8aB65c45c',
      decimals: 18,
    },
    [ARBITUM]: {
      address: '0x20865e63B111B2649ef829EC220536c82C58ad7B',
      decimals: 18,
    },
  },
  USDC: {
    [BSC_CHAIN]: { address: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d' },
    [ETH_CHAIN]: { address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48' },
    [FTM_CHAIN]: { address: '0x04068da6c83afcfa0e13ba15a6696662335d5b75' },
    [AVAX_CHAIN]: { address: '0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e' },
    [POLYGON_CHAIN]: { address: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174' },
    [TRONLINK]: { address: 'TEkxiTehnzSmSe2XqrBj4w32RUN966rdz8', decimals: 6 },

    [SOLANA]: {
      address: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
      decimals: 6,
    },
    [OPTIMISM_CHAIN_ID]: {
      address: '0x7F5c764cBc14f9669B88837ca1490cCa17c31607',
      decimals: 18,
    },
    [AURORA]: {
      address: '0xB12BFcA5A55806AaF64E99521918A4bf0fC40802',
      decimals: 18,
    },
    [ARBITUM]: {
      address: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
      decimals: 6,
    },
  },
  USDT: {
    [BSC_CHAIN]: { address: '0x55d398326f99059ff775485246999027b3197955' },
    [ETH_CHAIN]: { address: '0xdac17f958d2ee523a2206206994597c13d831ec7' },
    [FTM_CHAIN]: { address: '0x049d68029688eabf473097a2fc38ef61633a3c7a' },
    [AVAX_CHAIN]: { address: '0x9702230a8ea53601f5cd2dc00fdbc13d4df4a8c7' },
    [POLYGON_CHAIN]: { address: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f' },
    [TRONLINK]: { address: 'TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t', decimals: 6 },
    [SOLANA]: { address: 'THb4CqiFdwNHsWsQCs4JhzwjMWys4aqCbF', decimals: 18 },
    [OPTIMISM_CHAIN_ID]: {
      address: '0x94b008aA00579c1307B0EF2c499aD98a8ce58e58',
      decimals: 18,
    },
    [AURORA]: {
      address: '0x4988a896b1227218e4A686fdE5EabdcAbd91571f',
      decimals: 18,
    },
    [ARBITUM]: {
      address: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
      decimals: 6,
    },
  },
  FTM: {
    [BSC_CHAIN]: { address: '0xad29abb318791d579433d831ed122afeaf29dcfe' },
    [ETH_CHAIN]: { address: '0x4e15361fd6b4bb609fa63c81a2be19d873717870' },

    [FTM_CHAIN]: { address: '' },
    [AVAX_CHAIN]: { address: '' },
    [POLYGON_CHAIN]: { address: '' },
    [TRONLINK]: { address: 'TWP3jhyJTLN4jFw5qjAVof3SxrtLVZAtgY', decimals: 4 },
    [SOLANA]: { address: 'THb4CqiFdwNHsWsQCs4JhzwjMWys4aqCbF', decimals: 18 },
    [OPTIMISM_CHAIN_ID]: { address: '', decimals: 18 },
    [AURORA]: {
      address: '0x1fE622E91e54D6AD00B01917351Ea6081426764A',
      decimals: 18,
    },
    [ARBITUM]: {
      address: '0xd42785D323e608B9E99fa542bd8b1000D4c2Df37',
      decimals: 18,
    },
  },
  BETH: {
    [BSC_CHAIN]: { address: '0x250632378e573c6be1ac2f97fcdf00515d0aa91b' },
    [ETH_CHAIN]: { address: '0xc3501eaAF5E29Aa0529342F409443120fD545ea7' },
    [FTM_CHAIN]: { address: '' },
    [AVAX_CHAIN]: { address: '' },
    [POLYGON_CHAIN]: { address: '' },
    [TRONLINK]: { address: '' },
    [SOLANA]: { address: 'THb4CqiFdwNHsWsQCs4JhzwjMWys4aqCbF', decimals: 18 },
    [OPTIMISM_CHAIN_ID]: { address: '', decimals: 18 },
    [AURORA]: { address: '', decimals: 18 },
    [ARBITUM]: { address: '', decimals: 18 },
  },
  BUSD: {
    [BSC_CHAIN]: { address: '0xe9e7cea3dedca5984780bafc599bd69add087d56' },
    [ETH_CHAIN]: { address: '0x4fabb145d64652a948d72533023f6e7a623c7c53' },
    [FTM_CHAIN]: { address: '' },
    [AVAX_CHAIN]: { address: '0x19860ccb0a68fd4213ab9d8266f7bbf05a8dde98' },
    [POLYGON_CHAIN]: { address: '0xdab529f40e671a1d4bf91361c21bf9f0c9712ab7' },
    [TRONLINK]: { address: 'TRLLjnKsvrujZbDKZfgVVNTsDnQix7oVqi', decimals: 18 },
    [SOLANA]: { address: 'THb4CqiFdwNHsWsQCs4JhzwjMWys4aqCbF', decimals: 18 },
    [OPTIMISM_CHAIN_ID]: { address: '', decimals: 18 },
    [AURORA]: {
      address: '0x3b40D173b5802733108E047CF538Be178646b2e4',
      decimals: 18,
    },
    [ARBITUM]: { address: '', decimals: 18 },
  },

  AAVE: {
    [BSC_CHAIN]: { address: '0xfb6115445bff7b52feb98650c87f44907e58f802' },
    [ETH_CHAIN]: { address: '0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9' },
    [FTM_CHAIN]: { address: '0x6a07a792ab2965c72a5b8088d3a069a7ac3a993b' },
    [AVAX_CHAIN]: { address: '0x63a72806098bd3d9520cc43356dd78afe5d386d9' },
    [POLYGON_CHAIN]: { address: '0xd6df932a45c0f255f85145f286ea0b292b21c90b' },
    [TRONLINK]: { address: 'TAQ4QmZv9KXmXYH26LKarKauyrwzGteXAA', decimals: 6 },
    [SOLANA]: { address: 'THb4CqiFdwNHsWsQCs4JhzwjMWys4aqCbF', decimals: 18 },
    [OPTIMISM_CHAIN_ID]: { address: '', decimals: 18 },
    [AURORA]: {
      address: '0x4e834cDCc911605227eedDDb89Fad336AB9dc00a',
      decimals: 18,
    },
    [ARBITUM]: { address: '', decimals: 18 },
  },
  MATIC: {
    [BSC_CHAIN]: { address: '0xcc42724c6683b7e57334c4e856f4c9965ed682bd' },
    [ETH_CHAIN]: { address: '0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0' },
    [FTM_CHAIN]: { address: '' },
    [AVAX_CHAIN]: { address: '' },
    [POLYGON_CHAIN]: { address: '' },
    [TRONLINK]: { address: 'TWJWGBSdZFjFfC4ZZxzdnrrQZpmNDnmjKL', decimals: 6 },
    [SOLANA]: { address: 'THb4CqiFdwNHsWsQCs4JhzwjMWys4aqCbF', decimals: 18 },
    [OPTIMISM_CHAIN_ID]: { address: '', decimals: 18 },
    [AURORA]: {
      address: '0x6aB6d61428fde76768D7b45D8BFeec19c6eF91A8',
      decimals: 18,
    },
    [ARBITUM]: {
      address: '0x561877b6b3DD7651313794e5F2894B2F18bE0766',
      decimals: 18,
    },
  },
  AXS: {
    [BSC_CHAIN]: { address: '0x715d400f88c167884bbcc41c5fea407ed4d2f8a0' },
    [ETH_CHAIN]: { address: '0xbb0e17ef65f82ab018d8edd776e8dd940327b28b' },
    [FTM_CHAIN]: { address: '0x1e78F1B09c79eFAeB3247723622CD4Dd7E3f0567' },
    [AVAX_CHAIN]: { address: '' },
    [POLYGON_CHAIN]: { address: '' },
    [TRONLINK]: { address: 'TE1prX5NSN64i4or8PvBfaSdK7ybimejs5', decimals: 6 },
    [SOLANA]: { address: 'THb4CqiFdwNHsWsQCs4JhzwjMWys4aqCbF', decimals: 18 },
    [OPTIMISM_CHAIN_ID]: { address: '', decimals: 18 },
    [AURORA]: { address: '', decimals: 18 },
    [ARBITUM]: { address: '', decimals: 18 },
  },
  ANT: {
    [BSC_CHAIN]: { address: '' },
    [ETH_CHAIN]: { address: '0xa117000000f279d81a1d3cc75430faa017fa5a2e' },
    [FTM_CHAIN]: { address: '' },
    [AVAX_CHAIN]: { address: '' },
    [POLYGON_CHAIN]: { address: '' },
    [TRONLINK]: { address: '', decimals: 6 },
    [SOLANA]: { address: 'THb4CqiFdwNHsWsQCs4JhzwjMWys4aqCbF', decimals: 18 },
    [OPTIMISM_CHAIN_ID]: { address: '', decimals: 18 },
    [AURORA]: { address: '', decimals: 18 },
    [ARBITUM]: { address: '', decimals: 18 },
  },
  XRP: {
    [BSC_CHAIN]: { address: '0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE' },
    [ETH_CHAIN]: { address: '' },
    [FTM_CHAIN]: { address: '' },
    [AVAX_CHAIN]: { address: '' },
    [POLYGON_CHAIN]: { address: '' },
    [TRONLINK]: { address: 'TREwGSZGGMUHeT5gfA2f8AzoLjALtSRWjM', decimals: 6 },
    [SOLANA]: { address: 'THb4CqiFdwNHsWsQCs4JhzwjMWys4aqCbF', decimals: 18 },
    [OPTIMISM_CHAIN_ID]: { address: '', decimals: 18 },
    [AURORA]: { address: '', decimals: 18 },
    [ARBITUM]: { address: '', decimals: 18 },
  },
  TRX: {
    [BSC_CHAIN]: { address: '0x85EAC5Ac2F758618dFa09bDbe0cf174e7d574D5B' },
    [ETH_CHAIN]: { address: '0xf230b790E05390FC8295F4d3F60332c93BEd42e2' },
    [FTM_CHAIN]: { address: '' },
    [AVAX_CHAIN]: { address: '' },
    [POLYGON_CHAIN]: { address: '0x48f6bEA60D1b31669678e8ad9321aF8C7949A84A' },
    [TRONLINK]: { address: '' },
    [SOLANA]: { address: 'THb4CqiFdwNHsWsQCs4JhzwjMWys4aqCbF', decimals: 18 },
    [OPTIMISM_CHAIN_ID]: { address: '', decimals: 18 },
    [AURORA]: { address: '', decimals: 18 },
    [ARBITUM]: { address: '', decimals: 18 },
  },
  ADA: {
    [BSC_CHAIN]: { address: '0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47' },
    [ETH_CHAIN]: { address: '' },
    [FTM_CHAIN]: { address: '' },
    [AVAX_CHAIN]: { address: '' },
    [POLYGON_CHAIN]: { address: '' },
    [TRONLINK]: { address: 'TAyWDQjBC5QDPtSou7gpANPXa6Jpmiqx7Q', decimals: 10 },
    [SOLANA]: { address: 'THb4CqiFdwNHsWsQCs4JhzwjMWys4aqCbF', decimals: 18 },
    [OPTIMISM_CHAIN_ID]: { address: '', decimals: 18 },
    [AURORA]: { address: '', decimals: 18 },
    [ARBITUM]: { address: '', decimals: 18 },
  },
  GMT: {
    [BSC_CHAIN]: { address: '0x3019bf2a2ef8040c242c9a4c5c4bd4c81678b2a1' },
    [ETH_CHAIN]: { address: '0xe3c408bd53c31c085a1746af401a4042954ff740' },
    [FTM_CHAIN]: { address: '' },
    [AVAX_CHAIN]: { address: '' },
    [POLYGON_CHAIN]: { address: '' },
    [TRONLINK]: { address: 'TCQiPkH2rowSvBd5S7hhEBpzpKjDVdqSdy', decimals: 18 },
    [SOLANA]: { address: 'THb4CqiFdwNHsWsQCs4JhzwjMWys4aqCbF', decimals: 18 },
    [OPTIMISM_CHAIN_ID]: { address: '', decimals: 18 },
    [AURORA]: { address: '', decimals: 18 },
    [ARBITUM]: {
      address: '0x0861D7884a6D66b3ce5cF6Ac387FbE9ea85F161a',
      decimals: 18,
    },
  },
  ALGO: {
    [BSC_CHAIN]: { address: '' },
    [ETH_CHAIN]: { address: '' },
    [FTM_CHAIN]: { address: '' },
    [AVAX_CHAIN]: { address: '' },
    [POLYGON_CHAIN]: { address: '' },
    [TRONLINK]: { address: 'TGeGDg6DVyvigDafmvzR9yCtS1RSQQFdHL', decimals: 4 },
    [SOLANA]: {
      address: 'HtKKipqmgxMQJUob9XCgZBG6zSCZx4nA433ZfDuLyUTg',
      decimals: 9,
    },
    [OPTIMISM_CHAIN_ID]: { address: '', decimals: 18 },
    [AURORA]: { address: '', decimals: 18 },
    [ARBITUM]: { address: '', decimals: 18 },
  },
  ATOM: {
    [BSC_CHAIN]: { address: '0x0Eb3a705fc54725037CC9e008bDede697f62F335' },
    [ETH_CHAIN]: { address: '' },
    [FTM_CHAIN]: { address: '' },
    [AVAX_CHAIN]: { address: '' },
    [POLYGON_CHAIN]: { address: '' },
    [TRONLINK]: { address: 'TDdbdkAR2DXXPYMFTpj89nXYxZ5i9mmL6G', decimals: 6 },
    [SOLANA]: {
      address: '',
      decimals: 9,
    },
    [OPTIMISM_CHAIN_ID]: { address: '', decimals: 18 },
    [AURORA]: { address: '', decimals: 18 },
    [ARBITUM]: { address: '', decimals: 18 },
  },
  BCH: {
    [BSC_CHAIN]: { address: '0x8fF795a6F4D97E7887C79beA79aba5cc76444aDf' },
    [ETH_CHAIN]: { address: '' },
    [FTM_CHAIN]: {
      address: '0x67dE7CA1042B67608ee0A484CD76AA4De65561d3',
      decimals: 18,
    },
    [AVAX_CHAIN]: { address: '' },
    [POLYGON_CHAIN]: {
      address: '0x06430fe1f30a8A600Cb4712aE4834021C80B00C8',
      decimals: 18,
    },
    [TRONLINK]: { address: 'TFRcL1DdC854BNYf9hWiFGWXZqbsCYqUGA', decimals: 3 },
    [SOLANA]: {
      address: '',
      decimals: 9,
    },
    [OPTIMISM_CHAIN_ID]: { address: '', decimals: 18 },
    [AURORA]: { address: '', decimals: 18 },
    [ARBITUM]: { address: '', decimals: 18 },
  },
  BDOT: {
    [BSC_CHAIN]: { address: '' },
    [ETH_CHAIN]: { address: '0x7884F51dC1410387371ce61747CB6264E1dAeE0B' },
    [FTM_CHAIN]: { address: '', decimals: 18 },
    [AVAX_CHAIN]: { address: '' },
    [POLYGON_CHAIN]: { address: '', decimals: 18 },
    [TRONLINK]: { address: '', decimals: 3 },
    [SOLANA]: {
      address: '',
      decimals: 9,
    },
    [OPTIMISM_CHAIN_ID]: { address: '', decimals: 18 },
    [AURORA]: { address: '', decimals: 18 },
    [ARBITUM]: { address: '', decimals: 18 },
  },
  DOGE: {
    [BSC_CHAIN]: { address: '' },
    [ETH_CHAIN]: { address: '0x7884F51dC1410387371ce61747CB6264E1dAeE0B' },
    [FTM_CHAIN]: { address: '', decimals: 18 },
    [AVAX_CHAIN]: { address: '' },
    [POLYGON_CHAIN]: { address: '', decimals: 18 },
    [TRONLINK]: { address: 'THbVQp8kMjStKNnf2iCY6NEzThKMK5aBHg', decimals: 8 },
    [SOLANA]: {
      address: '',
      decimals: 9,
    },
    [OPTIMISM_CHAIN_ID]: { address: '', decimals: 18 },
    [AURORA]: { address: '', decimals: 18 },
    [ARBITUM]: { address: '', decimals: 18 },
  },
  DOT: {
    [BSC_CHAIN]: { address: '0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402' },
    [ETH_CHAIN]: { address: '' },
    [FTM_CHAIN]: { address: '', decimals: 18 },
    [AVAX_CHAIN]: { address: '' },
    [POLYGON_CHAIN]: { address: '', decimals: 18 },
    [TRONLINK]: { address: 'TJxJQvAu7a8Yvi15p1c5xTpawQuhc64w1E', decimals: 8 },
    [SOLANA]: {
      address: '4tfNrjsyMR35Jbvua7RM2n2zsrvWBfPsuqovGxcL2DHB',
      decimals: 9,
    },
    [OPTIMISM_CHAIN_ID]: { address: '', decimals: 18 },
    [AURORA]: { address: '', decimals: 18 },
    [ARBITUM]: { address: '', decimals: 18 },
  },
  ETC: {
    [BSC_CHAIN]: {
      address: '0x3d6545b08693daE087E957cb1180ee38B9e3c25E',
      decimals: 18,
    },
    [ETH_CHAIN]: { address: '' },
    [FTM_CHAIN]: { address: '', decimals: 18 },
    [AVAX_CHAIN]: { address: '' },
    [POLYGON_CHAIN]: { address: '', decimals: 18 },
    [TRONLINK]: { address: 'TKWyp8vDwjKE48pPCAfPN9fe7e4CLtC3cx', decimals: 8 },
    [SOLANA]: {
      address: '4tfNrjsyMR35Jbvua7RM2n2zsrvWBfPsuqovGxcL2DHB',
      decimals: 9,
    },
    [OPTIMISM_CHAIN_ID]: { address: '', decimals: 18 },
    [AURORA]: { address: '', decimals: 18 },
    [ARBITUM]: { address: '', decimals: 18 },
  },
  HARD: {
    [BSC_CHAIN]: { address: '', decimals: 18 },
    [ETH_CHAIN]: { address: '' },
    [FTM_CHAIN]: { address: '', decimals: 18 },
    [AVAX_CHAIN]: { address: '' },
    [POLYGON_CHAIN]: { address: '', decimals: 18 },
    [TRONLINK]: { address: 'TPvpWaHSfpXjRGoKqg36Hdum5d58sGz7Q6', decimals: 8 },
    [SOLANA]: {
      address: '',
      decimals: 9,
    },
    [OPTIMISM_CHAIN_ID]: { address: '', decimals: 18 },
    [AURORA]: { address: '', decimals: 18 },
    [ARBITUM]: { address: '', decimals: 18 },
  },
  THETA: {
    [BSC_CHAIN]: { address: '', decimals: 18 },
    [ETH_CHAIN]: { address: '' },
    [FTM_CHAIN]: { address: '', decimals: 18 },
    [AVAX_CHAIN]: { address: '' },
    [POLYGON_CHAIN]: { address: '', decimals: 18 },
    [TRONLINK]: { address: 'TYks6QynhVBqrCTU8e6QtM3wPyAW7QbneY', decimals: 4 },
    [SOLANA]: {
      address: '',
      decimals: 9,
    },
    [OPTIMISM_CHAIN_ID]: { address: '', decimals: 18 },
    [AURORA]: { address: '', decimals: 18 },
    [ARBITUM]: { address: '', decimals: 18 },
  },
  RUNE: {
    [BSC_CHAIN]: { address: '', decimals: 18 },
    [ETH_CHAIN]: {
      address: '0x3155BA85D5F96b2d030a4966AF206230e46849cb',
      decimals: 18,
    },
    [FTM_CHAIN]: { address: '', decimals: 18 },
    [AVAX_CHAIN]: { address: '' },
    [POLYGON_CHAIN]: { address: '', decimals: 18 },
    [TRONLINK]: { address: 'TLuWQmVrRpPoDU13hnJVdxRkJRZTwKXoD3', decimals: 10 },
    [SOLANA]: {
      address: '',
      decimals: 9,
    },
    [OPTIMISM_CHAIN_ID]: { address: '', decimals: 18 },
    [AURORA]: { address: '', decimals: 18 },
    [ARBITUM]: {
      address: '0x4d173Ff5F590dDE33ACEF04382F550d7E3494320',
      decimals: 18,
    },
  },
};
