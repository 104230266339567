import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  useMediaQuery,
  Stack,
  Divider,
  DialogTitle,
  InputAdornment,
  OutlinedInput,
  useTheme,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';

const TokenModal = ({
  open,
  setOpen,
  list,
  setCurrency,
  setSwitchState,
  switchState,
  readOnly,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(767));
  const useStyles = makeStyles({
    dialogPaper: {
      margin: '0px',
      width: isMobile ? '90vw' : '30vw',
    },
  });
  const classes = useStyles();
  const handleClose = () => {
    if (switchState) {
      setSwitchState(false);
    }
    setOpen(false);
  };

  const [scroll, setScroll] = React.useState('paper');
  const [search, setSearch] = React.useState('');

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth='xs'
        scroll={scroll}
        //fullWidth
        PaperProps={{ classes: { root: classes.dialogPaper } }}
        sx={{
          maxHeight: '610px',
          padding: 'none',
        }}
      >
        <DialogTitle sx={{ background: '#26263A', padding: '16px 8px ' }}>
          {handleClose ? (
            <IconButton
              aria-label='close'
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: theme => theme.palette.grey[500],
                background: '#1f1f2e',
              }}
            >
              <Close sx={{ fontSize: '15px' }} />
            </IconButton>
          ) : null}
          <Stack
            alignItems='center'
            direction='column'
            justifyContent='center'
            sx={{ mt: '20px' }}
            spacing={3}
          >
            <Typography sx={{ fontSize: '12px' }}>Select a token</Typography>
            <Box className='search-box'>
              <OutlinedInput
                sx={{
                  height: '2.6rem',
                  background: '#2F2F43',
                  border: 'navajowhite',
                  outline: 'none',
                  color: '#fff',
                  borderRadius: '10px',
                  fontSize: '12px',
                  '&:hover': {
                    outline: 'none',
                    borderColor: 'transparent',
                  },
                }}
                startAdornment={
                  <InputAdornment position='start'>
                    <SearchIcon sx={{ color: '#818C99' }} />
                  </InputAdornment>
                }
                type='search'
                value={search}
                onChange={e => setSearch(e.target.value)}
                id='searchCoin'
                placeholder={'Search name'}
                className='search-box'
              />
            </Box>
          </Stack>
        </DialogTitle>

        <Divider />

        <DialogContent
          dividers={scroll === 'paper'}
          sx={{
            pl: '20px',
            background: '#26263A',
            overflow: 'auto',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            height: '420px',
          }}
        >
          {list &&
            Object.keys(list).length > 0 &&
            list.map(
              r =>
                r.toLowerCase().includes(search.toLowerCase()) && (
                  <Stack
                    onClick={() => {
                      setCurrency(r);
                      handleClose();
                    }}
                    sx={{
                      cursor: 'pointer',
                      marginBottom: '20px',
                      width: '100%',
                    }}
                    direction='row'
                    spacing={1}
                  >
                    <img
                      style={{ height: '25px' }}
                      src={`./images/crypto-icons/${r.toLocaleLowerCase()}.svg`}
                      alt=''
                    />
                    <Typography sx={{ fontSize: '14px', lineHeight: '24px' }}>
                      {r}
                    </Typography>
                  </Stack>
                )
            )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TokenModal;
