/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Tooltip,
  IconButton,
  Typography,
  useMediaQuery,
  Stack,
  Divider,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import * as solanaWeb3 from '@solana/web3.js';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { ethers } from 'ethers';
import * as React from 'react';
import TronWeb from 'tronweb';
import Coinbase from './icons/Coinbase';
import Metamask from './icons/Metamask';
import Phatom from './icons/Phantom';
import Tronlink from './icons/Tronlink';
import Walletconnect from './icons/Walletconnect';
import TrustWallet from './icons/trustWallet';
import BinanceIcon from './icons/Binance';
import { makeStyles } from '@mui/styles';
import { BUYABLE_CHAINS_MAP } from './network';
import 'toastify-js/src/toastify.css';
import { useTheme } from '@mui/material/styles';
import { BscConnector } from '@binance-chain/bsc-connector';
import {
  injected,
  walletlink,
  activateInjectedProvider,
} from './utils/wallet-connector';
import { useWeb3React } from '@web3-react/core';

const useStyles = makeStyles({
  dialogPaper: {
    margin: '0px',
  },
});

function Web3Dialog({
  onConnectSub,
  resetApp,
  setWeb3State,
  web3States,
  open,
  setOpen,
  balanceChange,
}) {
  const {
    active,
    account,
    connector,
    activate,
    deactivate,
    chainId: injectedChainId,
  } = useWeb3React();
  const classes = useStyles();
  const [address, setAddress] = React.useState({ address: '', wallet: '' });
  const [balance, setBalance] = React.useState({ balance: 0, symbol: '' });
  const [scroll] = React.useState('paper');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(767));
  const bsc = new BscConnector({
    supportedChainIds: [56, 97], // later on 1 ethereum mainnet and 3 ethereum ropsten will be supported
  });

  const handleClose = () => {
    setOpen(false);
  };

  const getProvider = async wallet => {
    let baseUrl = '';
    baseUrl =
      process.env.REACT_APP_BACKEND_URL === 'https://ventiswap.tk'
        ? 'http://ventiswap.netlify.app/'
        : 'app.ventiswap.com';
    if (wallet === 'binance') {
      await bsc.activate();
      if (window.BinanceChain) {
        return window.BinanceChain;
      }
    } else if (wallet === 'metamask') {
      if ('ethereum' in window) {
        const provider = window.ethereum;
        if (provider.isMetaMask) {
          return provider;
        }
      } else {
        window.open(`https://metamask.app.link/dapp/${baseUrl}`, '_blank');
      }
    } else if (wallet === 'walletconnect') {
      const walletConnectProvider = new WalletConnectProvider({
        infuraId: process.env.REACT_APP_INFURA_ID,
        // infuraId: '3a50db1a56194279814ff1ef6591a94b',
      });
      await walletConnectProvider.enable();
      return walletConnectProvider;
    } else if (wallet === 'phantom') {
      baseUrl =
        process.env.REACT_APP_BACKEND_URL === 'https://ventiswap.tk'
          ? 'http%3A%2F%2Fventiswap.netlify.app'
          : 'https%3A%2F%2Fapp.ventiswap.com%2F';
      if ('solana' in window) {
        const provider = window.solana;
        if (provider.isPhantom) {
          return provider;
        }
      } else {
        window.open(
          `https://phantom.app/ul/browse/${baseUrl}?ref=${baseUrl}`,
          '_blank'
        );
      }
    } else if (wallet === 'tronlink') {
      if ('tronWeb' in window) {
        const provider = window.tronWeb;
        return provider;
      } else {
        window.open('https://www.tronlink.org/', '_blank');
      }
    } else if (wallet === 'coinbase') {
      if ('ethereum' in window) {
        if (window.ethereum.providers && window.ethereum.providers.length) {
          const provider = window.ethereum.providers.find(
            p => p.isCoinbaseWallet
          );
          if (provider) {
            return provider;
          }
        }
        const provider = window.ethereum;
        if (provider.isCoinbaseWallet) {
          return provider;
        }
      } else {
        window.open(`https://go.cb-w.com/dapp?cb_url=${baseUrl}`, '_blank');
      }
    } else if (wallet === 'trustwallet') {
      if (window.ethereum) {
        const provider = window.ethereum;
        return provider;
      } else {
        window.open(
          `https://link.trustwallet.com/open_url?&url=${baseUrl}`,
          '_blank'
        );
      }
    }
  };

  const handleConnect = async wallet => {
    localStorage.setItem('wallet', wallet);
    if (wallet === 'binance') {
      const provider = await getProvider(wallet);
      const web3 = new ethers.providers.Web3Provider(provider);
      const signer = web3.getSigner();
      const tempAddress = await signer.getAddress();
      console.log(tempAddress);
      setAddress({ wallet, address: tempAddress });
      localStorage.setItem('fromAddress', tempAddress);
      handleClose();
    } else if (wallet === 'metamask') {
      activateInjectedProvider(); // To select metamask
      activate(injected);
      // const provider = await getProvider(wallet);
      // const web3 = new ethers.providers.Web3Provider(provider);
      // await web3.send('eth_requestAccounts', []);
      // const signer = web3.getSigner();
      // const address = await signer.getAddress();
      setAddress({ wallet, address: account });
      localStorage.setItem('fromAddress', address);
      // onConnectSub(provider);
      handleClose();
    } else if (wallet === 'walletconnect') {
      const provider = await getProvider(wallet);
      onConnectSub(provider, 'Walletconnect');
      const web3 = new ethers.providers.Web3Provider(provider);
      const signer = web3.getSigner();
      const address = await signer.getAddress();
      localStorage.setItem('fromAddress', address);
      setAddress({ wallet, address });
      handleClose();
    } else if (wallet === 'phantom') {
      const provider = await getProvider(wallet);
      const resp = await provider.connect();
      const address = resp.publicKey.toString();
      setAddress({ wallet, address });
      localStorage.setItem('fromAddress', address);
      handleClose();
    } else if (wallet === 'tronlink') {
      const provider = await getProvider(wallet);
      // provider.request({ method: 'tron_requestAccounts' });
      const tempAddress = provider.defaultAddress.base58;
      // console.log('address 1 = ', tempAddress);
      setAddress({ wallet, address: tempAddress });
      localStorage.setItem('fromAddress', tempAddress);
      handleClose();
    } else if (wallet === 'coinbase') {
      activate(walletlink);
      // const provider = await getProvider(wallet);
      // const web3 = new ethers.providers.Web3Provider(provider);
      // await web3.send('eth_requestAccounts', []);
      // const signer = web3.getSigner();
      // const address = await signer.getAddress();
      localStorage.setItem('fromAddress', address);
      setAddress({ wallet, address: account });
      // onConnectSub(provider);
      handleClose();
    } else if (wallet === 'trustwallet') {
      const provider = await getProvider(wallet);
      const web3 = new ethers.providers.Web3Provider(provider);
      await web3.send('eth_requestAccounts', []);
      const signer = web3.getSigner();
      const address = await signer.getAddress();
      setAddress({ wallet, address });
      localStorage.setItem('fromAddress', address);
      onConnectSub(provider, false, address);
      handleClose();
    }
  };

  const getBalance = async wallet => {
    if (wallet === 'binance') {
      const provider = await getProvider(wallet);
      const web3 = new ethers.providers.Web3Provider(provider);
      const signer = web3.getSigner();
      const tempAddress = await signer.getAddress();
      let tempBalance = await web3.getBalance(tempAddress);
      console.log('Chain ID', tempAddress);
      const symbol = getCurrencyUnit(wallet);
      setBalance({ balance: tempBalance.toString() / 10 ** 18, symbol });
      // console.log(tempBalance.toString() / 10 ** 18);
      if (
        parseFloat(web3States.balanceWei) !==
          parseFloat(tempBalance.toString() / 10 ** 18) ||
        web3States.addressw !== tempAddress
      ) {
        setWeb3State(current => ({
          ...current,
          web3: null,
          provider: null,
          connected: true,
          addressw: tempAddress,
          chainId: 56,
          networkId: 56,
          balanceWei: tempBalance.toString() / 10 ** 18,
          coin: symbol,
          network: BUYABLE_CHAINS_MAP[`${56}`]?.network,
        }));
      }
    } else if (wallet === 'metamask') {
      // const provider = await getProvider(wallet);
      // const web3 = new ethers.providers.Web3Provider(provider);
      // const balance = await web3.getBalance(address.address);
      const symbol = getCurrencyUnit(wallet);
      setBalance({ balance: '0', symbol });
    } else if (wallet === 'walletconnect') {
      const provider = await getProvider(wallet);
      const web3 = new ethers.providers.Web3Provider(provider);
      const balance = await web3.getBalance(address.address);
      const symbol = getCurrencyUnit(wallet);
      setBalance({ balance: ethers.utils.formatEther(balance), symbol });
    } else if (wallet === 'phantom') {
      const provider = await getProvider(wallet);
      var connection = new solanaWeb3.Connection(
        solanaWeb3.clusterApiUrl('mainnet-beta'),
        'confirmed'
      );

      let tempBalance = await connection.getBalance(provider.publicKey);
      const symbol = getCurrencyUnit(wallet);
      setBalance({ balance: tempBalance / 10 ** 9, symbol });
      if (
        (web3States.coin === 'SOL' || web3States.coin === '-') &&
        parseFloat(web3States.balanceWei) !== parseFloat(tempBalance / 10 ** 9)
      ) {
        setWeb3State(current => ({
          ...current,
          web3: null,
          provider: null,
          connected: true,
          addressw: address.address,
          chainId: 1399811149,
          networkId: 1399811149,
          balanceWei: tempBalance / 10 ** 9,
          coin: getCurrencyUnit(wallet),
          network: BUYABLE_CHAINS_MAP[`${1399811149}`]?.network,
        }));
      }
    } else if (wallet === 'tronlink') {
      const provider = await getProvider(wallet);
      const tempAddress = provider.defaultAddress.base58;
      // console.log('tempAddress = ', tempAddress);
      const fullNode = 'https://api.trongrid.io';
      const solidityNode = 'https://api.trongrid.io';
      const eventServer = 'https://api.trongrid.io';
      // const fullNode = 'https://api.shasta.trongrid.io';
      // const solidityNode = 'https://api.shasta.trongrid.io';
      // const eventServer = 'https://api.shasta.trongrid.io';
      const tronWeb = new TronWeb(
        fullNode,
        solidityNode,
        eventServer
        // '88f98e84-3b96-45eb-8fa3-b71a0fa877ce'
      );
      const tempBalance = await tronWeb.trx.getBalance(tempAddress);
      // console.log('TRONELINK 8', tempBalance);
      const symbol = getCurrencyUnit(wallet);
      // console.log('TRONELINK 9');
      setBalance({ balance: tempBalance / 10 ** 6, symbol });
      console.log('TRONELINK 10 = ', web3States);
      if (
        (web3States.coin === 'TRX' || web3States.coin === '-') &&
        (parseFloat(web3States.balanceWei) !==
          parseFloat(tempBalance / 10 ** 6) ||
          web3States.addressw !== tempAddress)
      ) {
        setWeb3State(current => ({
          ...current,
          web3: null,
          provider: null,
          connected: true,
          addressw: tempAddress,
          chainId: 999999999,
          networkId: 999999999,
          balanceWei: tempBalance / 10 ** 6,
          coin: getCurrencyUnit(wallet),
          network: BUYABLE_CHAINS_MAP[`${999999999}`]?.network,
        }));
      }
    } else if (wallet === 'coinbase') {
      // const provider = await getProvider(wallet);
      // const web3 = new ethers.providers.Web3Provider(provider);
      // const balance = await web3.getBalance(address.address);
      const symbol = getCurrencyUnit(wallet);
      setBalance({ balance: '0', symbol });
    }
  };

  const getCurrencyUnit = wallet => {
    if (wallet === 'binance') {
      return 'BNB';
    } else if (wallet === 'metamask') {
      let currentSymbol;
      if (web3States.chainId === 56 || web3States.chainId === 97) {
        currentSymbol = 'BNB';
      } else {
        currentSymbol =
          web3States.chainId &&
          BUYABLE_CHAINS_MAP[`${web3States.chainId}`]?.nativeCurrency;
      }

      return currentSymbol ? currentSymbol : 'ETH';
    } else if (wallet === 'walletconnect') {
      let currentSymbol =
        web3States.chainId &&
        BUYABLE_CHAINS_MAP[`${web3States.chainId}`]?.nativeCurrency;
      return currentSymbol;
    } else if (wallet === 'phantom') {
      // console.log('123');
      return 'SOL';
    } else if (wallet === 'tronlink') {
      return 'TRX';
    } else if (wallet === 'coinbase') {
      let currentSymbol;
      if (web3States.chainId === 56 || web3States.chainId === 97) {
        currentSymbol = 'BNB';
      } else {
        currentSymbol =
          web3States.chainId &&
          BUYABLE_CHAINS_MAP[`${web3States.chainId}`]?.nativeCurrency;
      }

      return currentSymbol ? currentSymbol : 'ETH';
    }
  };

  React.useEffect(() => {
    if (address?.wallet) {
      getBalance(address.wallet);
    } else {
      setBalance({ balance: 0, symbol: '' });
    }
  }, [address, balanceChange]);

  React.useEffect(() => {
    const tempAddress = localStorage.getItem('fromAddress');
    const tempWallet = localStorage.getItem('wallet');
    if (tempWallet && tempAddress) {
      handleConnect(tempWallet);
      setAddress({ address: tempAddress, wallet: tempWallet });
    }
  }, []);

  React.useEffect(() => {
    if (active) {
      setWeb3State(current => ({
        ...current,
        web3: null,
        provider: null,
        connected: true,
        addressw: account,
        chainId: injectedChainId,
        networkId: injectedChainId,
        balanceWei: '0',
        coin: BUYABLE_CHAINS_MAP[`${injectedChainId}`]?.nativeCurrency,
        network: BUYABLE_CHAINS_MAP[`${injectedChainId}`]?.network,
        connector: connector,
      }));
    }
  }, [active, injectedChainId]);

  const disconnect = () => {
    if (
      localStorage &&
      localStorage.getItem('fromAddress') &&
      localStorage.getItem('wallet')
    ) {
      localStorage.removeItem('wallet');
      localStorage.removeItem('fromAddress');
    }

    setAddress({ address: '', wallet: '' });
    deactivate();
    resetApp && resetApp();
  };

  React.useEffect(() => {
    if (web3States && !web3States.addressw) {
      setAddress({ address: '', wallet: '' });
    } else {
      setAddress({ ...address, address: web3States.addressw });
    }
  }, [web3States]);

  return (
    <>
      <Tooltip
        title={
          web3States.addressw && address ? (
            <div>
              <div>Address: {address.address}</div>
              <div>
                Balance: {balance.balance}
                {balance.symbol}
              </div>
            </div>
          ) : (
            'click to connect'
          )
        }
      >
        <Button
          style={{
            // width: isMobile ? '' : '6vw',
            height: '30px',
            marginLeft: '15px',
            marginRight: '15px',
            borderRadius: '6px',
          }}
          variant='outlined'
          onClick={() => (web3States.addressw ? disconnect() : setOpen(true))}
          sx={{
            '.MuiButton-startIcon img': { width: 20 },
          }}
          startIcon={
            web3States.addressw && localStorage.getItem('wallet') ? (
              localStorage && localStorage.getItem('wallet') === 'metamask' ? (
                <Metamask />
              ) : localStorage.getItem('wallet') === 'walletconnect' ? (
                <Walletconnect />
              ) : localStorage.getItem('wallet') === 'coinbase' ? (
                <Coinbase />
              ) : localStorage.getItem('wallet') === 'phantom' ? (
                <Phatom />
              ) : localStorage.getItem('wallet') === 'tronlink' ? (
                <Tronlink />
              ) : localStorage.getItem('wallet') === 'trustwallet' ? (
                <TrustWallet />
              ) : localStorage.getItem('wallet') === 'binance' ? (
                <BinanceIcon />
              ) : (
                <></>
              )
            ) : (
              <></>
            )
          }
        >
          {web3States.addressw && localStorage.getItem('wallet') ? (
            <Typography sx={{ color: '#18A9F7', fontSize: '10px' }}>
              Disconnect
            </Typography>
          ) : (
            <Typography sx={{ color: '#18A9F7', fontSize: '10px' }}>
              Connect
            </Typography>
          )}
        </Button>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth='xs'
        scroll={scroll}
        //fullWidth
        PaperProps={{ classes: { root: classes.dialogPaper } }}
        sx={{
          maxHeight: '610px',
          padding: 'none',
        }}
      >
        <DialogTitle sx={{ background: '#26263A' }}>
          {handleClose ? (
            <IconButton
              aria-label='close'
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: theme => theme.palette.grey[500],
                background: '#1f1f2e',
              }}
            >
              <CloseIcon sx={{ fontSize: '15px' }} />
            </IconButton>
          ) : null}
          <Stack
            alignItems='center'
            direction='column'
            justifyContent='center'
            sx={{ mt: '20px' }}
          >
            <img
              src='/images/Group.svg'
              alt=''
              width='30px'
              style={{ paddingBottom: '10px' }}
            />
            <Typography sx={{ fontSize: '12px' }}>Connect Wallet</Typography>
            <Typography
              sx={{ color: 'white', opacity: '0.5', fontSize: '10px' }}
            >
              Select a wallet from below
            </Typography>
          </Stack>
        </DialogTitle>

        <Divider />

        <DialogContent
          dividers={scroll === 'paper'}
          sx={{
            //padding: 'none',
            //paddingRight: '60px',
            paddingLeft: '5px',
            background: '#26263A',
            overflow: 'auto',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
          }}
        >
          <List sx={{ ml: '1vw' }}>
            <ListItem>
              <ListItemButton onClick={() => handleConnect('metamask')}>
                <Stack
                  direction='row'
                  spacing={3}
                  justifyContent='flex-start'
                  alignItems='center'
                  sx={{ pr: '5vw' }}
                >
                  <Box sx={{ img: { width: 30 } }}>
                    <Metamask />
                  </Box>
                  <Box>
                    <Typography sx={{ fontSize: '12px' }}>MetaMask</Typography>
                    <Typography
                      variant='subtitle'
                      sx={{
                        opacity: '0.5',
                        fontSize: '10px',
                        whiteSpace: 'nowrap',
                      }}
                      gutterBottom
                    >
                      Connect to your MetaMask Wallet
                    </Typography>
                  </Box>
                </Stack>
              </ListItemButton>
            </ListItem>

            <ListItem>
              <ListItemButton onClick={() => handleConnect('walletconnect')}>
                <Stack
                  direction='row'
                  spacing={3}
                  alignItems='center'
                  justifyContent='flex-start'
                >
                  <Box sx={{ img: { width: 30 } }}>
                    <Walletconnect />
                  </Box>
                  <Box>
                    <Typography sx={{ fontSize: '12px' }}>
                      WalletConnect
                    </Typography>
                    <Typography
                      variant='subtitle'
                      component='div'
                      sx={{
                        opacity: '0.5',
                        fontSize: '10px',
                        whiteSpace: 'nowrap',
                      }}
                      gutterBottom
                    >
                      Connect to your WalletConnect
                    </Typography>
                  </Box>
                </Stack>
              </ListItemButton>
            </ListItem>

            <ListItem>
              <ListItemButton onClick={() => handleConnect('phantom')}>
                <Stack
                  direction='row'
                  spacing={3}
                  alignItems='center'
                  justifyContent='flex-start'
                >
                  <Box sx={{ img: { width: 30 } }}>
                    <Phatom />
                  </Box>
                  <Box>
                    <Typography sx={{ fontSize: '12px' }}>Phantom</Typography>
                    <Typography
                      variant='subtitle'
                      component='div'
                      sx={{
                        opacity: '0.5',
                        fontSize: '10px',
                        whiteSpace: 'nowrap',
                      }}
                      gutterBottom
                    >
                      Connect to your Phantom wallet
                    </Typography>
                  </Box>
                </Stack>
              </ListItemButton>
            </ListItem>

            <ListItem>
              <ListItemButton onClick={() => handleConnect('tronlink')}>
                <Stack
                  direction='row'
                  spacing={3}
                  alignItems='center'
                  justifyContent='flex-start'
                >
                  <Box sx={{ img: { width: 30 } }}>
                    <Tronlink />
                  </Box>
                  <Box>
                    <Typography sx={{ fontSize: '12px' }}>TronLink</Typography>
                    <Typography
                      variant='subtitle'
                      component='div'
                      sx={{
                        opacity: '0.5',
                        fontSize: '10px',
                        whiteSpace: 'nowrap',
                      }}
                      gutterBottom
                    >
                      Connect to your TronLink wallet
                    </Typography>
                  </Box>
                </Stack>
              </ListItemButton>
            </ListItem>

            <ListItem>
              <ListItemButton onClick={() => handleConnect('coinbase')}>
                <Stack
                  direction='row'
                  spacing={3}
                  alignItems='center'
                  justifyContent='flex-start'
                >
                  <Box sx={{ img: { width: 30 } }}>
                    <Coinbase />
                  </Box>
                  <Box>
                    <Typography sx={{ fontSize: '12px' }}>Coinbase</Typography>
                    <Typography
                      variant='subtitle'
                      component='div'
                      sx={{
                        opacity: '0.5',
                        fontSize: '10px',
                        whiteSpace: 'nowrap',
                      }}
                      gutterBottom
                    >
                      Connect to your Coinbase wallet
                    </Typography>
                  </Box>
                </Stack>
              </ListItemButton>
            </ListItem>

            <ListItem>
              <ListItemButton onClick={() => handleConnect('binance')}>
                <Stack
                  direction='row'
                  spacing={3}
                  alignItems='flex-start'
                  justifyContent='flex-start'
                >
                  <Box sx={{ img: { width: 30 } }}>
                    <BinanceIcon />
                  </Box>
                  <Box>
                    <Typography sx={{ fontSize: '12px' }}>Binance</Typography>
                    <Typography
                      variant='subtitle'
                      component='div'
                      sx={{
                        opacity: '0.5',
                        fontSize: '10px',
                        whiteSpace: 'nowrap',
                      }}
                      gutterBottom
                    >
                      Connect to your Binance wallet
                    </Typography>
                  </Box>
                </Stack>
              </ListItemButton>
            </ListItem>
            {isMobile && (
              <ListItem>
                <ListItemButton onClick={() => handleConnect('trustwallet')}>
                  <Stack
                    direction='row'
                    spacing={3}
                    alignItems='center'
                    justifyContent='flex-start'
                  >
                    <Box sx={{ img: { width: 30 } }}>
                      <TrustWallet />
                    </Box>
                    <Box>
                      <Typography sx={{ fontSize: '12px' }}>
                        TrustWallet
                      </Typography>
                      <Typography
                        variant='subtitle'
                        component='div'
                        sx={{
                          opacity: '0.5',
                          fontSize: '10px',
                          whiteSpace: 'nowrap',
                        }}
                        gutterBottom
                      >
                        Connect to your TrustWallet
                      </Typography>
                    </Box>
                  </Stack>
                </ListItemButton>
              </ListItem>
            )}
          </List>
          {/* <Card sx={{ textAlign: 'center', p: 2 }}>
                  <CardActionArea onClick={() => handleConnect('metamask')}>
                    <CardContent>
                      <Box sx={{ mb: 1, img: { width: 40 } }}>
                        <Metamask />
                      </Box>
                      <Typography variant='h5' gutterBottom>
                        MetaMask
                      </Typography>
                      <Typography>Connect to your MetaMask Wallet</Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>

                <Card sx={{ textAlign: 'center', p: 2 }}>
                  <CardActionArea onClick={() => handleConnect('walletconnect')}>
                    <CardContent>
                      <Box sx={{ mb: 1, img: { width: 40 } }}>
                        <Walletconnect />
                      </Box>
                      <Typography variant='h5' gutterBottom>
                        WalletConnect
                      </Typography>
                      <Typography>Scan with WalletConnect to connect</Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>

                <Card sx={{ textAlign: 'center', p: 2 }}>
                  <CardActionArea onClick={() => handleConnect('phantom')}>
                    <CardContent>
                      <Box sx={{ mb: 1, img: { width: 40 } }}>
                        <Phatom />
                      </Box>
                      <Typography variant='h5' gutterBottom>
                        Phantom
                      </Typography>
                      <Typography>Connect to your Phantom Wallet</Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
                <Card sx={{ textAlign: 'center', p: 2 }}>
                  <CardActionArea onClick={() => handleConnect('tronlink')}>
                    <CardContent>
                      <Box sx={{ mb: 1, img: { width: 40 } }}>
                        <Tronlink />
                      </Box>
                      <Typography variant='h5' gutterBottom>
                        TronLink
                      </Typography>
                      <Typography>Connect to your TronLink Wallet</Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
                <Card sx={{ textAlign: 'center', p: 2 }}>
                  <CardActionArea onClick={() => handleConnect('coinbase')}>
                    <CardContent>
                      <Box sx={{ mb: 1, img: { width: 40 } }}>
                        <Coinbase />
                      </Box>
                      <Typography variant='h5' gutterBottom>
                        Coinbase
                      </Typography>
                      <Typography>Connect to your Coinbase Wallet</Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>

                <Card sx={{ textAlign: 'center', p: 2 }}>
                  <CardActionArea onClick={() => handleConnect('binance')}>
                    <CardContent>
                      <Box sx={{ mb: 1, img: { width: 40 } }}>
                        <BinanceIcon />
                      </Box>
                      <Typography variant='h5' gutterBottom>
                        Binance
                      </Typography>
                      <Typography>Connect to your Binance Wallet</Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
                {isMobile && (
                  <Card sx={{ textAlign: 'center', p: 2 }}>
                    <CardActionArea onClick={() => handleConnect('trustwallet')}>
                      <CardContent>
                        <Box sx={{ mb: 1, img: { width: 40 } }}>
                          <TrustWallet />
                        </Box>
                        <Typography variant='h5' gutterBottom>
                          Trust wallet
                        </Typography>
                        <Typography>Connect to your Trust Wallet</Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                )} */}
        </DialogContent>
      </Dialog>
    </>
  );
}

export default Web3Dialog;
