import { IChainData } from './types';

const supportedChains: IChainData[] = [
  {
    name: 'Optimistic ethereum',
    short_name: 'optimistic ethereum',
    chain: 'optimistic',
    network: 'mainnet',
    chain_id: 10,
    network_id: 10,
    rpc_url: 'https://mainnet.optimism.io',
    native_currency: {
      symbol: 'ETH',
      name: 'ETH',
      decimals: '12',
      contractAddress: '11111111111111111111111111111111',
      balance: '',
    },
  },
  {
    name: 'Aurora',
    short_name: 'aurora',
    chain: 'aurora',
    network: 'mainnet',
    chain_id: 1313161554,
    network_id: 1313161554,
    rpc_url: 'https://mainnet.aurora.dev',
    native_currency: {
      symbol: 'ETH',
      name: 'ETH',
      decimals: '12',
      contractAddress: '11111111111111111111111111111111',
      balance: '',
    },
  },
  {
    name: 'Arbitrum',
    short_name: 'arbitrum',
    chain: 'arbitrum',
    network: 'mainnet',
    chain_id: 42161,
    network_id: 42161,
    rpc_url: 'https://rpc.ankr.com/arbitrum',
    native_currency: {
      symbol: 'ETH',
      name: 'ETH',
      decimals: '12',
      contractAddress: '11111111111111111111111111111111',
      balance: '',
    },
  },
  {
    name: 'Tronlink',
    short_name: 'tronlink',
    chain: 'tron',
    network: 'mainnet',
    chain_id: 999999999,
    network_id: 999999999,
    rpc_url: 'https://api.mainnet-beta.solana.com',
    native_currency: {
      symbol: 'TRX',
      name: 'trx',
      decimals: '12',
      contractAddress: '11111111111111111111111111111111',
      balance: '',
    },
  },
  {
    name: 'Solana',
    short_name: 'solana',
    chain: 'solana',
    network: 'mainnet',
    chain_id: 1399811149,
    network_id: 1399811149,
    rpc_url: 'https://api.mainnet-beta.solana.com',
    native_currency: {
      symbol: 'SOL',
      name: 'solana',
      decimals: '9',
      contractAddress: '11111111111111111111111111111111',
      balance: '',
    },
  },
  {
    name: 'Polygon Mainnet',
    short_name: 'polygon',
    chain: 'polygon',
    network: 'mainnet',
    chain_id: 137,
    network_id: 137,
    rpc_url: 'https://polygon-rpc.com	',
    native_currency: {
      symbol: 'MATIC',
      name: 'MATIC',
      decimals: '18',
      contractAddress: '0x0000000000000000000000000000000000001010',
      balance: '',
    },
  },
  {
    name: 'Polygon testnet',
    short_name: 'polygon',
    chain: 'polygon',
    network: 'testnet',
    chain_id: 80001,
    network_id: 80001,
    rpc_url: 'https://polygontestapi.terminet.io/rpc',
    native_currency: {
      symbol: 'MATIC',
      name: 'MATIC',
      decimals: '18',
      contractAddress: '0x0000000000000000000000000000000000001010',
      balance: '',
    },
  },
  {
    name: 'Ethereum Mainnet',
    short_name: 'eth',
    chain: 'ETH',
    network: 'mainnet',
    chain_id: 1,
    network_id: 1,
    rpc_url: 'https://mainnet.infura.io/v3/%API_KEY%',
    native_currency: {
      symbol: 'ETH',
      name: 'Ethereum',
      decimals: '18',
      contractAddress: '',
      balance: '',
    },
  },
  {
    name: 'Ethereum Ropsten',
    short_name: 'rop',
    chain: 'ETH',
    network: 'ropsten',
    chain_id: 3,
    network_id: 3,
    rpc_url: 'https://ropsten.infura.io/v3/%API_KEY%',
    native_currency: {
      symbol: 'ETH',
      name: 'Ethereum',
      decimals: '18',
      contractAddress: '',
      balance: '',
    },
  },
  {
    name: 'Ethereum Rinkeby',
    short_name: 'rin',
    chain: 'ETH',
    network: 'rinkeby',
    chain_id: 4,
    network_id: 4,
    rpc_url: 'https://rinkeby.infura.io/v3/%API_KEY%',
    native_currency: {
      symbol: 'ETH',
      name: 'Ethereum',
      decimals: '18',
      contractAddress: '',
      balance: '',
    },
  },
  {
    name: 'Ethereum Görli',
    short_name: 'gor',
    chain: 'ETH',
    network: 'goerli',
    chain_id: 5,
    network_id: 5,
    rpc_url: 'https://goerli.infura.io/v3/%API_KEY%',
    native_currency: {
      symbol: 'ETH',
      name: 'Ethereum',
      decimals: '18',
      contractAddress: '',
      balance: '',
    },
  },
  {
    name: 'RSK Mainnet',
    short_name: 'rsk',
    chain: 'RSK',
    network: 'mainnet',
    chain_id: 30,
    network_id: 30,
    rpc_url: 'https://public-node.rsk.co',
    native_currency: {
      symbol: 'RSK',
      name: 'RSK',
      decimals: '18',
      contractAddress: '',
      balance: '',
    },
  },
  {
    name: 'Ethereum Kovan',
    short_name: 'kov',
    chain: 'ETH',
    network: 'kovan',
    chain_id: 42,
    network_id: 42,
    rpc_url: 'https://kovan.infura.io/v3/%API_KEY%',
    native_currency: {
      symbol: 'ETH',
      name: 'Ethereum',
      decimals: '18',
      contractAddress: '',
      balance: '',
    },
  },
  {
    name: 'Ethereum Classic Mainnet',
    short_name: 'etc',
    chain: 'ETC',
    network: 'mainnet',
    chain_id: 61,
    network_id: 1,
    rpc_url: 'https://ethereumclassic.network',
    native_currency: {
      symbol: 'ETH',
      name: 'Ethereum',
      decimals: '18',
      contractAddress: '',
      balance: '',
    },
  },
  {
    name: 'POA Network Sokol',
    short_name: 'poa',
    chain: 'POA',
    network: 'sokol',
    chain_id: 77,
    network_id: 77,
    rpc_url: 'https://sokol.poa.network',
    native_currency: {
      symbol: 'POA',
      name: 'POA',
      decimals: '18',
      contractAddress: '',
      balance: '',
    },
  },
  {
    name: 'POA Network Core',
    short_name: 'skl',
    chain: 'POA',
    network: 'core',
    chain_id: 99,
    network_id: 99,
    rpc_url: 'https://core.poa.network',
    native_currency: {
      symbol: 'POA',
      name: 'POA',
      decimals: '18',
      contractAddress: '',
      balance: '',
    },
  },
  {
    name: 'xDAI Chain',
    short_name: 'xdai',
    chain: 'POA',
    network: 'dai',
    chain_id: 100,
    network_id: 100,
    rpc_url: 'https://dai.poa.network',
    native_currency: {
      symbol: 'xDAI',
      name: 'xDAI',
      decimals: '18',
      contractAddress: '',
      balance: '',
    },
  },
  {
    name: 'Callisto Mainnet',
    short_name: 'clo',
    chain: 'callisto',
    network: 'mainnet',
    chain_id: 820,
    network_id: 1,
    rpc_url: 'https://clo-geth.0xinfra.com/',
    native_currency: {
      symbol: 'CLO',
      name: 'CLO',
      decimals: '18',
      contractAddress: '',
      balance: '',
    },
  },
  {
    name: 'Binance Smart Chain',
    short_name: 'bsc',
    chain: 'smartchain',
    network: 'mainnet',
    chain_id: 56,
    network_id: 56,
    rpc_url: 'https://bsc-dataseed1.defibit.io/',
    native_currency: {
      symbol: 'BNB',
      name: 'BNB',
      decimals: '18',
      contractAddress: '',
      balance: '',
    },
  },
  {
    name: 'Binance Smart Chain (testnet)',
    short_name: 'bsc',
    chain: 'smartchain',
    network: 'mainnet',
    chain_id: 97,
    network_id: 97,
    rpc_url: 'https://bsc-dataseed1.defibit.io/',
    native_currency: {
      symbol: 'BNB',
      name: 'BNB',
      decimals: '18',
      contractAddress: '',
      balance: '',
    },
  },
  {
    name: 'Celo Mainnet',
    short_name: 'celo',
    chain: 'celo',
    network: 'mainnet',
    chain_id: 42220,
    network_id: 42220,
    rpc_url: 'https://forno.celo.org',
    native_currency: {
      symbol: 'CELO',
      name: 'CELO',
      decimals: '18',
      contractAddress: '',
      balance: '',
    },
  },
  {
    name: 'Celo Alfajores Testnet',
    short_name: 'celo',
    chain: 'celo',
    network: 'alfajores',
    chain_id: 44787,
    network_id: 44787,
    rpc_url: 'https://alfajores-forno.celo-testnet.org',
    native_currency: {
      symbol: 'CELO',
      name: 'CELO',
      decimals: '18',
      contractAddress: '',
      balance: '',
    },
  },
  {
    name: 'Celo Baklava Testnet',
    short_name: 'celo',
    chain: 'celo',
    network: 'baklava',
    chain_id: 62320,
    network_id: 62320,
    rpc_url: 'https://baklava-forno.celo-testnet.org',
    native_currency: {
      symbol: 'CELO',
      name: 'CELO',
      decimals: '18',
      contractAddress: '',
      balance: '',
    },
  },
  {
    name: 'Fantom Opera',
    short_name: 'FTM',
    chain: 'FTM',
    network: 'ftm',
    chain_id: 250,
    network_id: 250,
    rpc_url: 'https://rpc.ftm.tools/',
    native_currency: {
      symbol: 'FTM',
      name: 'FTM',
      decimals: '18',
      contractAddress: '',
      balance: '',
    },
  },
  {
    name: 'Fantom testnet',
    short_name: 'FTM',
    chain: 'FTM',
    network: 'ftm',
    chain_id: 0xfa2,
    network_id: 0xfa2,
    rpc_url: 'https://rpc.testnet.fantom.network/',
    native_currency: {
      symbol: 'FTM',
      name: 'FTM',
      decimals: '18',
      contractAddress: '',
      balance: '',
    },
  },
  {
    name: 'Avalanche Network',
    short_name: 'AVAX',
    chain: 'AVAX',
    network: 'avax',
    chain_id: 43114,
    network_id: 43114,
    rpc_url: 'https://api.avax.network/ext/bc/C/rpc',
    native_currency: {
      symbol: 'AVAX',
      name: 'AVAX',
      decimals: '18',
      contractAddress: '',
      balance: '',
    },
  },
  {
    name: 'Avalanche FUJI C-Chain',
    short_name: 'AVAX',
    chain: 'AVAX',
    network: 'avax',
    chain_id: 43113,
    network_id: 43113,
    rpc_url: 'https://api.avax-test.network/ext/bc/C/rpc',
    native_currency: {
      symbol: 'AVAX',
      name: 'AVAX',
      decimals: '18',
      contractAddress: '',
      balance: '',
    },
  },
];

export default supportedChains;
