/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { useSwapHook } from './hooks';
import { VentiAlert } from '../alert-modal';
import http from '../../http';
import FUNCTION_UTILS from '../generic-functions';
import { Step1 } from './step1';
import { Step2 } from './step2';
interface IProps {
  amount: any;
  setAmount: any;
  fromCurrency: any;
  setFromCurrency: any;
  poolsList: any;
  amountReceive: any;
  toCurrency: any;
  setToCurrency: any;
  poolsListSecondary: any;
  walletAddress: any;
  handleSubmit: any;
  toCurrencyNetworks: any;
  handleSwitchBtn: any;
  setSwitchState: any;
  fromUsdRate: number;
  toUsdRate: number;
  web3States: any;
  ethToUsdRate: any;
  swapInProgress: any;
  setSwapInProgress: any;
  setAmountReceive: any;
  updateAssets: () => any;
  getCoinDetails: () => void;
  setWeb3State: any;
  setSwapStep: any;
  swapStep: any;
  accountTokens: any;
  setAccountTokens: any;
  switchState: any;
}

export function SwapComponent({
  amount,
  setAmount,
  fromCurrency,
  setFromCurrency,
  poolsList,
  amountReceive,
  toCurrency,
  walletAddress,
  setToCurrency,
  poolsListSecondary,
  handleSubmit,
  toCurrencyNetworks,
  handleSwitchBtn,
  setSwitchState,
  fromUsdRate,
  toUsdRate,
  web3States,
  ethToUsdRate,
  swapInProgress,
  setSwapInProgress,
  setAmountReceive,
  getCoinDetails,
  setWeb3State,
  setSwapStep,
  swapStep,
  accountTokens,
  setAccountTokens,
  switchState,
  updateAssets,
}: IProps) {
  const {
    swapETHchain,
    swapETHchainTokens,
    swapSolanaChain,
    swapTrxChain,
    swapTerraChain,
    swapWalletConnect,
    swapBnbExtensionchain,
    swapBSCchainTokens,
    swapAssetWalletConnect,
    swapTrxChainAssets,
    swapTrustChainTokens,
    swapTrustchain,
  } = useSwapHook();
  const [receiverAddress, setReceiverAddress] = React.useState<string>('');
  const [selectedNetwork, setSelectedNetwork] = React.useState<any>({
    network: 'Select Receiving Network',
    withdrawMin: '0',
    withdrawEnable: true,
  });
  const [receiverErr, setReceiverErr] = React.useState<boolean>(false);
  const [trxHash, setTrxHash] = React.useState<string>('');
  const [alertOpen, setAlertOpen] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [isMemo, setIsMemo] = React.useState<boolean>(false);
  const [tag, setTag] = React.useState<string>('');
  const [memoErr, setMemoErr] = React.useState<boolean>(false);
  const [chainNetwork, setChainNetwork] = React.useState<any>({
    chainId: '',
    addressw: '',
  });

  useEffect(() => {
    if (toCurrencyNetworks && Object.keys(toCurrencyNetworks).length > 1) {
      setSelectedNetwork(toCurrencyNetworks[0]);
      setLoading(false);
    } else {
      setSelectedNetwork(toCurrencyNetworks[0]);
      setLoading(false);
    }
  }, [toCurrencyNetworks]);

  useEffect(() => {
    if (toCurrency && toCurrency.toLocaleLowerCase().includes('select')) {
      setSelectedNetwork({
        network: 'Select Receiving Network',
        withdrawMin: '0',
      });
    }
  }, [toCurrency]);

  const handleMetamaskSwap = async () => {
    let hash: string = '';
    console.log('web3States = ', web3States);
    if (fromCurrency === FUNCTION_UTILS.isCurrentSymbol(web3States.chainId)) {
      hash =
        (await swapETHchain(amount, walletAddress, web3States.connector)) || '';
    } else {
      const currentAmount: string =
        FUNCTION_UTILS.getCurrentAmount(
          web3States.chainId,
          amount,
          fromCurrency
        ) || amount;
      hash =
        (await swapETHchainTokens(
          currentAmount,
          walletAddress,
          fromCurrency,
          web3States.chainId,
          web3States.connector
        )) || '';
    }
    return hash;
  };

  const handleTrustWalletSwap = async () => {
    let hash: string = '';
    if (fromCurrency === FUNCTION_UTILS.isCurrentSymbol(web3States.chainId)) {
      hash = (await swapTrustchain(amount, walletAddress)) || '';
    } else {
      const currentAmount: string =
        FUNCTION_UTILS.getCurrentAmount(
          web3States.chainId,
          amount,
          fromCurrency
        ) || amount;
      hash =
        (await swapTrustChainTokens(
          currentAmount,
          walletAddress,
          fromCurrency,
          web3States.chainId
        )) || '';
    }
    return hash;
  };

  const handleSwap = async () => {
    let hash: string = '';
    if (receiverAddress !== '') {
      setMemoErr(false);
      setReceiverErr(false);
      if (localStorage.getItem('wallet') === 'tronlink') {
        if (fromCurrency === 'TRX') {
          hash = (await swapTrxChain(amount, walletAddress)) || '';
        } else {
          const currentAmount: string =
            FUNCTION_UTILS.getCurrentAmount(
              999999999,
              amount,
              fromCurrency,
              'tronlink'
            ) || '';
          hash =
            (await swapTrxChainAssets(
              currentAmount,
              walletAddress,
              fromCurrency
            )) || '';
        }
        setTrxHash(hash);
        if (!hash) {
          setSwapInProgress(false);
          return;
        }
        handleSubmit(
          hash,
          receiverAddress,
          selectedNetwork.network,
          isMemo ? tag : ''
        );
        return;
      }
      if (
        localStorage.getItem('wallet') === 'metamask' ||
        localStorage.getItem('wallet') === 'coinbase'
      ) {
        hash = await handleMetamaskSwap();
        setTrxHash(hash);
        if (!hash) {
          setSwapInProgress(false);
          return;
        }
        handleSubmit(
          hash,
          receiverAddress,
          selectedNetwork.network,
          isMemo ? tag : ''
        );
        return;
      }
      if (localStorage.getItem('wallet') === 'trustwallet') {
        hash = await handleTrustWalletSwap();
        setTrxHash(hash);
        if (!hash) {
          setSwapInProgress(false);
          return;
        }
        handleSubmit(
          hash,
          receiverAddress,
          selectedNetwork.network,
          isMemo ? tag : ''
        );
        return;
      }
      if (localStorage.getItem('wallet') === 'walletconnect') {
        if (
          fromCurrency === FUNCTION_UTILS.isCurrentSymbol(web3States.chainId)
        ) {
          hash = await swapWalletConnect(web3States, amount, walletAddress);
        } else {
          const currentAmount: string =
            FUNCTION_UTILS.getCurrentAmount(
              web3States.chainId,
              amount,
              fromCurrency
            ) || amount;
          hash =
            (await swapAssetWalletConnect(
              currentAmount,
              walletAddress,
              fromCurrency,
              web3States.chainId,
              web3States
            )) || '';
        }
        setTrxHash(hash);
        if (!hash) {
          setSwapInProgress(false);
          return;
        }
        handleSubmit(
          hash,
          receiverAddress,
          selectedNetwork.network,
          isMemo ? tag : ''
        );
        return;
      }
      if (fromCurrency === 'LUNA') {
        hash = (await swapTerraChain(amount, walletAddress)) || '';
        setTrxHash(hash);
        if (hash) {
          handleSubmit(
            hash,
            receiverAddress,
            selectedNetwork.network,
            isMemo ? tag : ''
          );
        } else {
          setSwapInProgress(false);
        }
        return;
      }

      if (localStorage.getItem('wallet') === 'phantom') {
        hash = (await swapSolanaChain(amount, walletAddress)) || '';
        setTrxHash(hash);
        if (!hash) {
          setSwapInProgress(false);
          return;
        }
        handleSubmit(
          hash,
          receiverAddress,
          selectedNetwork.network,
          isMemo ? tag : ''
        );
        return;
      }
      if (localStorage.getItem('wallet') === 'binance') {
        if (fromCurrency === 'BNB') {
          hash = (await swapBnbExtensionchain(amount, walletAddress)) || '';
        } else {
          hash =
            (await swapBSCchainTokens(
              amount,
              walletAddress,
              fromCurrency,
              web3States.chainId
            )) || '';
        }
        setTrxHash(hash);
        if (!hash) {
          setSwapInProgress(false);
          return;
        }
        handleSubmit(
          hash,
          receiverAddress,
          selectedNetwork.network,
          isMemo ? tag : ''
        );
        return;
      }
    } else {
      setReceiverErr(true);
      setSwapInProgress(false);
      FUNCTION_UTILS.showNotification(
        'Please Enter receiving account address.'
      );
    }
    if (!hash) {
      setSwapInProgress(false);
    }
  };

  const handleConfirm = () => {
    setAlertOpen(false);
    setSwapInProgress(true);
    handleSwap();
  };

  const handleOpenModal = () => {
    const minLimitReceive =
      selectedNetwork &&
      parseFloat(amountReceive) - parseFloat(selectedNetwork.withdrawFee);
    if (parseFloat(web3States.balanceWei) <= parseFloat(amount)) {
      FUNCTION_UTILS.showNotification('Insufficient balance');
    } else if (
      minLimitReceive &&
      (!selectedNetwork.withdrawEnable ||
        parseFloat(selectedNetwork?.withdrawMin) > minLimitReceive)
    ) {
      FUNCTION_UTILS.showNotification(
        !selectedNetwork.withdrawEnable
          ? 'Receiving for this token is temporarily disabled'
          : 'Receiving amount is less than minimum required amount for this network'
      );
    } else if (web3States.coin.toLowerCase() !== fromCurrency.toLowerCase()) {
      FUNCTION_UTILS.showNotification('Please connect correct wallet');
    } else if (
      !web3States.balanceWei ||
      web3States.balanceWei === undefined ||
      web3States.balanceWei === null
    ) {
      FUNCTION_UTILS.showNotification(
        'Wallet not responding please refresh the page'
      );
    } else if (parseFloat(web3States.balanceWei) > amount) {
      setAlertOpen(true);
    }
  };

  const IsSwapDisabled = () => {
    return (
      selectedNetwork.network === 'Select Receiving Network' ||
      toCurrency.includes('Select') ||
      fromCurrency.includes('Select') ||
      amount === 0 ||
      amount === '0'
    );
  };

  useEffect(() => {
    setAmountReceive(0);
  }, [fromCurrency]);

  const handleCheckMinimum = () => {
    let commission: string = process.env.REACT_APP_COMMISSION
      ? process.env.REACT_APP_COMMISSION
      : '0.02';
    const finalAmount =
      parseFloat(amount) - parseFloat(amount) * parseFloat(commission);
    http
      .get(
        `/binance/request-quote?quoteAsset=${fromCurrency}&baseAsset=${toCurrency}&quoteQty=${finalAmount.toFixed(
          6
        )}`
      )
      .then(res => {
        handleOpenModal();
      })
      .catch(err => {
        FUNCTION_UTILS.showNotification('Amount is less than minimum value');
      });
  };

  useEffect(() => {
    if (selectedNetwork) {
      if (selectedNetwork.memoRegex && selectedNetwork.memoRegex.length > 0) {
        setIsMemo(true);
      } else {
        setIsMemo(false);
      }
    }
  }, [selectedNetwork]);

  useEffect(() => {
    if (
      web3States.addressw &&
      (web3States.chainId !== chainNetwork.chainId ||
        web3States.addressw !== chainNetwork.addressw)
    ) {
      setChainNetwork({
        chainId: web3States.chainId,
        addressw: web3States.addressw,
      });
      updateAssets();
    }
  }, [web3States]);

  return (
    <>
      <VentiAlert
        handleConfirm={handleConfirm}
        open={alertOpen}
        setOpen={setAlertOpen}
      />
      {swapStep === 0 ? (
        <Step1
          amount={amount}
          amountReceive={amountReceive}
          fromCurrency={fromCurrency}
          setAmount={setAmount}
          toCurrency={toCurrency}
          poolsList={poolsList}
          poolsListSecondary={poolsListSecondary}
          setToCurrency={setToCurrency}
          setFromCurrency={setFromCurrency}
          handleSwitchBtn={handleSwitchBtn}
          setSwitchState={setSwitchState}
          setSwapStep={setSwapStep}
          web3States={web3States}
          fromUsdRate={fromUsdRate}
          switchState={switchState}
        />
      ) : (
        <Step2
          getCoinDetails={getCoinDetails}
          loading={loading}
          selectedNetwork={selectedNetwork}
          setSelectedNetwork={setSelectedNetwork}
          toCurrency={toCurrency}
          toCurrencyNetworks={toCurrencyNetworks}
          trxHash={trxHash}
          isMemo={isMemo}
          memoErr={memoErr}
          setTag={setTag}
          handleCheckMinimum={handleCheckMinimum}
          swapInProgress={swapInProgress}
          IsSwapDisabled={IsSwapDisabled}
          receiverAddress={receiverAddress}
          setReceiverAddress={setReceiverAddress}
          receiverErr={receiverErr}
        />
      )}
    </>
  );
}
