import { Grid, Typography } from '@mui/material';
import React from 'react';
import MouseOverPopover from '../popover';

export function RowComponent({ title, value }) {
  return (
    <Grid container spacing={3} sx={{ paddingTop: '10px' }}>
      <Grid item xs={12} md={12} lg={12} sm={12}>
        <Typography
          sx={{
            fontSize: '11px',
            color: '#FFFFFF',
            opacity: '0.8',
            wordBreak: 'break-all',
          }}
        >
          <MouseOverPopover
            value={value}
          >{`${title} ${value}`}</MouseOverPopover>
        </Typography>
      </Grid>
      {/* <Grid
        item
        xs={6}
        md={6}
        lg={6}
        sm={6}
        sx={{ display: 'flex', justifyContent: 'end' }}
      >
        {children}
      </Grid> */}
    </Grid>
  );
}
