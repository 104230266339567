import React, { useEffect, useState } from 'react';
import { Asset } from './components';
import { Box, Typography } from '@mui/material';

interface AssetsProps {
  address?: string;
  chainId: number;
  accountTokens: any;
}

export const Assets = ({ address, chainId, accountTokens }: AssetsProps) => {
  const [assetsList, setAssetsList] = useState<any>('');

  useEffect(() => {
    if (accountTokens && Object.keys(accountTokens).length > 0) {
      setAssetsList(accountTokens);
    }
  }, [accountTokens]);

  return accountTokens &&
    assetsList &&
    Object.keys(assetsList || null).length > 0 ? (
    <>
      {Object.entries(assetsList || {})
        ?.reverse()
        .map(([code, asset]) => (
          <div
            key={code}
            className='single-coin btc-coin eth'
            data-fsyms={code}
          >
            <Asset addressAsset={asset ? asset : ''} chainId={chainId} />
          </div>
        ))}
    </>
  ) : (
    <Box sx={{ display: 'flex', gap: '10px' }}>
      <Box
        component='div'
        sx={{
          width: '100%',
          borderRadius: '10px',
          marginBottom: '10px',
        }}
      >
        <Box
          sx={{
            width: '100%',
            marginTop: '100px',
            maxHeight: '200px',
            minHeight: '200px',
            overflow: 'auto',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          <Box
            sx={{
              width: '60%',
              margin: 'auto',
              textAlign: 'center',
            }}
          >
            <img alt='' src='images/assets.svg' />
            <Typography sx={{ fontSize: '12px', color: '#707585' }}>
              Nothing Found
            </Typography>
            <Typography
              sx={{
                fontSize: '10px',
                color: '#707585',
                paddingTop: '15px',
                paddingBottom: '15px',
              }}
            >
              We weren’t able to locate any assets linked to your wallet
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
