import React, { useEffect, useState } from 'react';
import { Box, Typography, Stack, Divider, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';
import FUNCTION_UTILS from '../generic-functions';

export function NewsFeedComponent() {
  const [newsData, setNewsData] = useState([]);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.between(1501, 2450));
  const isLaptop = useMediaQuery(theme.breakpoints.between(1240, 1430));
  const isMac = useMediaQuery(theme.breakpoints.between(1440, 1500));
  const isMiniDesktop = useMediaQuery(theme.breakpoints.between(1280, 1364));
  const HandleResponsiveness = () => {
    if (FUNCTION_UTILS.getMobileOperatingSystem() === 'iOS') {
      if (matchMedia('(min-width:370px) and (max-width:375px)').matches) {
        return '50vh';
      } else if (
        matchMedia('(min-width:400px) and (max-width:420px)').matches
      ) {
        return '58vh';
      } else if (
        matchMedia('(min-width:360px) and (max-width:369px)').matches
      ) {
        return '50vh';
      } else if (
        matchMedia('(min-width:389px) and (max-width:400px)').matches
      ) {
        return '54vh';
      } else if (
        matchMedia('(min-width:425px) and (max-width:440px)').matches
      ) {
        return '59vh';
      } else if (
        matchMedia('(min-width:800px) and (max-width:820px)').matches
      ) {
        return '60vh';
      } else {
        return '30vh';
      }
    } else if (FUNCTION_UTILS.getMobileOperatingSystem() === 'Android') {
      if (matchMedia('(min-width:370px) and (max-width:375px)').matches) {
        return '50vh';
      } else if (
        matchMedia('(min-width:400px) and (max-width:420px)').matches
      ) {
        return '69vh';
      } else if (
        matchMedia('(min-width:360px) and (max-width:369px)').matches
      ) {
        return '66vh';
      } else if (
        matchMedia('(min-width:389px) and (max-width:400px)').matches
      ) {
        return '61.5vh';
      } else if (
        matchMedia('(min-width:425px) and (max-width:440px)').matches
      ) {
        return '59vh';
      } else {
        return '61.5vh';
      }
    } else {
      if (isDesktop) {
        return '41.5vh';
      } else if (isLaptop) {
        return '34.6vh';
      } else if (isMiniDesktop) {
        return '34.6vh';
      } else if (isMac) {
        return '40vh';
      } else if (
        matchMedia('(min-width:800px) and (max-width:820px)').matches
      ) {
        return '72vh';
      } else if (matchMedia('(min-width:961px)').matches) {
        return '63vh';
      } else {
        return '25vh';
      }
    }
  };
  const API_KEY =
    '8cf7209cb162104bc80fe1e247e709b13a3fa5051c3b948bb37e9f6bd4e66ab3';
  const NewsFeed = async () => {
    const response = await fetch(
      'https://min-api.cryptocompare.com/data/v2/news/?lang=EN&api_key=' +
        API_KEY
    );
    const json = await response.json();

    const AllNewsFeed = json.Data;
    return {
      AllNewsFeed,
    };
  };
  async function DataoNewsfeed() {
    let { AllNewsFeed } = await NewsFeed();
    setNewsData(AllNewsFeed);
  }

  useEffect(() => {
    DataoNewsfeed();
  }, []);

  return (
    <div>
      <Box
        component='div'
        sx={{
          height: HandleResponsiveness(),
          overflow: 'auto',
          '&::-webkit-scrollbar': {
            width: '12px !important',
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'rgba(221, 217, 219, 0.5)',
            borderRadius: '3px !important',
          },
        }}
      >
        {newsData &&
          Object.keys(newsData).length > 0 &&
          newsData.map(news => (
            <Stack spacing={5} alignItems='stretch' direction='column'>
              <div>
                <Stack direction='row' spacing={-1}>
                  <Typography
                    variant='h5'
                    sx={{ color: '#4aa1ff', ml: '16px', mb: '5px', mt: '-5px' }}
                  >
                    &#8226;
                  </Typography>
                  <div>
                    <Typography
                      sx={{
                        cursor: 'pointer',
                        textDecoration: 'none',
                        color: '#fffff',
                        display: 'block',
                        padding: '5px 20px 0px 20px',
                        fontSize: '10px',
                        lineHeight: '1.3',
                      }}
                      onClick={() =>
                        news.url && window.open(news.url, '_blank')
                      }
                      variant='h6'
                      component='div'
                    >
                      {news.title}
                    </Typography>
                    <Typography
                      sx={{
                        color: 'white',
                        opacity: '0.5',
                        padding: '1px 10px 10px 20px',
                        fontSize: '8px',
                      }}
                    >
                      {`${moment(
                        new Date(news.published_on * 1000).toString()
                      ).fromNow()} Updated ${news.source_info.name}`}
                    </Typography>
                  </div>
                </Stack>
                <Divider />
              </div>
            </Stack>
          ))}
      </Box>
    </div>
  );
}
