/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Container from '@mui/material/Container';
import * as React from 'react';
import CachedIcon from '@mui/icons-material/Cached';
import {
  SwapComponent,
  HeaderComponent,
  ChartComponent,
  NewsFeedComponent,
  StackComponent,
  VerifyTransaction,
  CardComponent,
  CoustomTabs,
  CustomModal,
  VentiData,
} from './components';
import http from './http';
import { DepositAddress, Pool, verify, tokenList } from './interface';
import Toastify from 'toastify-js';
import 'toastify-js/src/toastify.css';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { client } from 'defi-sdk';
import axios from 'axios';
import { Assets } from './components/assets-components/Assets';
import { BNBAssets } from './components/assets-components/bnbAssets';
import { Portfo } from './components/assets-components/Portfolio';
import { arraySortByKName } from './utils/global.utils';
import { DisclaimerAlert } from './components/disclaimer-modal';
import FUNCTION_UTILS from './components/generic-functions';
import { useTheme } from '@mui/material/styles';
import { BUYABLE_CHAINS_MAP } from './network';
import { FROM_TOKENS, TO_TOKENS } from './utils/tokensToRemove';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

import {
  UserBscassets,
  UserEthassets,
  UserPolyAssets,
} from './components/assets-components/hooks/UserBscassets';
import { useSwapHook } from './components/swap/hooks';
import ReactGA from 'react-ga';
export const endpoint = 'wss://api-v4.zerion.io/';
export const API_TOKEN = 'Ventiswap.naTLgmR7DH1LLlZZXWLtqh6xRZ7D6wa6';

const INITIAL_STATE = {
  fetching: false,
  addressw: '',
  web3: null,
  provider: null,
  connected: false,
  chainId: 1,
  networkId: 1,
  assets: [],
  showModal: false,
  pendingRequest: false,
  result: null,
  balanceWei: 0,
  coin: '-',
  wallet: '',
};

client.configure({
  url: endpoint,
  apiToken: API_TOKEN,
  hooks: {
    willSendRequest: request => {
      (request.payload as any).lol = 'lol';
      return request;
    },
  },
});

ReactGA.initialize('UA-33264083-4');

Object.assign(window, { client });

function App() {
  const theme: any = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.between(1501, 2450));
  const isLaptop = useMediaQuery(theme.breakpoints.between(1240, 1430));
  const isMobile = useMediaQuery(theme.breakpoints.between(320, 480));
  const isMiniDesktop = useMediaQuery(theme.breakpoints.between(1280, 1364));
  const [amount, setAmount] = React.useState(0.0);
  const [exchangeRate, setExchangeRate] = React.useState(0);
  const [fromCurrency, setFromCurrency] = React.useState<any>('Select');
  const [amountReceive, setAmountReceive] = React.useState('0.0');
  const [toCurrency, setToCurrency] = React.useState<any>('Select');
  const [poolsData, setPoolsData] = React.useState<Pool[]>([]);
  const [poolsList, setPoolsList] = React.useState<string[]>([]);
  const [modalopen, setModalopen] = React.useState<boolean>(false);
  const [walletAddress, setWalletAddress] =
    React.useState<null | DepositAddress>(null);
  const [errors, setErrors] = React.useState<string[]>([]);
  const [verificationData, setVerificationData] = React.useState<null | verify>(
    null
  );
  const [poolsListSecondary, setPoolsListSecondary] = React.useState<string[]>(
    []
  );
  const [coinsNetworks, setCoinsNetworks] = React.useState<any>([]);
  const [toCurrencyNetworks, setToCurrencyNetworks] = React.useState<any>([
    {
      network: 'Select Receiving Network',
      withdrawMin: '0',
      withdrawEnable: true,
    },
  ]);
  const { getSolanaTokenBalance } = useSwapHook();
  const [switchState, setSwitchState] = React.useState<boolean>(false);
  const [fromUsdRate, setFromUsdRate] = React.useState<number>(0.0);
  const [toUsdRate, setToUsdRate] = React.useState<number>(0.0);
  const [web3States, setWeb3State] = React.useState<any>(INITIAL_STATE);
  const [trxHashVerification, setTrxHashVerification] = React.useState<
    string | null
  >(null);
  const [ethToUsdRate, setEthToUsdRate] = React.useState<any>(0.0);
  const [swapInProgress, setSwapInProgress] = React.useState<boolean>(false);
  const [open, setOpen] = React.useState<boolean>(true);
  const [swapStep, setSwapStep] = React.useState(0);
  const isTabletMini = useMediaQuery(theme.breakpoints.between(600, 1280));
  const [accountTokens, setAccountTokens] = React.useState<any>('');
  const [loadTransaction, setLoadTransaction] = React.useState(false);
  const isMac = useMediaQuery(theme.breakpoints.between(1440, 1500));

  // React.useEffect(() => {
  //   const retrievedObject = localStorage.getItem('web3States');
  //   if (retrievedObject) {
  //     // console.log(retrievedObject);

  //     // setWeb3State(JSON.parse(retrievedObject));
  //   }
  // }, []);
  const [value, setValue] = React.useState(0);
  const polyassets = UserPolyAssets(web3States?.addressw);
  const bscassets = UserBscassets(web3States.addressw);
  const ethassets = UserEthassets(web3States.addressw);
  const HandleResponsiveness = () => {
    if (FUNCTION_UTILS.getMobileOperatingSystem() === 'iOS') {
      if (matchMedia('(min-width:370px) and (max-width:375px)').matches) {
        return '50vh';
      } else if (
        matchMedia('(min-width:400px) and (max-width:420px)').matches
      ) {
        return '55vh';
      } else if (
        matchMedia('(min-width:360px) and (max-width:369px)').matches
      ) {
        return '46vh';
      } else if (
        matchMedia('(min-width:389px) and (max-width:400px)').matches
      ) {
        return '50vh';
      } else if (
        matchMedia('(min-width:425px) and (max-width:440px)').matches
      ) {
        return '55vh';
      } else {
        return '25vh';
      }
    } else if (FUNCTION_UTILS.getMobileOperatingSystem() === 'Android') {
      if (matchMedia('(min-width:370px) and (max-width:375px)').matches) {
        return '42vh';
      } else if (
        matchMedia('(min-width:400px) and (max-width:420px)').matches
      ) {
        return '65vh';
      } else if (
        matchMedia('(min-width:360px) and (max-width:369px)').matches
      ) {
        return '63vh';
      } else if (
        matchMedia('(min-width:389px) and (max-width:400px)').matches
      ) {
        return '59vh';
      } else if (
        matchMedia('(min-width:425px) and (max-width:440px)').matches
      ) {
        return '53vh';
      } else if (
        matchMedia('(min-width:800px) and (max-width:820px)').matches
      ) {
        return '62vh';
      } else {
        return '59vh';
      }
    } else {
      if (isDesktop) {
        return '22.5vh';
      } else if (isLaptop) {
        return '23vh';
      } else if (isMiniDesktop) {
        return '23vh';
      } else if (matchMedia('(min-width:961px)').matches) {
        return '60vh';
      } else if (
        matchMedia('(min-width:800px) and (max-width:820px)').matches
      ) {
        return '70vh';
      } else {
        return '36vh';
      }
    }
  };
  async function getCoinDetails() {
    try {
      const { data } = await http.get('/binance/pools');
      const { data: coinsData } = await http.get('/binance/coin-details');
      setCoinsNetworks(coinsData);
      // let allCoinsNetworkLists = coinsData.map((x: any) => x.networkList)
      // allCoinsNetworkLists = [].concat.apply([], allCoinsNetworkLists)
      // let allNetworksNames = allCoinsNetworkLists.map((x: any) => x.network)
      // const unique = [...new Set(allNetworksNames)];
      // // console.log('============allCoinsNetworkLists========================');
      // // console.log(unique);
      // // console.log('====================================');
      setPoolsData(data);
    } catch (e: any) {
      // console.log('pools', e);
    }
  }
  React.useEffect(() => {
    getCoinDetails();
  }, []);

  React.useEffect(() => {
    if (
      toCurrency &&
      coinsNetworks &&
      coinsNetworks.length > 0 &&
      Object.keys(coinsNetworks).length > 0
    ) {
      try {
        const nets = coinsNetworks?.find((x: any) => x.coin === toCurrency);
        let temp = nets.networkList.map((x: any) => x);
        // let temp2 = nets.networkList.map((x: any) => x.withdrawMin);
        // // console.log('temp2 = ', temp);
        temp.unshift({
          network: 'Select Receiving Network',
          withdrawMin: '0',
          withdrawEnable: true,
        });
        setToCurrencyNetworks(temp);
      } catch (e) {
        setToCurrencyNetworks([
          {
            network: 'Select Receiving Network',
            withdrawMin: '0',
            withdrawEnable: true,
          },
        ]);
        // console.log(e);
      }
    }

    if (switchState) {
      let temp: string[] = [];
      poolsData
        .filter(element => element.poolName.indexOf(fromCurrency) !== -1)
        .forEach(element => {
          temp.push(...element.assets);
        });
      const list: string[] = temp.filter(
        (value, index, self) =>
          self.indexOf(value) === index && value !== fromCurrency
      );
      let toCurrencyIndex: string | null =
        list.find(x => x === toCurrency) || null;
      setPoolsListSecondary(list.filter((x: any) => !TO_TOKENS.includes(x)));
      if (toCurrencyIndex === null) {
        setToCurrency('Select');
      }
    }
  }, [toCurrency, coinsNetworks]);

  React.useEffect(() => {
    if (poolsData.length <= 0) {
      return;
    }
    let temp: string[] = [];
    poolsData.forEach(element => {
      temp.push(...element.assets);
    });
    const list: string[] = temp.filter(
      (value, index, self) => self.indexOf(value) === index
    );
    setPoolsList(list.filter((x: any) => !FROM_TOKENS.includes(x)));
    setFromCurrency('Select');
    setToCurrency('Select');
  }, [poolsData]);

  React.useEffect(() => {
    if (!switchState) {
      if (poolsData.length <= 0 || !Boolean(fromCurrency)) {
        return;
      }
      let temp: string[] = [];
      poolsData
        .filter(element => element.poolName.indexOf(fromCurrency) !== -1)
        .forEach(element => {
          temp.push(...element.assets);
        });
      const list: string[] = temp.filter(
        (value, index, self) =>
          self.indexOf(value) === index && value !== fromCurrency
      );
      if (fromCurrency) {
        setPoolsListSecondary(list.filter((x: any) => !TO_TOKENS.includes(x)));
        setToCurrency('Select');
      }
    }
  }, [fromCurrency, poolsData, switchState]);

  React.useEffect(() => {
    if (!Boolean(fromCurrency)) {
      return;
    }
    async function init() {
      try {
        const { data } = await http.get('/binance/deposit-address', {
          params: {
            coin: fromCurrency.toLocaleLowerCase(),
            network: web3States?.network ? web3States?.network : '',
          },
        });
        setWalletAddress(data);
      } catch (e: any) {
        setErrors([e.response.data.msg]);
        setWalletAddress(null);
      }
    }
    init();
  }, [fromCurrency]);

  React.useEffect(() => {
    if (!Boolean(fromCurrency) || !Boolean(toCurrency)) {
      return;
    }
    async function init() {
      try {
        const fromRate = await axios.get(
          `https://min-api.cryptocompare.com/data/price?fsym=${fromCurrency}&tsyms=USD`
        );
        const toRate = await axios.get(
          `https://min-api.cryptocompare.com/data/price?fsym=${toCurrency}&tsyms=USD`
        );
        const tempEthToUsdRate = await axios.get(
          `https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD`
        );
        const tempBscToUsdRate = await axios.get(
          `https://min-api.cryptocompare.com/data/price?fsym=BNB&tsyms=USD`
        );

        setEthToUsdRate({
          eth: tempEthToUsdRate?.data?.USD,
          bnb: tempBscToUsdRate?.data?.USD,
        });
        setFromUsdRate(fromRate?.data?.USD);
        setToUsdRate(toRate?.data?.USD);
        const toCurrencyRate = await axios.get(
          `https://min-api.cryptocompare.com/data/price?fsym=${fromCurrency}&tsyms=${toCurrency}`
        );

        // const { data } = await http.get('/binance/request-quote', {
        //   params: {
        //     quoteAsset: toCurrency,
        //     baseAsset: fromCurrency,
        //     quoteQty: 1,
        //   },
        // });
        setExchangeRate(toCurrencyRate.data[toCurrency]);

        // setExchangeRate(data?.price);
      } catch (e: any) {
        setErrors([e.response.data.msg]);
        setExchangeRate(0);
      }
    }
    init();
  }, [fromCurrency, toCurrency]);

  React.useEffect(() => {
    if (!Boolean(exchangeRate) || !Boolean(amount)) {
      return;
    }

    setAmountReceive(`${(+amount * exchangeRate).toFixed(8)}`);
  }, [exchangeRate, amount]);

  React.useEffect(() => {
    if (!swapInProgress) {
      setAmount(0);
      setAmountReceive('0');
    }
  }, [swapInProgress]);

  const handleSubmit = async (
    txHash: string,
    receivingAddress: string,
    network: string,
    tag: string
  ) => {
    const payload = {
      fromCurrencyAmount: amount,
      trxHash: txHash,
      toCurrency,
      fromCurrency,
      toCurrencyAddress: receivingAddress,
      tag: tag,
      network: network,
      fromAddress: web3States.addressw,
      usd: fromUsdRate,
    };
    try {
      await http.post('/swap-request', payload);
      setSwapInProgress(false);
      Toastify({
        text: 'Swap has been initiated successfully',
        className: 'info',
        style: {
          background: 'linear-gradient(to right, #00b09b, #96c93d)',
        },
      }).showToast();

      Toastify({
        text: "Don't close the browser till the transaction completes.",
        className: 'info',
        style: {
          background: 'linear-gradient(to right, #00b09b, #96c93d)',
        },
      }).showToast();

      setTrxHashVerification(txHash);
    } catch (e: any) {
      setErrors([e.response.data.errors[0]]);
      setSwapInProgress(false);
      Toastify({
        text: 'Something went wrong while swapping',
        className: 'info',
        style: {
          background: 'linear-gradient(to right, #cc3300 0%, #ff3399 100%)',
        },
      }).showToast();
    }
  };

  const handleSubmitVerify = async (transactionHash: string | null) => {
    if (transactionHash) {
      try {
        setTrxHashVerification(transactionHash);
        const { data } = await http.get('/check-swap-request', {
          params: {
            trxHash: transactionHash,
          },
        });
        setLoadTransaction(true);
        setVerificationData(data);
        setTimeout(() => {
          setLoadTransaction(false);
        }, 3000);
      } catch (e: any) {
        setLoadTransaction(false);
        setVerificationData(null);
        setErrors([e.response.data.errors[0]]);
      }
    } else {
      setTrxHashVerification(null);
      setVerificationData(null);
    }
  };

  React.useEffect(() => {
    let trxInterval = setInterval(() => {
      // console.log('--');
    }, 1000);

    if (trxHashVerification) {
      clearInterval(trxInterval);
      trxInterval = setInterval(() => {
        handleSubmitVerify(trxHashVerification);
      }, 5000);
    }

    return () => clearInterval(trxInterval);
  }, [trxHashVerification]);

  const handleSwitchBtn = () => {
    if (fromCurrency !== 'Select' && toCurrency !== 'Select') {
      setSwitchState(true);
      let tempPoolList = Object.assign([], poolsList);
      let tempPoolListSecondary = Object.assign([], poolsListSecondary);
      let fromCurrencyIndex: string | null =
        poolsList.find(x => x === fromCurrency) || null;
      let toCurrencyIndex: string | null =
        poolsListSecondary.find(x => x === toCurrency) || null;

      if (fromCurrencyIndex === null && toCurrencyIndex === null) {
        return false;
      }

      // setPoolsList(tempPoolListSecondary);
      // setPoolsListSecondary(tempPoolList);
      setFromCurrency(toCurrencyIndex);
      setToCurrency(fromCurrencyIndex);
      setAmount(0);
    }
  };
  const onDisclaimerAccept = () => {
    localStorage.setItem('isFirstTime', 'false');
    setOpen(false);
  };
  React.useEffect(() => {
    if (
      (value === 3 || value === 4 || value === 5) &&
      !isMobile &&
      !isTabletMini
    ) {
      setValue(0);
    }
  }, [value, isMobile, isTabletMini]);
  const handleGetDecimals = (resultArray: any) => {
    if (web3States.addressw && localStorage.getItem('wallet')) {
      if (resultArray && fromCurrency && Object.keys(resultArray).length > 0) {
        const obj = resultArray.find(
          (tk: any) => tk.asset.symbol === fromCurrency
        );
        if (!obj) {
          return;
        }
        let decimals: number =
          web3States.chainId === 56
            ? fromCurrency === 'USDT' || fromCurrency === 'USDC'
              ? 18
              : obj.asset.decimals
            : obj.asset.decimals;
        let price =
          obj && obj.quantity ? parseFloat(obj.quantity) / 10 ** decimals : 0.0;
        if (price) {
          setWeb3State({
            ...web3States,
            balanceWei: price,
            coin: fromCurrency,
          });
        }
      }
    }
  };

  const getOtherChainTokens = async () => {
    const tokens = await axios.get(
      `https://api.covalenthq.com/v1/${web3States.chainId}/address/${web3States.addressw}/balances_v2/?key=ckey_8a13c9ce3c064be6a123e710a76`
    );
    if (tokens && Object.keys(tokens?.data.data?.items).length > 0) {
      const resultArray = tokens.data.data.items.map((x: any) => {
        return {
          asset: {
            asset_code: x.contract_address,
            decimals: x.contract_decimals,
            symbol: x.contract_ticker_symbol.toUpperCase(),
            icon_url: x.logo_url,
            price: {
              relative_change_24h: x.quote - x.quote_24h,
              value: x.quote_rate,
            },
          },
          quantity: x.balance,
        };
      });
      console.log('resultArray = ', resultArray);
      setAccountTokens(resultArray.reverse());
      handleGetDecimals(resultArray.reverse());
    }
  };

  const updateAssets = () => {
    if (polyassets.value && web3States.chainId === 137) {
      // let temp = Object.assign(polyassets.value);
      // let result: tokenList = temp;
      // let resultArray = Object.keys(result).map(function (assetIndex) {
      //   let currentAsset = result[assetIndex];
      //   return currentAsset;
      // });
      // console.log('RESULT = ', resultArray);
      // setAccountTokens(resultArray);
      // handleGetDecimals(resultArray);
      getOtherChainTokens();
    } else if (bscassets.value && web3States.chainId === 56) {
      // let temp = Object.assign(bscassets.value);
      // let result: tokenList = temp;
      // let resultArray = Object.keys(result).map(function (assetIndex) {
      //   let currentAsset = result[assetIndex];
      //   return currentAsset;
      // });
      // setAccountTokens(resultArray);
      // handleGetDecimals(resultArray);
      getOtherChainTokens();
    } else if (ethassets.value && web3States.chainId === 1) {
      let temp = Object.assign(ethassets.value);
      let result: tokenList = temp;
      let resultArray = Object.keys(result).map(function (assetIndex) {
        let currentAsset = result[assetIndex];
        return currentAsset;
      });
      console.log('Result = ', resultArray);
      setAccountTokens(resultArray);
      handleGetDecimals(resultArray);
    } else if (
      web3States.chainId &&
      web3States.addressw &&
      web3States.chainId !== 999999999
    ) {
      getOtherChainTokens();
    } else if (
      web3States.chainId &&
      web3States.addressw &&
      (web3States.chainId === 999999999 || web3States.chainId === 43114) &&
      fromCurrency !== 'TRX'
    ) {
      getSolanaTokenBalance(web3States.addressw, fromCurrency).then(
        (res: any) => {
          let resultArray = [
            {
              asset: {
                asset_code: res.tokenData.address,
                decimals: res.tokenData.decimals,
                symbol: fromCurrency,
              },
              quantity: res.balance,
            },
          ];
          console.log('Result = ', resultArray);
          setAccountTokens(resultArray);
          handleGetDecimals(resultArray);
        }
      );
      // console.log('newBalance = ', newBalance);
    }
  };

  React.useEffect(() => {
    if (swapInProgress && trxHashVerification !== null) {
      const unloadCallback = (event: any) => {
        event.preventDefault();
        return (event.returnValue = '');
      };
      window.addEventListener('beforeunload', unloadCallback);
      return () => {
        window.removeEventListener('beforeunload', unloadCallback);
      };
    }
  }, [swapInProgress, trxHashVerification, isMobile, value, isTabletMini]);

  React.useEffect(() => {
    if (web3States.addressw && !fromCurrency.includes('Select')) {
      updateAssets();
    }
  }, [fromCurrency]);

  const handleCheckFingerPrint = async () => {
    const fp = await FingerprintJS.load();
    // The FingerprintJS agent is ready.
    // Get a visitor identifier when you'd like to.
    const result = await fp.get();
    // This is the visitor identifier:
    const visitorId = result.visitorId;
    if (localStorage && localStorage.getItem('visitorId') === visitorId) {
      console.log(visitorId);
    } else {
      http.post('/fingerprint', {
        fingerprint: visitorId,
      });
      localStorage.setItem('visitorId', visitorId);
    }
  };
  React.useEffect(() => {
    handleCheckFingerPrint();
  }, []);

  React.useEffect(() => {
    // ReactGA.pageview(window.location.pathname + window.location.search);
    ReactGA.pageview('Init page view');
  }, []);
  return (
    <div className='container'>
      <DisclaimerAlert
        setOpen={setOpen}
        open={open}
        handleConfirm={onDisclaimerAccept}
      />
      <HeaderComponent
        setWeb3State={setWeb3State}
        web3States={web3States}
        INITIAL_STATE={INITIAL_STATE}
        value={value}
      />
      <CustomModal
        setModalopen={setModalopen}
        modalopen={modalopen}
        title='Swap Info'
        setValue={setValue}
        value={value}
        swapInProgress={swapInProgress}
      >
        <StackComponent />
      </CustomModal>
      {(value === 0 || value === 2) && !isMobile && !isTabletMini && (
        <>
          <img
            src='/images/elements3x.png'
            alt=''
            style={{ position: 'absolute', width: '13%', top: '100px' }}
          />
          <Container
            style={{
              maxWidth: '100vw',
              paddingLeft: isMobile ? '20px' : '40px',
              paddingRight: isMobile ? '20px' : '40px',
              paddingTop: '10px',
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={4}>
                <CardComponent
                  title={
                    swapStep === 0 ? (
                      'Swap'
                    ) : (
                      <div
                        onClick={() => setSwapStep(0)}
                        style={{
                          display: 'flex',
                          gap: '5px',
                          cursor: 'pointer',
                          width: '40%',
                        }}
                      >
                        <KeyboardBackspaceOutlinedIcon /> <p>Enter Details</p>
                      </div>
                    )
                  }
                  secondElement={false}
                >
                  <SwapComponent
                    amount={amount}
                    setAmount={setAmount}
                    fromCurrency={fromCurrency}
                    setFromCurrency={setFromCurrency}
                    poolsList={arraySortByKName(poolsList)}
                    amountReceive={amountReceive}
                    walletAddress={walletAddress?.address}
                    toCurrency={toCurrency}
                    setToCurrency={setToCurrency}
                    handleSubmit={handleSubmit}
                    poolsListSecondary={arraySortByKName(poolsListSecondary)}
                    toCurrencyNetworks={toCurrencyNetworks}
                    handleSwitchBtn={handleSwitchBtn}
                    getCoinDetails={getCoinDetails}
                    setSwitchState={setSwitchState}
                    fromUsdRate={fromUsdRate}
                    toUsdRate={toUsdRate}
                    web3States={web3States}
                    ethToUsdRate={ethToUsdRate}
                    setSwapInProgress={setSwapInProgress}
                    swapInProgress={swapInProgress}
                    setAmountReceive={setAmountReceive}
                    setWeb3State={setWeb3State}
                    setSwapStep={setSwapStep}
                    swapStep={swapStep}
                    accountTokens={accountTokens}
                    setAccountTokens={setAccountTokens}
                    switchState={switchState}
                    updateAssets={updateAssets}
                  />
                </CardComponent>
                <CardComponent
                  title={'Transaction Status'}
                  secondElement={
                    process.env.NODE_ENV === 'development' &&
                    loadTransaction && <CircularProgress size={20} />
                  }
                >
                  <VerifyTransaction
                    trxHashVerification={trxHashVerification}
                    verificationData={verificationData}
                    handleSubmitVerify={handleSubmitVerify}
                  />
                </CardComponent>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={4}
                style={{ width: isTabletMini ? '100%' : 'inherit' }}
              >
                <CardComponent title={'Charts'} secondElement={false}>
                  <ChartComponent />
                </CardComponent>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={4}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <CardComponent
                    title={'Assets'}
                    secondElement={
                      web3States?.connected &&
                      Object.keys(accountTokens).length > 0 &&
                      FUNCTION_UTILS.getSum(accountTokens) > 0 && (
                        <Tooltip title='Refresh'>
                          <CachedIcon
                            onClick={() => {
                              updateAssets();
                            }}
                            sx={{ width: '0.8em' }}
                          />
                        </Tooltip>
                      )
                    }
                  >
                    {web3States?.addressw &&
                    accountTokens &&
                    Object.keys(accountTokens).length > 0 &&
                    FUNCTION_UTILS.getSum(accountTokens) > 0 ? (
                      <>
                        <Box
                          sx={{
                            overflow: 'auto',
                            height: isMac ? '24vh' : HandleResponsiveness(),
                            marginBottom: '10px',
                            '&::-webkit-scrollbar': {
                              width: '12px !important',
                            },
                            '&::-webkit-scrollbar-thumb': {
                              background: '#626273',
                              borderRadius: '2px !important',
                            },
                          }}
                        >
                          <Assets
                            address={web3States?.addressw}
                            chainId={web3States?.chainId}
                            accountTokens={accountTokens}
                          />
                        </Box>
                        {/* {web3States.chainId === 56 ||
                        web3States.chainId === 97 ? (
                          <Box
                            sx={{
                              overflow: 'auto',
                              height: HandleResponsiveness(),
                              marginBottom: '10px',
                              '&::-webkit-scrollbar': {
                                width: '12px !important',
                              },
                              '&::-webkit-scrollbar-thumb': {
                                background: '#626273',
                                borderRadius: '2px !important',
                              },
                            }}
                          >
                            <BNBAssets
                              chainId={web3States.chainId}
                              address={web3States.addressw}
                            />
                          </Box>
                        ) : (
                          <Box
                            sx={{
                              overflow: 'auto',
                              height: HandleResponsiveness(),
                              marginBottom: '10px',
                              '&::-webkit-scrollbar': {
                                width: '12px !important',
                              },
                              '&::-webkit-scrollbar-thumb': {
                                background: '#626273',
                                borderRadius: '2px !important',
                              },
                            }}
                          >
                            <Assets
                              address={web3States.addressw}
                              chainId={web3States?.chainId}
                              accountTokens={accountTokens}
                            />
                          </Box>
                        )} */}
                      </>
                    ) : (
                      <>
                        <Box sx={{ display: 'flex', gap: '10px' }}>
                          <Box
                            component='div'
                            sx={{
                              width: '100%',
                              borderRadius: '10px',
                              overflow: 'hidden',
                              height: isMac ? '24vh' : HandleResponsiveness(),
                              '&::-webkit-scrollbar': {
                                width: '12px !important',
                              },
                              '&::-webkit-scrollbar-thumb': {
                                background: '#626273',
                                borderRadius: '2px !important',
                              },
                              marginBottom: '10px',
                            }}
                          >
                            <Box
                              sx={{
                                width: '100%',
                                marginTop: '10px',
                                height: HandleResponsiveness(),
                                overflow: 'hidden',
                                '&::-webkit-scrollbar': {
                                  width: '12px !important',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                  background: '#626273',
                                  borderRadius: '2px !important',
                                },
                              }}
                            >
                              <Box
                                sx={{
                                  width: '60%',
                                  margin: 'auto',
                                  textAlign: 'center',
                                }}
                              >
                                <img alt='' src='images/assets.svg' />
                                <Typography
                                  sx={{ fontSize: '12px', color: '#707585' }}
                                >
                                  Nothing Found
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: '10px',
                                    color: '#707585',
                                    paddingTop: '15px',
                                    paddingBottom: '15px',
                                  }}
                                >
                                  We weren’t able to locate any assets linked to
                                  your wallet
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </>
                    )}
                  </CardComponent>
                </Grid>
                <img
                  src='images/elements.svg'
                  alt=''
                  style={{ position: 'absolute', width: '10%', right: '0px' }}
                />
                <CardComponent title={'Crypto News'} secondElement={false}>
                  <NewsFeedComponent />
                </CardComponent>
              </Grid>
              {value === 2 && (
                <CustomModal
                  swapInProgress={swapInProgress}
                  setModalopen={setModalopen}
                  modalopen={modalopen}
                  title='Swap Info'
                  setValue={setValue}
                  value={value}
                >
                  <StackComponent />
                </CustomModal>
              )}
            </Grid>
          </Container>
        </>
      )}
      {value === 1 && <VentiData />}

      {(isMobile || isTabletMini) && value === 0 && (
        <>
          <Grid
            container
            justifyContent='center'
            alignItems='center'
            sx={{
              mb:
                isTabletMini && swapInProgress === false
                  ? '25vh'
                  : isTabletMini &&
                    (swapInProgress || trxHashVerification !== null)
                  ? 'inherit'
                  : '12vh',
              mt: isTabletMini && swapInProgress === false ? '25vh' : 'inherit',
            }}
          >
            <Grid item xs={12} sm={5} md={5} lg={5}>
              <CardComponent
                title={
                  swapStep === 0 ? (
                    'Swap'
                  ) : (
                    <div
                      onClick={() => setSwapStep(0)}
                      style={{
                        display: 'flex',
                        gap: '5px',
                        cursor: 'pointer',
                        width: '40%',
                      }}
                    >
                      <KeyboardBackspaceOutlinedIcon /> <p>Enter Details</p>
                    </div>
                  )
                }
                secondElement={
                  <img
                    src='/images/info.jpg'
                    alt='info'
                    style={{
                      width: '6%',
                      height: '6%',
                      borderRadius: '10px',
                    }}
                    onClick={() => setModalopen(true)}
                  />
                }
              >
                <SwapComponent
                  amount={amount}
                  setAmount={setAmount}
                  fromCurrency={fromCurrency}
                  setFromCurrency={setFromCurrency}
                  poolsList={arraySortByKName(poolsList)}
                  amountReceive={amountReceive}
                  walletAddress={walletAddress?.address}
                  toCurrency={toCurrency}
                  setToCurrency={setToCurrency}
                  handleSubmit={handleSubmit}
                  poolsListSecondary={arraySortByKName(poolsListSecondary)}
                  toCurrencyNetworks={toCurrencyNetworks}
                  handleSwitchBtn={handleSwitchBtn}
                  getCoinDetails={getCoinDetails}
                  setSwitchState={setSwitchState}
                  fromUsdRate={fromUsdRate}
                  toUsdRate={toUsdRate}
                  web3States={web3States}
                  ethToUsdRate={ethToUsdRate}
                  setSwapInProgress={setSwapInProgress}
                  swapInProgress={swapInProgress}
                  setAmountReceive={setAmountReceive}
                  setWeb3State={setWeb3State}
                  setSwapStep={setSwapStep}
                  swapStep={swapStep}
                  accountTokens={accountTokens}
                  setAccountTokens={setAccountTokens}
                  switchState={switchState}
                  updateAssets={updateAssets}
                />
              </CardComponent>
            </Grid>
            {swapInProgress === false && (
              <CustomModal
                setModalopen={setModalopen}
                modalopen={modalopen}
                title='Swap Info'
                setValue={setValue}
                value={value}
                swapInProgress={swapInProgress}
              >
                <StackComponent />
              </CustomModal>
            )}
          </Grid>
          {(swapInProgress || trxHashVerification !== null) && (
            <Grid container justifyContent='center' alignItems='center'>
              <Grid item xs={12} sm={5} md={5} lg={5}>
                <CardComponent
                  title={'Transaction Status'}
                  secondElement={false}
                >
                  <VerifyTransaction
                    trxHashVerification={trxHashVerification}
                    verificationData={verificationData}
                    handleSubmitVerify={handleSubmitVerify}
                  />
                </CardComponent>
              </Grid>
            </Grid>
          )}
        </>
      )}
      {(isMobile || isTabletMini) && value === 3 && (
        <Grid container justifyContent='center' alignItems='center'>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <CardComponent title={'Charts'} secondElement={false}>
              <ChartComponent />
            </CardComponent>
          </Grid>
        </Grid>
      )}
      {(isMobile || isTabletMini) && value === 4 && (
        <Grid container justifyContent='center' alignItems='center'>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <CardComponent title={'Crypto News'} secondElement={false}>
              <NewsFeedComponent />
            </CardComponent>
          </Grid>
        </Grid>
      )}
      {(isMobile || isTabletMini) && value === 5 && (
        <Grid container justifyContent='center' alignItems='center'>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <CardComponent
              title={'Assets'}
              secondElement={
                web3States?.addressw &&
                accountTokens &&
                Object.keys(accountTokens).length > 0 &&
                FUNCTION_UTILS.getSum(accountTokens) > 0 && (
                  <Tooltip title='Refresh'>
                    <CachedIcon
                      onClick={() => {
                        updateAssets();
                      }}
                      sx={{ width: '0.8em' }}
                    />
                  </Tooltip>
                )
              }
            >
              {web3States?.addressw &&
              accountTokens &&
              Object.keys(accountTokens).length > 0 &&
              FUNCTION_UTILS.getSum(accountTokens) > 0 ? (
                <>
                  <Box
                    sx={{
                      overflow: 'auto',
                      height: HandleResponsiveness(),
                      marginBottom: '10px',
                      '&::-webkit-scrollbar': {
                        width: '12px !important',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        background: '#626273',
                        borderRadius: '2px !important',
                      },
                    }}
                  >
                    <Assets
                      address={web3States.addressw}
                      chainId={web3States?.chainId}
                      accountTokens={accountTokens}
                    />
                  </Box>
                  {/* {web3States.chainId === 56 || web3States.chainId === 97 ? (
                <Box
                  sx={{
                    overflow: 'auto',
                    height: HandleResponsiveness(),
                    marginBottom: '10px',
                    '&::-webkit-scrollbar': {
                      width: '12px !important',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: '#626273',
                      borderRadius: '2px !important',
                    },
                  }}
                >
                  <BNBAssets
                    chainId={web3States.chainId}
                    address={web3States.addressw}
                  />
                </Box>
              ) : (
                <Box
                  sx={{
                    overflow: 'auto',
                    height: HandleResponsiveness(),
                    marginBottom: '10px',
                    '&::-webkit-scrollbar': {
                      width: '12px !important',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: '#626273',
                      borderRadius: '2px !important',
                    },
                  }}
                >
                  <Assets
                    address={web3States.addressw}
                    chainId={web3States?.chainId}
                    accountTokens={accountTokens}
                  />
                </Box>
              )} */}
                </>
              ) : (
                <>
                  <Box sx={{ display: 'flex', gap: '10px' }}>
                    <Box
                      component='div'
                      sx={{
                        width: '100%',
                        borderRadius: '10px',
                        overflow: 'hidden',
                        height: HandleResponsiveness(),
                        '&::-webkit-scrollbar': {
                          width: '12px !important',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          background: '#626273',
                          borderRadius: '2px !important',
                        },
                        marginTop: '10px',
                        marginBottom: '10px',
                      }}
                    >
                      <Box
                        sx={{
                          width: '100%',
                          marginTop: '110px',
                          height: HandleResponsiveness(),
                          overflow: 'hidden',
                          '&::-webkit-scrollbar': {
                            width: '12px !important',
                          },
                          '&::-webkit-scrollbar-thumb': {
                            background: '#626273',
                            borderRadius: '2px !important',
                          },
                        }}
                      >
                        <Box
                          sx={{
                            width: '60%',
                            margin: 'auto',
                            textAlign: 'center',
                          }}
                        >
                          <img alt='' src='images/assets.svg' />
                          <Typography
                            sx={{ fontSize: '12px', color: '#707585' }}
                          >
                            Nothing Found
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: '10px',
                              color: '#707585',
                              paddingTop: '15px',
                              paddingBottom: '15px',
                            }}
                          >
                            We weren’t able to locate any assets linked to your
                            wallet
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </>
              )}
            </CardComponent>
          </Grid>
        </Grid>
      )}
      <CoustomTabs value={value} setValue={setValue} />
    </div>
  );
}
export default App;
