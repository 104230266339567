import {
  //Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
//import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import React from 'react';
//import MouseOverPopover from '../popover';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';

export function StackComponent() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between(320, 480));
  return (
    <div
      style={{
        marginTop: '20px',
        marginBottom: '10px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: isMobile ? '13rem' : '16rem',
          whiteSpace: 'nowrap',
        }}
      >
        <Typography sx={{ fontSize: '12px', color: '#FFFFFF', opacity: '0.5' }}>
          VentiSwap Fee
        </Typography>
        <Typography sx={{ fontSize: '12px' }}>2%</Typography>
      </Box>
      <br />
      <Box sx={{ display: 'flex' }}>
        <Typography
          sx={{
            fontSize: '12px',
            color: '#FFFFFF',
            opacity: '0.5',
            width: '50%',
          }}
        >
          Transaction Time (Dependent on network used)
        </Typography>
        <Typography
          sx={{
            fontSize: '12px',
            whiteSpace: 'nowrap',
            position: 'relative',
            left: isMobile ? '5rem' : '6.6rem',
          }}
        >
          5-15 minutes
        </Typography>
      </Box>
      <br />
      <Box sx={{ display: 'flex', width: '100%' }}>
        <Typography
          sx={{
            fontSize: '12px',
            color: '#FFFFFF',
            opacity: '0.5',
            whiteSpace: 'nowrap',
            width: '55%',
          }}
        >
          Wallet Connection
        </Typography>
        <Typography
          sx={{
            fontSize: '12px',
            // position: 'relative',
            // left: isMobile ? '4.6rem' : '7.6rem',
            width: '45%',
            // wordBreak: 'break-word',
          }}
        >
          For Coinbase wallet and Trust wallet, for full functionality best used
          on Mobile
        </Typography>
      </Box>
      <br />
      <Box sx={{ display: 'flex', width: '100%' }}>
        <Typography
          sx={{
            fontSize: '12px',
            color: '#FFFFFF',
            opacity: '0.5',
            width: '55%',
          }}
        >
          Refund Function
        </Typography>
        <Typography
          sx={{
            fontSize: '12px',
            // position: 'relative',
            // left: isMobile ? '9rem' : '12.5rem',
            width: '45%',
          }}
        >
          Refunds take place 24 hours after a failed transaction
        </Typography>
      </Box>
    </div>
  );
}
