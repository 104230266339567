/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-distracting-elements */
import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useState, useEffect } from 'react';
import Web3 from 'web3';
import Web3Modal from 'web3modal';
import WalletLink from 'walletlink';
import { getChainData } from '../../helpers/utilities';
import Web3Dialog from '../../Web3Dialog';
import { useTheme } from '@mui/material/styles';
import { BUYABLE_CHAINS_MAP } from '../../network';
import Toastify from 'toastify-js';
import { ethers } from 'ethers';
import FUNCTION_UTILS from '../generic-functions';
import http from '../../http';

function initWeb3(provider) {
  const web3 = new Web3(provider);
  web3.eth.extend({
    methods: [
      {
        name: 'chainId',
        call: 'eth_chainId',
        outputFormatter: web3.utils.hexToNumber,
      },
    ],
  });
  return web3;
}

export function HeaderComponent({
  web3States,
  setWeb3State,
  INITIAL_STATE,
  value,
}) {
  const [open, setOpen] = useState(false);
  const [trendingPairs, setTrendingPairs] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between(320, 480));
  const [balanceChange, setBalanceChange] = useState(true);

  const API_KEY =
    '8cf7209cb162104bc80fe1e247e709b13a3fa5051c3b948bb37e9f6bd4e66ab3';

  const toppairs = async () => {
    const response = await fetch(
      // 'https://min-api.cryptocompare.com/data/top/mktcapfull?limit=10&page=0&tsym=USD&api_key=' + API_KEY
      'https://api.coingecko.com/api/v3/search/trending'
    );
    const json = await response.json();
    // const colinlist = json.Data;
    const colinlist = json.coins;
    // // console.log(json)
    return {
      colinlist,
    };
  };
  const resetApp = async () => {
    if (
      localStorage &&
      localStorage.getItem('fromAddress') &&
      localStorage.getItem('wallet')
    ) {
      localStorage.removeItem('wallet');
      localStorage.removeItem('fromAddress');
    }
    const { web3 } = web3States;
    if (web3 && web3.currentProvider && web3.currentProvider.close) {
      await web3.currentProvider.close();
    }
    await web3Modal.clearCachedProvider();
    // console.log('1');
    setWeb3State(INITIAL_STATE);
  };

  const getNetwork = () => {
    let newChain = getChainData(web3States.chainId || 1).network;
    if (newChain === 'notFound') {
      Toastify({
        text: 'Currency is not available',
        className: 'info',
        style: {
          background: 'linear-gradient(to right, #cc3300 0%, #ff3399 100%)',
        },
      }).showToast();

      resetApp();
      return false;
    } else {
      return newChain;
    }
  };

  const getProviderOptions = () => {
    const infuraId = process.env.REACT_APP_INFURA_ID;
    const providerOptions = {
      'custom-example': {
        display: {
          logo: 'data:image/gif;base64,INSERT_BASE64_STRING',
          name: 'Example Provider',
          description: 'Connect to your example provider account',
        },
        package: WalletLink,
        options: {
          apiKey: 'EXAMPLE_PROVIDER_API_KEY',
        },
      },
      walletlink: {
        package: WalletLink,
        options: {
          appName: 'Web3Modal Example App',
          infuraId,
        },
      },
    };
    return providerOptions;
  };

  const web3Modal = new Web3Modal({
    network: getNetwork(),
    cacheProvider: true,
    providerOptions: getProviderOptions(),
    theme: {
      background: 'rgb(31, 41, 74)',
      main: 'rgb(199, 199, 199)',
      secondary: 'rgb(136, 136, 136)',
      border: 'rgba(195, 195, 195, 0.14)',
      hover: 'rgb(16, 26, 32)',
    },
  });

  useEffect(() => {
    if (web3Modal && web3Modal.cachedProvider) {
      // onConnect();
    }
    return;
  }, []);

  const onConnect = async () => {
    const provider = await web3Modal.connect();
    await provider.enable();
    onConnectSub(provider);
  };
  async function onConnectSub(provider, walletConnect, walletAddress) {
    await subscribeProvider(provider);
    const web3 = initWeb3(provider);
    // const addressw = '0xf293589d18B1b6a3A946D41501CB0235F309F7F9';

    const addressw = walletConnect
      ? web3.currentProvider.accounts[0]
      : walletAddress
      ? walletAddress
      : web3.currentProvider.selectedAddress;
    const networkId = await web3.eth.net.getId();
    const chainId = await web3.eth.chainId();
    const balance = await web3.eth.getBalance(addressw);
    const balanceWei = await web3.utils.fromWei(balance, 'ether');

    if (walletConnect && chainId === 56) {
      Toastify({
        text: 'Chain not supported',
        className: 'info',
        style: {
          background: 'linear-gradient(to right, #cc3300 0%, #ff3399 100%)',
        },
      }).showToast();
      resetApp();
    }

    setWeb3State({
      ...web3States,
      web3,
      provider,
      connected: true,
      addressw,
      chainId,
      networkId,
      balanceWei,
      coin: BUYABLE_CHAINS_MAP[`${chainId}`]?.nativeCurrency,
      network: BUYABLE_CHAINS_MAP[`${chainId}`]?.network,
    });
    setBalanceChange(!balanceChange);
  }

  const subscribeProvider = async provider => {
    if (!provider.on) {
      return;
    }
    provider.on('close', () => {
      resetApp();
    });
    provider.on('accountsChanged', async accounts => {
      // await onConnect();
      const web3 = initWeb3(provider);
      // const addressw = '0xf293589d18B1b6a3A946D41501CB0235F309F7F9';
      const addressw = web3.currentProvider.selectedAddress;

      const networkId = await web3.eth.net.getId();
      const chainId = await web3.eth.chainId();
      const balance = await web3.eth.getBalance(addressw);
      const balanceWei = await web3.utils.fromWei(balance, 'ether');
      setWeb3State({
        ...web3States,
        addressw: accounts[0],
        balanceWei,
        networkId,
        chainId,
        coin: BUYABLE_CHAINS_MAP[`${chainId}`]?.nativeCurrency,
        network: BUYABLE_CHAINS_MAP[`${chainId}`]?.network,
      });
    });
    provider.on('chainChanged', async chainId => {
      // await onConnect();

      const web3 = initWeb3(provider);
      const addressw = web3.currentProvider.selectedAddress;
      const networkId = await web3.eth.net.getId();
      const chainId2 = await web3.eth.chainId();
      const balance = await web3.eth.getBalance(addressw);
      const balanceWei = await web3.utils.fromWei(balance, 'ether');
      setWeb3State({
        ...web3States,
        chainId: chainId2,
        chaIndBinary: chainId2,
        networkId,
        balanceWei,
        addressw,
        coin: BUYABLE_CHAINS_MAP[`${chainId2}`]?.nativeCurrency,
        network: BUYABLE_CHAINS_MAP[`${chainId2}`]?.network,
      });
      setBalanceChange(!balanceChange);
    });
    provider.on('networkChanged', async networkId => {
      // For trust wallet

      const web2 = new ethers.providers.Web3Provider(provider);
      // await web2.send('eth_requestAccounts', []);
      const signer = web2.getSigner();
      const address = await signer.getAddress();
      // await onConnect();
      const web3 = initWeb3(provider);
      const addressw = web3.currentProvider.selectedAddress || address;
      const networkId2 = await web3.eth.net.getId();
      const chainId = await web3.eth.chainId();
      const balance = await web3.eth.getBalance(addressw);
      const balanceWei = await web3.utils.fromWei(balance, 'ether');
      const newNetwork = isNaN(parseInt(networkId))
        ? networkId2
        : parseInt(networkId);

      setWeb3State({
        ...web3States,
        addressw,
        chainId: newNetwork,
        networkId: newNetwork,
        balanceWei,
        coin: BUYABLE_CHAINS_MAP[`${newNetwork}`]?.nativeCurrency,
        network: BUYABLE_CHAINS_MAP[`${newNetwork}`]?.network,
      });
      setBalanceChange(!balanceChange);
    });
  };

  async function chartLoad() {
    try {
      const data = await http.get('/ventidata/trending');
      if (data.status === 200) {
        setTrendingPairs(
          data.data.trendsFrom.sort((a, b) => (a.count > b.count ? -1 : 1))
        );
      }
    } catch (error) {
      console.log('====================================');
      console.log(error);
      console.log('====================================');
    }
  }

  useEffect(() => {
    chartLoad();
  }, []);

  return (
    <>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          gap: 2,
          flexDirection: { xs: 'column', sm: 'row' },
          paddingLeft: isMobile ? '20px' : '45px',
          paddingRight: isMobile ? '5px' : '25px',
          paddingTop: '10px',
          paddingBottom: isMobile ? '' : '10px',
          borderBottom: isMobile ? 'none' : '1px solid #39394B',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            alignItems: 'center',
            justifyContent: 'space-between',
            width: { xs: '100%', sm: 'auto' },
          }}
        >
          <div
            style={{
              display: 'flex',
              gap: 10,
              alignItems: 'center',
            }}
          >
            <Box
              component='img'
              src='./images/logo.png'
              alt=''
              sx={{ width: isMobile ? '40%' : '14%' }}
            />
            <Box
              component='img'
              src={isMobile ? '' : './images/VentiSwap.png'}
              alt=''
              sx={{ maxWidth: { xs: '200px', sm: '90px' } }}
            />
          </div>
          <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
            <Web3Dialog
              open={open}
              setOpen={setOpen}
              onConnectSub={onConnectSub}
              resetApp={resetApp}
              setWeb3State={setWeb3State}
              web3States={web3States}
              balanceChange={balanceChange}
              INITIAL_STATE={INITIAL_STATE}
            />
          </Box>
        </Box>
        <Box sx={{ flex: 1 }}></Box>
        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
          <Web3Dialog
            open={open}
            setOpen={setOpen}
            onConnectSub={onConnectSub}
            resetApp={resetApp}
            setWeb3State={setWeb3State}
            web3States={web3States}
            balanceChange={balanceChange}
            INITIAL_STATE={INITIAL_STATE}
          />
        </Box>
      </Box>
      {value === 1 && (
        <Box
          id='dn'
          sx={{
            alignItems: 'center',
            display: 'flex',
            gap: isMobile ? '0px' : '30px',
            border: '0.5px solid #FFFBFB40',
            borderRadius: '8px',
            background: '#303043',
            height: '2.6rem',
            margin: isMobile ? '10px 20px 0px 20px' : '10px 45px 0px 45px',
          }}
        >
          <Typography
            style={{
              lineHeight: '21.78px',
              paddingLeft: '15px',
              paddingRight: '15px',
              fontWeight: '600',
              whiteSpace: 'nowrap',
              fontSize: '12px',
            }}
          >
            Trending on Ventiswap
          </Typography>
          <Box
            sx={{
              width: '100%',
            }}
          >
            <marquee>
              <Box
                sx={{
                  display: 'flex',
                  // maxWidth: isMobile && '100vw',
                  // overflow: isMobile && 'hidden',
                }}
              >
                {trendingPairs &&
                  Object.keys(trendingPairs).length > 0 &&
                  trendingPairs?.map(
                    (cn, index) =>
                      index < 10 && (
                        <Box
                          component='div'
                          sx={{
                            display: 'flex',
                            mr: '22px',
                            mt: '5px',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          <a id='tendingPired'>#{`${index + 1}`}.</a>&nbsp;
                          <img
                            src={`./images/crypto-icons/${cn.fromCurrency?.toLocaleLowerCase()}.svg`}
                            alt={cn.fromCurrency?.toLocaleLowerCase()}
                            style={{ height: '12px' }}
                          />
                          &nbsp;
                          <a id='tendingPired'>{`${cn.fromCurrency}`} </a>
                        </Box>
                      )
                  )}
                &nbsp;
              </Box>
            </marquee>
          </Box>
        </Box>
      )}
    </>
  );
}
