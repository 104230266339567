import React from 'react';
import Toastify from 'toastify-js';
import 'toastify-js/src/toastify.css';
import { BUYABLE_CHAINS_MAP } from '../../network';
import { TOKEN_ADDRESS_ALL } from '../../tokens';
import {
  UserBscassets,
  UserEthassets,
} from '../assets-components/hooks/UserBscassets';

const FUNCTION_UTILS = {
  showNotification: text => {
    Toastify({
      text: text,
      className: 'info',
      style: {
        background: 'linear-gradient(to right, #cc3300 0%, #ff3399 100%)',
      },
    }).showToast();
    return;
  },
  getBalance: async (setWeb3State, web3States, Web3Api, fromCurrency) => {
    const { chainId } = web3States;
    const tempNetwork = BUYABLE_CHAINS_MAP[chainId]?.network;
    const options = {
      chain: tempNetwork,
      address: web3States.addressw,
    };
    let balances = await Web3Api.account.getTokenBalances(options);
    let tempBalance =
      balances.find(br => br.symbol === fromCurrency)?.balance / 10 ** 18;
    setWeb3State({
      ...web3States,
      coin: fromCurrency,
      balanceWei: tempBalance,
      network: BUYABLE_CHAINS_MAP[`${chainId}`]?.network,
    });
  },

  getAssets: (setState, chainId, address) => {
    if (address) {
      if (chainId === 56 || chainId === 97) {
        const bscassets = UserBscassets(address);

        let assets = bscassets.value[Object.keys(bscassets.value)[0]];
        // console.log('assets  = ', assets);
        setState(bscassets.value);
      } else if (chainId === 1) {
        const ethassets = UserEthassets(address);
        setState(ethassets.value);
      }
    }
  },
  getCurrentAmount: (chainId, amount, tokenName, wallet) => {
    if (wallet === 'tronlink') {
      const decimals = TOKEN_ADDRESS_ALL[tokenName][999999999].decimals;
      console.log('decimals = ', decimals);
      if (decimals === 4) {
        return amount * 1e-2;
      } else if (decimals === 6) {
        return amount;
      } else if (decimals === 8) {
        return amount * 1e2;
      } else if (decimals === 12) {
        return amount * 1e6;
      } else {
        return amount * 1e12;
      }
    }
    if (chainId === 137) {
      return (amount / 10 ** 12).toFixed(12);
    }
    if (chainId === 1 && (tokenName === 'USDT' || tokenName === 'USDC')) {
      return (amount / 10 ** 12).toFixed(12);
    }
  },
  isCurrentSymbol: chainId => {
    return BUYABLE_CHAINS_MAP[chainId].nativeCurrency;
  },
  getMobileOperatingSystem: () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone';
    }

    if (/android/i.test(userAgent)) {
      return 'Android';
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'iOS';
    }

    return 'unknown';
  },
  getSum: arr => {
    const sum = arr.reduce((accumulator, object) => {
      return accumulator + object.quantity;
    }, 0);
    return sum;
  },
};

export default FUNCTION_UTILS;
