import * as React from 'react';
import Box from '@mui/material/Box';
//import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Divider, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { usePrevious } from './usePrevious';

export function CustomModal({
  modalopen,
  setModalopen,
  title,
  children,
  setValue,
  value,
  swapInProgress,
}) {
  let temp = usePrevious(value);
  const handleChnage = () => {
    setModalopen(false);
    if (temp === 2) {
      setValue(1);
    } else {
      setValue(0);
    }
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between(320, 480));
  const style = {
    position: 'absolute',
    background: '#26263A',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? 350 : 400,
    boxShadow: 24,
    p: 4,
    padding: '22px',
    borderRadius: '15px',
  };
  return (
    <div>
      {(modalopen || value === 2) && (
        <Modal
          open={value === 2 || modalopen}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box sx={style}>
            <img
              src='images/dismiss_24.svg'
              style={{
                position: 'fixed',
                right: '20px',
                cursor: 'pointer',
                height: '20px',
              }}
              onClick={() => handleChnage()}
              alt=''
            />
            <Typography
              sx={{
                fontWeight: '600',
                fontSize: '14px',
              }}
            >
              {title}
            </Typography>
            <Divider
              sx={{ position: 'relative', top: '10px', bottom: '10px' }}
            />
            <div>{children}</div>
          </Box>
        </Modal>
      )}
    </div>
  );
}
