import React from 'react';
import {
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
} from 'recharts';

export function Graph({ overview, isDesktop }) {
  return (
    <ResponsiveContainer height={isDesktop ? 250 : 160}>
      <AreaChart
        data={overview}
        margin={{ top: 0, right: 30, left: 0, bottom: 10 }}
      >
        <defs>
          <linearGradient id='colorUv' x1='0' y1='0' x2='0' y2='1'>
            <stop offset='5%' stopColor='#144FF133' stopOpacity={0.8} />
            <stop offset='95%' stopColor='#144FF133' stopOpacity={0} />
          </linearGradient>
          <linearGradient id='colorPv' x1='0' y1='0' x2='0' y2='1'>
            <stop offset='5%' stopColor='#144FF133' stopOpacity={0.8} />
            <stop offset='95%' stopColor='#144FF133' stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis dataKey='name' fontSize={10} padding={{ left: 15 }} />
        <YAxis fontSize={10} />
        {/* <CartesianGrid strokeDasharray='3 3' /> */}
        <Tooltip
          contentStyle={{
            backgroundColor: 'rgba(34,34,36,130)',
            height: '100%',
            border: 'revert',
            fontSize: '12px',
            opacity: '0.8',
          }}
          itemStyle={{ color: 'white', fontSize: '12px' }}
        />
        <Area
          type='monotone'
          dataKey='Swaps'
          stroke='#E93375'
          fillOpacity={1}
          fill='url(#colorUv)'
        />
        {/* <Area
        type='monotone'
        dataKey='pv'
        stroke='#E93375'
        fillOpacity={1}
        fill='url(#colorPv)'
      /> */}
      </AreaChart>
    </ResponsiveContainer>
  );
}
