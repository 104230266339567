import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { WalletLinkConnector } from '@web3-react/walletlink-connector';

export const injected = new InjectedConnector({
  supportedChainIds: [
    1, 3, 4, 5, 42, 137, 56, 1399811149, 30, 61, 77, 99, 100, 42220, 44787, 250,
    43114, 43113,
  ],
});

export const walletconnect = new WalletConnectConnector({
  rpcUrl: `https://mainnet.infura.io/v3/3a50db1a56194279814ff1ef6591a94b`,
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
});

export const walletlink = new WalletLinkConnector({
  url: `https://mainnet.infura.io/v3/3a50db1a56194279814ff1ef6591a94b`,
  appName: 'Your app name',
});

export const activateInjectedProvider = (providerName = 'MetaMask') => {
  const { ethereum } = window;

  if (!ethereum?.providers) {
    return undefined;
  }

  let provider;
  if (providerName === 'CoinBase') {
    provider = ethereum.providers.find(
      ({ isCoinbaseWallet }) => isCoinbaseWallet
    );
  } else if (providerName === 'MetaMask') {
    provider = ethereum.providers.find(({ isMetaMask }) => isMetaMask);
  }

  if (provider) {
    ethereum.setSelectedProvider(provider);
  }
};
