import { Box, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import {
  CardComponent,
  Menu,
  Graph,
  Trades,
  TotalVolume,
  CountryByProgress,
  GeoMap,
} from '../index';
import http from '../../http';
import { countryNames } from '../../utils/countrylist';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import moment from 'moment';
import { DATE_FORMAT, DAY_FORMAT } from '../../utils/format';

export function VentiData() {
  const [axis, setAxis] = React.useState('month');
  const [countryvalue, setCountryvalue] = React.useState('month');
  const [content, setContent] = React.useState('');
  const [progress, setProgress] = React.useState([]);
  const [overview, setOverview] = React.useState([]);
  const [salesvolume, setSalesvolume] = React.useState('');
  const [tardes, setTrades] = React.useState([]);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.between(1501, 2450));
  const isMiniDesktop = useMediaQuery(theme.breakpoints.between(1280, 1364));
  const isMac = useMediaQuery(theme.breakpoints.between(1440, 1500));

  const getGraphData = async () => {
    try {
      const data = await http.get(`/ventidata/overview?filter=${axis}`);
      if (data.status === 200) {
        let temp =
          data &&
          data.data.swaps?.map(item => {
            return {
              Swaps: item.count,
              name:
                axis === 'day'
                  ? moment(
                      item.month.toString() +
                        '/' +
                        item.day.toString() +
                        '/' +
                        item.year.toString()
                    ).format(DAY_FORMAT)
                  : axis === 'month'
                  ? item.m_name
                  : axis === 'year'
                  ? item.year
                  : item.m_name,
            };
          });
        setOverview(temp);
      }
    } catch (error) {
      console.log('====================================');
      console.log(error);
      console.log('====================================');
    }
  };
  const getLiveTrades = async () => {
    try {
      const data = await http.get('/ventidata/live-trades');
      if (data.status === 200) {
        setTrades(data.data.trades);
      } else {
        setTrades([]);
      }
    } catch (error) {
      console.log('====================================');
      console.log(error);
      console.log('====================================');
    }
  };
  const getSalesVolume = async () => {
    try {
      const data = await http.get('/ventidata/sales-volume');
      if (data.status === 200) {
        setSalesvolume(data.data.total);
      }
    } catch (error) {
      console.log('====================================');
      console.log(error);
      console.log('====================================');
    }
  };
  const getAnalytics = async () => {
    try {
      let startDate = moment(['2018', '01']);
      let start =
        countryvalue === 'all'
          ? moment(startDate).startOf('year').format(DATE_FORMAT)
          : moment().startOf(countryvalue).format(DATE_FORMAT);
      let end =
        countryvalue === 'all'
          ? moment().endOf('year').format(DATE_FORMAT)
          : moment().endOf(countryvalue).format(DATE_FORMAT);
      const data = await http.get(
        `/ventidata/analytics?dateTo=${end}&dateFrom=${start}`
      );
      if (data.status === 200) {
        setProgress(data.data.users);
      }
    } catch (error) {
      console.log('====================================');
      console.log(error);
      console.log('====================================');
    }
  };
  React.useEffect(() => {
    getLiveTrades();
    getSalesVolume();
  }, []);
  React.useEffect(() => {
    getGraphData();
  }, [axis]);
  React.useEffect(() => {
    getAnalytics();
  }, [countryvalue]);

  const item = ['day', 'month', 'year'];
  const item2 = ['day', 'month', 'year', 'all'];
  // console.log(window.innerWidth);
  // console.log('height', window.innerHeight);
  return (
    <Container
      style={{
        paddingLeft: '45px',
        maxWidth: '100vw',
        paddingRight: '45px',
      }}
    >
      <img
        src='/images/elements3x.png'
        alt=''
        style={{
          position: 'absolute',
          width: '13%',
          top: '100px',
          left: '0px',
        }}
      />
      <Grid container spacing={2}>
        <Grid xs={12} sm={12} md={8} item>
          <CardComponent
            title='Overview'
            secondElement={<Menu item={item} axis={axis} setAxis={setAxis} />}
          >
            <Graph overview={overview} isDesktop={isDesktop} />
          </CardComponent>
          <CardComponent
            title='Analytics'
            secondElement={
              <Menu
                item={item2}
                axis={countryvalue}
                setAxis={setCountryvalue}
              />
            }
          >
            <Grid
              container
              sx={{
                height: isDesktop
                  ? '33vh'
                  : isMiniDesktop
                  ? '25.7vh'
                  : isMac
                  ? '36.2vh'
                  : '24.5vh',
              }}
            >
              <Grid xs={12} sm={12} md={6} item>
                <GeoMap
                  setTooltipContent={setContent}
                  data='https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json'
                  isDesktop={isDesktop}
                  progress={progress}
                />
                <ReactTooltip
                  place='top'
                  offset={{ top: 330, left: 100, bottom: 20 }}
                >
                  {content}
                </ReactTooltip>
              </Grid>
              <Grid
                xs={12}
                sm={12}
                md={6}
                item
                sx={{ pl: '15px', pr: '15px', pb: '5px' }}
              >
                <Typography xs={{ fontSize: '12px' }}>
                  Users by Country
                </Typography>
                <Box
                  sx={{
                    overflow: 'auto',
                    height: isDesktop ? '30vh' : '20vh',
                    '&::-webkit-scrollbar': {
                      display: 'none',
                    },
                  }}
                >
                  {progress &&
                    progress?.map(col => (
                      <>
                        {col.country && (
                          <CountryByProgress
                            progress={col.count}
                            countryName={col.country}
                            flag={
                              countryNames.find(
                                r =>
                                  r.name.toLowerCase() ===
                                  col.country.toLowerCase()
                              )?.code
                            }
                            flagcolor={
                              countryNames.find(
                                r =>
                                  r.name.toLowerCase() ===
                                  col.country.toLowerCase()
                              )?.color
                            }
                          />
                        )}
                      </>
                    ))}
                </Box>
              </Grid>
            </Grid>
          </CardComponent>
        </Grid>
        <Grid xs={12} sm={12} md={4} item>
          <CardComponent title='Live Trades'>
            <Trades tardes={tardes} isDesktop={isDesktop} />
          </CardComponent>
          <img
            src='images/elements.svg'
            alt=''
            style={{ position: 'absolute', width: '10%', right: '0px' }}
          />
          <CardComponent title='Total Volume'>
            <TotalVolume
              salesvolume={salesvolume}
              isDesktop={isDesktop}
              isMiniDesktop={isMiniDesktop}
              isMac={isMac}
            />
          </CardComponent>
        </Grid>
      </Grid>
    </Container>
  );
}
