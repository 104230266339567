import { Container, Grid } from '@mui/material';
import React from 'react';
import {
  Chart,
  CategoryScale,
  LinearScale,
  LineElement,
  Tooltip,
} from 'chart.js';
import Trades from './trades';
import TotalVolume from './totalVolume';
import Graph from './graph';
import MapAnalytics from './mapAnalytics';

Chart.register(CategoryScale, LinearScale, LineElement, Tooltip);

const labels = [
    'January', 'February', 'March', 'April', 'May',
    'June', 'July','Aug','Sept','Oct','Nov','Dec'
  ]

const data = {
  labels: labels,
  datasets: [{
    label: '',
    data: [0,200,300,400,600],
    fill: true,
    borderColor: '#E93375',
    tension: 0.4,
    pointBorderColor: 'transparent'
  }]
}

export const options = {
  responsive: true,
  legend: {
    display: false,
  },
  plugins: {
    tooltip: {
      enabled: false,
    },
  },
}

export function ComingSoon ()
{
  return(
    <Container style={{ paddingLeft:  '45px',
    maxWidth:'100vw',
    paddingRight:  '45px',
    paddingTop: '10px'}}>
      <Grid container alignItems='stretch' columnSpacing={2} spacing={0}>
        <Grid xs={12} sm={12} md={8} item>
          <Graph />
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <Trades />
        </Grid>

        <Grid item xs={12} sm={12} md={8}>
          <MapAnalytics />
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <TotalVolume />
        </Grid>
      </Grid>
    </Container>
  )
}
