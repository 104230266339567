import React from 'react';
import { Typography, Box, Divider } from '@mui/material';

export function Trades({ tardes, isDesktop }) {
  return (
    <Box
      sx={{
        minWidth: '350px',
        height: isDesktop ? '265px' : '175px',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
          width: '10px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: 'rgba(221, 217, 219, 0.5)',
          borderRadius: '3px ',
        },
      }}
    >
      <Box>
        <Divider />
      </Box>
      <Box
        sx={{
          padding: '10px 0px 10px 20px',
        }}
      >
        {tardes &&
          Object.keys(tardes).length > 0 &&
          tardes?.map(trade => (
            <Typography
              sx={{
                fontSize: '13px',
                fontWeight: '300',
                mb: '10px',
                ml: '5px',
              }}
            >
              {trade.fromCurrency}
              &ensp;to&nbsp;
              {trade.toCurrency}
              &nbsp;-&nbsp;
              {trade.network}
              &nbsp;Network
            </Typography>
          ))}
      </Box>
    </Box>
  );
}
