export const arraySortByKName = array => {
  //   return array.slice().sort((a, b) => a[key] - b[key]);
  return array.slice().sort((a, b) => {
    // equal items sort equally
    if (a === b) {
      return 0;
    }
    // nulls sort after anything else
    else if (a === null) {
      return 1;
    } else if (b === null) {
      return -1;
    }

    let fa = typeof a === 'string' ? a.toLowerCase() : a,
      fb = typeof b === 'string' ? b.toLowerCase() : b;
    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  });
};
