/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-array-constructor */
import {
  Box,
  Button,
  InputAdornment,
  LinearProgress,
  Typography,
  useMediaQuery,
  OutlinedInput,
  FormControl,
  Divider,
  Stack,
} from '@mui/material';
import Input from '@mui/material/Input';
import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  AccountCircle,
  ArrowDropUp as ArrowDropUpIcon,
} from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import { Line } from 'react-chartjs-2';
import {
  Chart,
  CategoryScale,
  LinearScale,
  LineElement,
  Tooltip,
} from 'chart.js';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';
import FUNCTION_UTILS from '../generic-functions';
Chart.register(CategoryScale, LinearScale, LineElement, Tooltip);

const DisplayChart = ({ fsym, index, ch }) => {
  const [times, setTimes] = useState([]);
  const [prices, setPrices] = useState([]);
  const [gradient, setGradient] = useState([]);
  const [bgColor, setBgcolor] = useState([]);

  const API_KEY =
    '8cf7209cb162104bc80fe1e247e709b13a3fa5051c3b948bb37e9f6bd4e66ab3';

  const allCoinChartData = async fsym => {
    var fsymC = fsym;
    if (fsym == 'eths') fsymC = 'eth';
    if (fsym == 'sols') fsymC = 'sol';

    const response = await fetch(
      'https://min-api.cryptocompare.com/data/v2/histominute?fsym=' +
        fsymC +
        '&tsym=USD&limit=119&api_key=' +
        API_KEY
    );

    const json = await response.json();
    let chart_times = '',
      chart_prices = '';

    const data = json.Data.Data;

    if (data) {
      chart_times = data.map(obj => obj.time);
      chart_prices = data.map(obj => obj.high);
    }

    setTimes(chart_times);
    setPrices(chart_prices);
  };

  useEffect(() => {
    if (fsym && ch) {
      allCoinChartData(fsym);
    }
  }, [fsym, ch]);

  useEffect(() => {
    if (document.getElementsByTagName('canvas')[index]) {
      let canvas_ctx = document
        .getElementsByTagName('canvas')
        [index].getContext('2d');
      let $gradient;
      let $bgcolor;
      if (canvas_ctx) {
        $gradient = canvas_ctx.createLinearGradient(0, 0, 0, 400);
        if (ch.avg < 0) {
          $gradient.addColorStop(0, 'rgba(255,64,104,.5)');
          $gradient.addColorStop(0.425, 'rgba(255,64,104,0)');
          $bgcolor = 'rgba(255,64,104,.5)';
        } else {
          $gradient.addColorStop(0, 'rgba(93,209,101,.5)');
          $gradient.addColorStop(0.425, 'rgba(93,209,101,0)');
          $bgcolor = 'rgba(93,209,101,.5)';
        }
        setGradient($gradient);
        setBgcolor($bgcolor);
      }
    }
  }, [index]);

  return (
    <Line
      data={{
        labels: times && times.length > 0 ? times : [],
        datasets: [
          {
            label: '$',
            data: prices && prices.length > 0 ? prices : [],
            backgroundColor: gradient || 'rgba(0,0,0,0)',
            borderColor: bgColor || '#ffffff',
            borderJoinStyle: 'round',
            borderCapStyle: 'round',
            borderWidth: 1,
            pointRadius: 0,
            pointHitRadius: 10,
            lineTension: 0.2,
          },
        ],
      }}
      options={{
        title: {
          display: false,
          text: 'Heckin Chart!',
          fontSize: 35,
        },

        legend: {
          display: false,
        },

        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
          },
        },
        scales: {
          x: {
            display: false,
            grid: false,
          },
          y: {
            display: false,
            grid: false,
          },
        },

        plugins: {
          tooltip: {
            enabled: false,
          },
        },
      }}
    />
  );
};

export function ChartComponent() {
  const [searchQuery, setSearchQuery] = React.useState('');
  const [typingTimeout, setTypingTimeout] = React.useState(0);
  const [chartData, setChartData] = useState();
  const [page, setPage] = useState(0);
  const listInnerRef = useRef();
  const [limitdata] = useState(20);
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.between(1501, 2450));
  const isLaptop = useMediaQuery(theme.breakpoints.between(1240, 1430));
  const isMac = useMediaQuery(theme.breakpoints.between(1440, 1500));
  const isMiniDesktop = useMediaQuery(theme.breakpoints.between(1280, 1364));
  const isMobile = useMediaQuery(theme.breakpoints.between(320, 388));
  const HandleResponsiveness = () => {
    if (FUNCTION_UTILS.getMobileOperatingSystem() === 'iOS') {
      if (matchMedia('(min-width:370px) and (max-width:375px)').matches) {
        return '42vh';
      } else if (
        matchMedia('(min-width:400px) and (max-width:420px)').matches
      ) {
        return '50vh';
      } else if (
        matchMedia('(min-width:360px) and (max-width:369px)').matches
      ) {
        return '41vh';
      } else if (
        matchMedia('(min-width:389px) and (max-width:400px)').matches
      ) {
        return '42vh';
      } else if (
        matchMedia('(min-width:425px) and (max-width:440px)').matches
      ) {
        return '51vh';
      } else if (
        matchMedia('(min-width:800px) and (max-width:820px)').matches
      ) {
        return '12vh';
      } else {
        return '25vh';
      }
    } else if (FUNCTION_UTILS.getMobileOperatingSystem() === 'Android') {
      if (matchMedia('(min-width:370px) and (max-width:375px)').matches) {
        return '42vh';
      } else if (
        matchMedia('(min-width:400px) and (max-width:420px)').matches
      ) {
        return '60vh';
      } else if (
        matchMedia('(min-width:360px) and (max-width:369px)').matches
      ) {
        return '55vh';
      } else if (
        matchMedia('(min-width:389px) and (max-width:400px)').matches
      ) {
        return '55vh';
      } else if (
        matchMedia('(min-width:425px) and (max-width:440px)').matches
      ) {
        return '50vh';
      } else if (
        matchMedia('(min-width:800px) and (max-width:820px)').matches
      ) {
        return '60vh';
      } else {
        return '55vh';
      }
    } else {
      if (isDesktop) {
        return '66vh';
      } else if (isLaptop) {
        return '59vh';
      } else if (isMiniDesktop) {
        return '59vh';
      } else if (isMac) {
        return '65vh';
      } else if (
        matchMedia('(min-width:800px) and (max-width:820px)').matches
      ) {
        return '67vh';
      } else if (matchMedia('(min-width:961px)').matches) {
        return '56vh';
      } else {
        return '63vh';
      }
    }
  };
  const API_KEY =
    '8cf7209cb162104bc80fe1e247e709b13a3fa5051c3b948bb37e9f6bd4e66ab3';

  const allCoinList = async newPage => {
    const response = await fetch(
      'https://min-api.cryptocompare.com/data/top/mktcapfull?limit=' +
        limitdata +
        '&page=' +
        newPage +
        '&tsym=USD&api_key=' +
        API_KEY
    );

    const json = await response.json();

    const Allcolinlist = json.Data;
    return {
      Allcolinlist,
    };
  };

  const allCoinUpdateData = async value => {
    const response = await fetch(
      'https://min-api.cryptocompare.com/data/pricemultifull?fsyms=' +
        value +
        '&tsyms=USD&api_key=' +
        API_KEY
    );
    const json = await response.json();
    const colinlist = json.RAW;
    return {
      colinlist,
    };
  };

  async function searchResult(value) {
    let { colinlist } = await allCoinUpdateData(value);
    var data = new Array();

    if (colinlist) {
      const tempObject = colinlist[Object.keys(colinlist)[0]];
      data.push({
        name: value,
        symbole: tempObject.USD.FROMSYMBOL,
        price: tempObject.USD.PRICE,
        images: tempObject.USD.IMAGEURL,
        avg: tempObject.USD.CHANGEPCTDAY,
        mktcap: tempObject.USD.MKTCAP,
        supply: tempObject.USD.SUPPLY,
      });
    }
    setIsLoading(false);
    setChartData(data);
  }

  async function DataofAllcoins(newPage) {
    let { Allcolinlist } = await allCoinList(newPage);
    var data = new Array();

    Allcolinlist.forEach(function (coin, index) {
      if (coin.RAW) {
        data.push({
          name: coin.CoinInfo.FullName,
          symbole: coin.RAW.USD.FROMSYMBOL,
          price: coin.RAW.USD.PRICE,
          images: coin.RAW.USD.IMAGEURL,
          avg: coin.RAW.USD.CHANGEPCTDAY,
          mktcap: coin.RAW.USD.MKTCAP,
          supply: coin.RAW.USD.SUPPLY,
        });
      }
    });
    return { data };
  }

  async function fetchcharData(fromSearch, newPage) {
    setPage(newPage);
    setIsLoading(true);
    let { data } = await DataofAllcoins(newPage);
    if (chartData && Object.keys(chartData).length > 0 && !fromSearch) {
      let tempArray = chartData.concat(data);
      let uniqueObjArray = [
        ...new Map(tempArray.map(item => [item['symbole'], item])).values(),
      ];
      setIsLoading(false);
      setChartData(uniqueObjArray);
    } else {
      setIsLoading(false);
      setChartData(data);
    }
  }

  useEffect(() => {
    fetchcharData('', page);
  }, []);

  const handleType = e => {
    setSearchQuery(e.target.value);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      setTimeout(() => {
        if (e.target.value) {
          setChartData([]);
          searchResult(e.target.value);
        } else {
          setChartData([]);
          fetchcharData('FromSearch', 0);
        }
      }, 1500)
    );
  };

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        setPage(page + 1);
        fetchcharData('', page + 1);
        // console.log('Reached bottom');
      }
    }
  };

  return (
    <Box component='div'>
      <Box className='search-box'>
        <OutlinedInput
          sx={{
            height: '2.6rem',
            background: '#222141',
            border: 'navajowhite',
            outline: 'none',
            color: '#fff',
            borderRadius: '10px',
            fontSize: '12px',
            '&:hover': {
              outline: 'none',
              borderColor: 'transparent',
            },
          }}
          startAdornment={
            <InputAdornment position='start'>
              <SearchIcon sx={{ color: '#818C99', fontSize: '1.2rem' }} />
            </InputAdornment>
          }
          type='search'
          value={searchQuery}
          onChange={handleType}
          id='searchCoin'
          placeholder={'Search Any Coin'}
          className='search-box'
        />
      </Box>
      <Divider sx={{ marginTop: '10px' }} />
      <Box
        component='div'
        className='CoinChartList'
        sx={{
          padding:
            matchMedia('(min-width:389px) and (max-width:400px)').matches ===
            true
              ? '6px 15px 6px 15px'
              : '6px 0px 5px 0px',
          borderRadius: '10px',
          height: HandleResponsiveness(),
          overflow: 'auto',
          width: '100%',
          '&::-webkit-scrollbar': {
            width: '12px !important',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#626273',
            borderRadius: '2px !important',
          },
        }}
        onScroll={() => onScroll()}
        ref={listInnerRef}
      >
        <>
          {chartData && Object.keys(chartData).length > 0 ? (
            chartData.map((ch, index) => {
              return (
                <Box
                  component='div'
                  sx={{ display: 'flex', justifyContent: 'space-around' }}
                >
                  <Stack
                    direction='row'
                    spacing={1}
                    alignItems='stretch'
                    sx={{ marginRight: isMobile ? '' : '50px' }}
                  >
                    <img
                      style={{ height: '25px' }}
                      src={`https://www.cryptocompare.com${ch.images}`}
                      alt=''
                    />
                    <Typography sx={{ fontSize: '14px', lineHeight: '24px' }}>
                      {ch.symbole}
                    </Typography>
                  </Stack>
                  <div
                    style={{
                      display: 'block',
                      width: '90px',
                      height: '36px',
                      marginLeft: '10px',
                    }}
                  >
                    <DisplayChart fsym={ch.symbole} index={index} ch={ch} />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-end',
                      justifyContent: 'space-between',
                      flex: '0 0 80px',
                      marginBottom: '10px',
                    }}
                  >
                    <Typography sx={{ fontSize: '12px' }}>{`$${ch.price.toFixed(
                      2
                    )}`}</Typography>

                    <div style={{ display: 'flex' }}>
                      {ch.avg > 0 ? (
                        <ArrowDropUpIcon
                          sx={{
                            fontSize: '20px',
                            lineHeight: '19px',
                            color: '#5dd165',
                          }}
                        />
                      ) : (
                        <ArrowDropDownIcon
                          sx={{
                            fontSize: '20px',
                            lineHeight: '19px',
                            color: '#ff4068',
                          }}
                        />
                      )}
                      <Typography
                        sx={{
                          fontSize: '12px',
                          lineHeight: '24px',
                          color: ch.avg > 0 ? '#5dd165' : '#ff4068',
                        }}
                      >
                        {ch.avg.toFixed(2)}%
                      </Typography>
                    </div>
                  </div>
                </Box>
              );
            })
          ) : (
            <p style={{ width: 'fit-content', margin: 'auto' }}>Not Found</p>
          )}
          {isLoading && (
            <Box component='div' sx={{ width: '100%', color: 'white' }}>
              <LinearProgress color='inherit' />
            </Box>
          )}
          {/* {isMobile && chartData && Object.keys(chartData).length > 0 && (
            // <Box component='div' sx={{ width: 'fit-content', margin: 'auto' }}>
            //   <Button
            //     onClick={() => {
            //       fetchcharData('', page + 1);
            //     }}
            //   >
            //     Load More
            //   </Button>
            // </Box>
          )} */}
        </>
      </Box>
    </Box>
  );
}
