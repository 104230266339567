import { useSubscription } from 'defi-sdk';
import { useCallback, useMemo } from 'react';
import { useAddressAssets } from 'defi-sdk';
interface bscassets {
  asset_code: string;
  name: string;
  symbol: string;
  decimals: number;
  icon_url: string | null;
  value: number | null;
  type: string;
}

export function UserBscassets(address?: string) {
  return useSubscription<bscassets, 'address', 'bsc-assets'>({
    namespace: 'address',

    getId: useCallback((item: bscassets) => item.asset_code, []),

    body: useMemo(() => {
      const payload = {
        address,
        currency: 'USD',
      };
      return {
        scope: ['bsc-assets'],
        payload,
      };
    }, [address]),
  });
}

export function UserPolyAssets(address?: string) {
  return useSubscription<bscassets, 'address', 'polygon-assets'>({
    namespace: 'address',

    getId: useCallback((item: bscassets) => item.asset_code, []),

    body: useMemo(() => {
      const payload = {
        address,
        currency: 'USD',
      };
      return {
        scope: ['polygon-assets'],
        payload,
      };
    }, [address]),
  });
}

export function UserEthassets(address?: string) {
  return useAddressAssets(
    {
      currency: 'USD',
      address: address || '',
    },
    {
      enabled: Boolean(address),
    }
  );
}
