import React from "react";
import {
Chart as ChartJS,
CategoryScale,
LinearScale,
PointElement,
LineElement
} from 'chart.js';
import { Line } from 'react-chartjs-2'
ChartJS.register(
CategoryScale,
LinearScale,
PointElement,
LineElement

);

interface ChartProps {
  
charts :{others:[]};

}


export const ChartLive = ({ charts }: ChartProps) => {
const dataarray = charts.others;
const datadynmiac = [] || null;
const labels  = [] || null;
let backgroundColor = 'rgba(255, 99, 132, 0.5)';
let borderColor = 'rgba(255, 99, 132, 0.5)';
for (var value of dataarray) {

datadynmiac.push(value[1]);	
labels.push(value[0]);

}  
const currentValue = dataarray[dataarray.length-dataarray.length][1];
const lastValue = dataarray[dataarray.length-1][1];

 if(lastValue > currentValue)
 {
   backgroundColor = 'rgb(93 209 101)';
   borderColor = 'rgb(93 209 101)';
 }
const options = {
responsive: true,
  legend: {
    position: 'top' as const,
    display:false,
    enabled: false,
  },
  title: {
    display: true,
    text: 'Chart.js Line Chart',
  },
   
  layout: {
    padding: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0
    }
  },

  scales: {
    x: {
      display: false,
      enabled: false,
      ticks: {
        display: false
    },
      gridLines: { offsetGridLines: false,}
    },
    y: {
      display: false,
      enabled: false,
      ticks: {
        display: false
    },
      gridLines: { offsetGridLines: false,}
    }
  },

  tooltips: {
    enabled:false,
    display: false,
    callbacks: {
      //This removes the tooltip title
      title: function() {}
    },
    //this removes legend color
    displayColors: false,
    yPadding: 10,
    xPadding: 10,
    position: 'nearest',
    caretSize: 10,
    backgroundColor: backgroundColor,
    bodyFontSize: 15,
    bodyFontColor: '#303030' 
  }
};

const datafinal = {
labels,
datasets: [
  {
    label: '$',
    data:datadynmiac,
    borderColor: borderColor,
    fill: {
      target: 'origin',
      above: 'rgb(255, 0, 0)',   // Area will be red above the origin
      below: 'rgb(0, 0, 255)'    // And blue below the origin
    },
    borderWidth: 1,
    pointRadius: 0,
    pointHitRadius:10,
    lineTension: .2,
}
],
};

return ( <div className="chart"><Line options={options} data={datafinal} /></div>);
};
