import React from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Button,
  Stack,
  Modal,
  Typography,
  useMediaQuery,
  Divider
} from '@mui/material';
//import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const style = (width,height) => ({
  position: 'absolute',
  top: '48%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: width || '40%',
  overflow: 'auto',
  border: 0,
  maxHeight: height,
  borderRadius: '15px',
  bgcolor: '#303043',
  boxShadow: 5,
  pl: 4.3,
  pr:4.3,
  pb:3,
  pt:2,
  '@media (max-width: 900px)': {
    width: '95%',
  },
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

export const DisclaimerAlert = ({ open, setOpen, handleConfirm }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(767));
  const is4K = useMediaQuery(theme.breakpoints.up(1600))
  return (
    <Modal
      open={open}
      // onClose={() => setOpen(false)}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      disableAutoFocus
      disableEnforceFocus

    >
      <Box sx={is4K ? style('30vw','1500px') : style('70vw','500px')}>
        {/* <Box sx={{ float: 'right' }}>
          <IconButton onClick={() => setOpen(false)}>
            <CloseOutlinedIcon />
          </IconButton>
        </Box> */}

        <Box marginTop={0.5}>
          <Box component='div'>
            {/* <div
              style={{
                width: 'fit-content',
                margin: 'auto',
                marginBottom: '10px',
              }}
            >
              <h2>Disclaimer</h2>
            </div> */}
            <Stack direction='column' alignItems='stretch'>
              <Typography sx={{
                  width: 'fit-content',
                  margin: 'auto',
                  marginBottom: '5px',
                  fontSize:'22px'
                  }}>
                Disclaimer
              </Typography>
              <Divider />
              <Box >
                <Typography sx={{mt:'10px', mb:'10px',textAlign: 'justify', fontSize:'14px', fontWeight:'400' }}>
                  Disclaimer: Please note that this is a beta version of the
                  VentiSwap platform which is still undergoing final testing and
                  upgrades before its official release. The platform, its tech and
                  all content found on it are provided on an “as is” and “as
                  available” basis. VentiSwap does not give any warranties,
                  whether express or implied, as to the suitability or usability
                  of the platform, its tech or any of its content.
                </Typography>
                <Typography sx={{ textAlign: 'justify' , fontSize:'14px', fontWeight:'400', mb:'10px', }}>
                  VentiSwap will not be liable for any loss, whether such loss is
                  direct, indirect, special or consequential, suffered by any
                  party as a result of their use of the VentiSwap platform, its
                  tech or content.
                </Typography>
                <Typography sx={{ textAlign: 'justify' , fontSize:'14px', fontWeight:'400', mb:'10px', }}>
                  During this beta version, staking in order to use the
                  functionalities of the VentiSwap platform will not be required.
                  While the VentiSwap platform uses Automated Market Makers (AMM),
                  please note that prices can change due to market conditions and
                  fluctuations.
                </Typography>
                <Typography sx={{ textAlign: 'justify' , fontSize:'14px', fontWeight:'400', mb:'10px', }}>
                  Please note transactions during prior testings of the VentiSwap
                  platform ranged between 5 minutes to 15 minutes for completion
                  (depending on selected networks)
                </Typography>
                <Typography sx={{ textAlign: 'justify' , fontSize:'14px', fontWeight:'400', mb:'10px', }}>
                  Should you encounter any bugs, glitches, lack of functionality
                  or other problems on the VentiSwap platform, please let us know
                  immediately so we can rectify these accordingly. Your help in
                  this regard is greatly appreciated! You can write to us at this
                  address info@ventiswap.com
                </Typography>
                <Typography sx={{ textAlign: 'justify' , fontSize:'14px', fontWeight:'400', mb:'10px', }}>
                  By clicking “Accept”, you agree to the terms and conditions of
                  this disclaimer.
                </Typography>
              </Box>
              <Box
                component='div'
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Button
                  variant='contained'
                  onClick={() => handleConfirm()}
                  sx={{
                    color: 'white',
                    borderRadius:'5px',
                    background: 'linear-gradient(108.51deg, rgb(242, 25, 161) 53.69%, rgb(173, 12, 248) 100.22%, rgb(254, 0, 126) 100.23%)',
                    '&:hover': { color: 'white', background: 'linear-gradient(108.51deg, rgb(242, 25, 161) 53.69%, rgb(173, 12, 248) 100.22%, rgb(254, 0, 126) 100.23%)' },
                    pl:'65px',pr:'65px',height:'5.5vh'
                  }}
                >
                  Accept
                </Button>
              </Box>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
