import React from 'react';
import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ProgressBar } from '../progressbar';
export const CountryFlag = ({ width, height, src, alt }) => {
  return (
    <div>
      <img
        src={src}
        width={width}
        height={height}
        alt={alt}
        style={{ borderRadius: '50%' }}
      />
    </div>
  );
};
export function CountryByProgress({ progress, countryName, flag, flagcolor }) {
  return (
    <>
      <Grid container sx={{ paddingTop: '8px' }}>
        <Grid item xs={12} md={12} sm={12} lg={5}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <CountryFlag
              src={`https://countryflagsapi.com/png/${flag}`}
              width='20px'
              height='20px'
              alt={flag}
            />
            <Typography sx={{ fontSize: '14px', ml: '15px', mr: '15px' }}>
              {countryName}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={12} sm={12} lg={7}>
          <ProgressBar progress={progress} flagcolor={flagcolor} />
        </Grid>
      </Grid>
    </>
  );
}
