import axios from 'axios';

const baseURL = process.env.REACT_APP_BACKEND_URL;
const http = axios.create({ baseURL });

http.interceptors.request.use(
  request => {
    request.headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
    return request;
  },
  error => error
);

export default http;
