/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import {
  Box,
  InputAdornment,
  OutlinedInput,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { verify } from '../../interface';
import Circle from '../../icons/circle';
import { useTheme } from '@mui/material/styles';
import MouseOverPopover from '../popover';
import { RowComponent } from './rowComponent';
interface IProps {
  verificationData: verify | null;
  handleSubmitVerify: any;
  trxHashVerification: string | null;
}

export function VerifyTransaction({
  verificationData,
  handleSubmitVerify,
  trxHashVerification,
}: IProps) {
  const [verifyTx, setVerifyTx] = React.useState<string>('');
  const theme: any = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.between(1501, 2450));
  const isLaptop = useMediaQuery(theme.breakpoints.between(1240, 1430));
  const isMac = useMediaQuery(theme.breakpoints.between(1440, 1500));
  const isMiniDesktop = useMediaQuery(theme.breakpoints.between(1280, 1364));
  const isMobile = useMediaQuery(theme.breakpoints.between(320, 480));
  const handleHeight = () => {
    if (isDesktop) {
      return '37.5vh';
    } else if (isLaptop) {
      return '28.5vh';
    } else if (isMiniDesktop) {
      return '28.7vh';
    } else if (isMac) {
      return '36vh';
    } else if (
      matchMedia('(min-width:800px) and (max-width:820px)').matches === true
    ) {
      return '40vh';
    } else {
      return '32vh';
    }
  };
  useEffect(() => {
    if (trxHashVerification && trxHashVerification !== verifyTx) {
      setVerifyTx(trxHashVerification);
    }
  }, [trxHashVerification]);

  function containsNumber(str: any) {
    return /\d/.test(str);
  }

  return (
    <div className='swap-content'>
      <Box
        component='div'
        sx={{
          borderRadius: '10px',
          maxHeight: '200',
          overflow: 'auto',
          '&::-webkit-scrollbar': {
            width: '12px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#626273',
            borderRadius: '2px',
          },
        }}
      >
        <Box className='search-box'>
          <OutlinedInput
            sx={{
              height: '2.6rem',
              background: '#222141',
              border: 'navajowhite',
              outline: 'none',
              color: '#fff',
              borderRadius: '10px',
              fontSize: '12px',
              fontFamily: 'Inter-Regular !important',
              '&:hover': {
                outline: 'none',
                borderColor: 'transparent',
              },
            }}
            type='search'
            value={verifyTx}
            onChange={e => {
              setVerifyTx(e.target.value);
              handleSubmitVerify(e.target.value);
            }}
            id='searchCoin'
            placeholder='Enter transaction hash'
            className='search-box'
            endAdornment={
              <InputAdornment position='end'>
                <Circle />
              </InputAdornment>
            }
          />
        </Box>
        {verificationData && Object.keys(verificationData).length > 0 ? (
          <Box
            sx={{
              width: '100%',
              overflowX: 'hidden',
              overflowY: 'auto',
              // overflow: isMobile ? '' : 'auto',
              height: handleHeight(),
              paddingLeft: '20px',
              paddingRight: '15px',
              '&::-webkit-scrollbar': {
                width: '12px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#626273',
                borderRadius: '2px',
              },
            }}
          >
            {verificationData.completed ? (
              <Box
                component='div'
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  background: '#15CDA1',
                  width: '100%',
                  borderRadius: '10px',
                  marginTop: '10px',
                  height: '30px',
                }}
              >
                <Box
                  sx={{
                    width: '96%',
                    margin: 'auto',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                >
                  <Typography
                    sx={{ fontSize: '12px', display: 'flex', gap: '2%' }}
                  >
                    <img
                      src='/images/check-circle.png'
                      alt='success'
                      style={{ width: '20px', height: '18px' }}
                    />
                    Transaction Successfully Completed!
                  </Typography>
                </Box>
              </Box>
            ) : (
              ''
            )}
            <div style={{ margin: '5px' }}>
              <RowComponent
                value={
                  containsNumber(verificationData?.info?.split(' ')[0]) &&
                  verificationData?.info?.split(' ')[0].length > 25
                    ? `${verificationData?.info?.split(' ')[0].substr(0, 33)}`
                    : verificationData?.info
                }
                title='Info:'
              />

              <RowComponent
                value={verificationData.trxHash}
                title='Trx Hash:'
              />
              <RowComponent
                value={verificationData.toCurrency}
                title='To Currency:'
              />

              <RowComponent
                value={verificationData.fromCurrency}
                title='From Currency:'
              />

              <RowComponent
                value={
                  verificationData.toCurrencyAddress.length > 25
                    ? `${verificationData.toCurrencyAddress}`
                    : verificationData.toCurrencyAddress
                }
                title='To Currency Address:'
              />

              <RowComponent
                value={verificationData.amount}
                title='From Currency Amount:'
              />

              {/* <Box sx={{ display: 'flex', marginTop: '10px' }}>
                <Typography
                  sx={{
                    fontSize: '10px',
                    color: '#FFFFFF',
                    opacity: '0.5',
                    whiteSpace: 'nowrap',
                  }}
                >
                  Amount:
                </Typography>
                <Typography
                  sx={{
                    fontSize: '10px',
                    position: 'relative',
                    left: isMobile
                      ? '14.2rem'
                      : isLaptop
                      ? '16.7rem'
                      : isMiniDesktop
                      ? '16.7rem'
                      : isMac
                      ? '19.7rem'
                      : isDesktop
                      ? '24.7rem'
                      : '19.7rem',
                  }}
                >
                  {verificationData.amount}
                </Typography>
              </Box> */}
              <RowComponent
                value={verificationData.withdrawalAmount || '--'}
                title='Withdrawal Amount:'
              />

              {/* <Box sx={{ display: 'flex', marginTop: '10px' }}>
                <Typography
                  sx={{
                    fontSize: '10px',
                    color: '#FFFFFF',
                    opacity: '0.5',
                    whiteSpace: 'nowrap',
                  }}
                >
                  Transfer Fee:
                </Typography>
                <Typography
                  sx={{
                    fontSize: '10px',
                    position: 'relative',
                    left: isMobile
                      ? '14rem'
                      : isLaptop
                      ? '16rem'
                      : isMiniDesktop
                      ? '16rem'
                      : isMac
                      ? '19rem'
                      : isDesktop
                      ? '24rem'
                      : '19rem',
                  }}
                >
                  {verificationData.transferFee || '--'}
                </Typography>
              </Box> */}
              <RowComponent
                value={verificationData.swapFee}
                title='Swap Fee:'
              />

              <RowComponent value={verificationData.network} title='Network:' />
            </div>
          </Box>
        ) : (
          <Box
            sx={{
              width: '100%',
              overflow: isMobile ? '' : 'hidden',
              height: handleHeight(),
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            }}
          >
            <Box
              sx={{
                width: '60%',
                margin: 'auto',
                textAlign: 'center',
                paddingTop: '35px',
              }}
            >
              <img alt='' src='images/assets.svg' />
              <Typography sx={{ fontSize: '12px', color: '#707585' }}>
                Nothing Found
              </Typography>
              <Typography sx={{ fontSize: '10px', color: '#707585' }}>
                We weren’t able to locate any Transaction
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </div>
  );
}
