export const FROM_TOKENS = [
  'BTC',
  'AAVE',
  'ALICE',
  'ALPINE',
  'ANT',
  'APE',
  'ATA',
  'AVA',
  'BAKE',
  'BSW',
  'CAKE',
  'CHZ',
  'DAI',
  'DAR',
  'DUSK',
  'DYDX',
  'ENJ',
  'FTT',
  'GAL',
  'GALA',
  'GMT',
  'IDEX',
  'ILV',
  'LINK',
  'LIT',
  'LOKA',
  'MANA',
  'MBOX',
  'MULTI',
  'OOKI',
  'PAXG',
  'PERP',
  'PHA',
  'RAY',
  'SAND',
  'SANTOS',
  'SHIB',
  'SUSHI',
  'SXP',
  'TKO',
  'TLM',
  'UNI',
  'WOO',
  'WRX',
  'XVS',
  'CHR',
  'COMP',
  'COS',
  'CTSI',
  'FRONT',
  'POND',
];

export const TO_TOKENS = [
  'AAVE',
  'ALICE',
  'ALPINE',
  'ANT',
  'APE',
  'ATA',
  'AVA',
  'BAKE',
  'BSW',
  'CAKE',
  'CHZ',
  'DAI',
  'DAR',
  'DUSK',
  'DYDX',
  'ENJ',
  'FTT',
  'GAL',
  'GALA',
  'GMT',
  'IDEX',
  'ILV',
  'LINK',
  'LIT',
  'LOKA',
  'MANA',
  'MBOX',
  'MULTI',
  'OOKI',
  'PAXG',
  'PERP',
  'PHA',
  'RAY',
  'SAND',
  'SANTOS',
  'SHIB',
  'SUSHI',
  'SXP',
  'TKO',
  'TLM',
  'UNI',
  'WOO',
  'WRX',
  'XVS',
  'CHR',
  'COMP',
  'COS',
  'CTSI',
  'FRONT',
  'POND',
];
