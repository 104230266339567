export const countryNames = [
  {
    name: 'Afghanistan',
    code: 'AF',
    color: '#563687',
  },
  {
    name: 'land Islands',
    code: 'AX',
    color: '#ac8487',
  },
  {
    name: 'Albania',
    code: 'AL',
    color: '#54248f',
  },
  {
    name: 'Algeria',
    code: 'DZ',
    color: '#9c8be4',
  },
  {
    name: 'American Samoa',
    code: 'AS',
    color: '#c804e8',
  },
  {
    name: 'AndorrA',
    code: 'AD',
    color: '#db07a3',
  },
  {
    name: 'Angola',
    code: 'AO',
    color: '#712bbe',
  },
  {
    name: 'Anguilla',
    code: 'AI',
    color: '#ec4af2',
  },
  {
    name: 'Antarctica',
    code: 'AQ',
    color: '#ad8a2a',
  },
  {
    name: 'Antigua and Barbuda',
    code: 'AG',
    color: '#4bf5e6',
  },
  {
    name: 'Argentina',
    code: 'AR',
    color: '#70fa23',
  },
  {
    name: 'Armenia',
    code: 'AM',
    color: '#2d0036',
  },
  {
    name: 'Aruba',
    code: 'AW',
    color: '#5021f2',
  },
  {
    name: 'Australia',
    code: 'AU',
    color: '#dfb00f',
  },
  {
    name: 'Austria',
    code: 'AT',
    color: '#12f46f',
  },
  {
    name: 'Azerbaijan',
    code: 'AZ',
    color: '#4e0d5d',
  },
  {
    name: 'Bahamas',
    code: 'BS',
    color: '#ede36d',
  },
  {
    name: 'Bahrain',
    code: 'BH',
    color: '#cb234c',
  },
  {
    name: 'Bangladesh',
    code: 'BD',
    color: '#908f3f',
  },
  {
    name: 'Barbados',
    code: 'BB',
    color: '#11be4a',
  },
  {
    name: 'Belarus',
    code: 'BY',
    color: '#b8d07c',
  },
  {
    name: 'Belgium',
    code: 'BE',
    color: '#6b006f',
  },
  {
    name: 'Belize',
    code: 'BZ',
    color: '#e3c772',
  },
  {
    name: 'Benin',
    code: 'BJ',
    color: '#4b0a1d',
  },
  {
    name: 'Bermuda',
    code: 'BM',
    color: '#eac790',
  },
  {
    name: 'Bhutan',
    code: 'BT',
    color: '#2b9bf7',
  },
  {
    name: 'Bolivia',
    code: 'BO',
    color: '#4ff575',
  },
  {
    name: 'Bosnia and Herzegovina',
    code: 'BA',
    color: '#f9500d',
  },
  {
    name: 'Botswana',
    code: 'BW',
    color: '#22a4ff',
  },
  {
    name: 'Bouvet Island',
    code: 'BV',
    color: '#705e99',
  },
  {
    name: 'Brazil',
    code: 'BR',
    color: '#ee5d81',
  },
  {
    name: 'British Indian Ocean Territory',
    code: 'IO',
    color: '#ef196a',
  },
  {
    name: 'Brunei Darussalam',
    code: 'BN',
    color: '#bf99b5',
  },
  {
    name: 'Bulgaria',
    code: 'BG',
    color: '#39885e',
  },
  {
    name: 'Burkina Faso',
    code: 'BF',
    color: '#a91966',
  },
  {
    name: 'Burundi',
    code: 'BI',
    color: '#b658d1',
  },
  {
    name: 'Cambodia',
    code: 'KH',
    color: '#6309c2',
  },
  {
    name: 'Cameroon',
    code: 'CM',
    color: '#0e8dff',
  },
  {
    name: 'Canada',
    code: 'CA',
    color: '#d399eb',
  },
  {
    name: 'Cape Verde',
    code: 'CV',
    color: '#c8e5bc',
  },
  {
    name: 'Cayman Islands',
    code: 'KY',
    color: '#9baafd',
  },
  {
    name: 'Central African Republic',
    code: 'CF',
    color: '#76b53c',
  },
  {
    name: 'Chad',
    code: 'TD',
    color: '#5d1d15',
  },
  {
    name: 'Chile',
    code: 'CL',
    color: '#3080d2',
  },
  {
    name: 'China',
    code: 'CN',
    color: '#265b82',
  },
  {
    name: 'Christmas Island',
    code: 'CX',
    color: '#8f6b47',
  },
  {
    name: 'Cocos (Keeling) Islands',
    code: 'CC',
    color: '#40aa91',
  },
  {
    name: 'Colombia',
    code: 'CO',
    color: '#53053b',
  },
  {
    name: 'Comoros',
    code: 'KM',
    color: '#73eac4',
  },
  {
    name: 'Congo',
    code: 'CG',
    color: '#04307e',
  },
  {
    name: 'Congo, The Democratic Republic of the',
    code: 'CD',
    color: '#979ea1',
  },
  {
    name: 'Cook Islands',
    code: 'CK',
    color: '#7e356d',
  },
  {
    name: 'Costa Rica',
    code: 'CR',
    color: '#e391ba',
  },
  {
    name: 'Cote DIvoire',
    code: 'CI',
    color: '#a1ecde',
  },
  {
    name: 'Croatia',
    code: 'HR',
    color: '#933a45',
  },
  {
    name: 'Cuba',
    code: 'CU',
    color: '#52f828',
  },
  {
    name: 'Cyprus',
    code: 'CY',
    color: '#2dbf07',
  },
  {
    name: 'Czech Republic',
    code: 'CZ',
    color: '#5fdd3c',
  },
  {
    name: 'Denmark',
    code: 'DK',
    color: '#51946c',
  },
  {
    name: 'Djibouti',
    code: 'DJ',
    color: '#972c15',
  },
  {
    name: 'Dominica',
    code: 'DM',
    color: '#71ebf7',
  },
  {
    name: 'Dominican Republic',
    code: 'DO',
    color: '#c96988',
  },
  {
    name: 'Ecuador',
    code: 'EC',
    color: '#c548f6',
  },
  {
    name: 'Egypt',
    code: 'EG',
    color: '#e7244b',
  },
  {
    name: 'El Salvador',
    code: 'SV',
    color: '#bb118b',
  },
  {
    name: 'Equatorial Guinea',
    code: 'GQ',
    color: '#bef43f',
  },
  {
    name: 'Eritrea',
    code: 'ER',
    color: '#ad918d',
  },
  {
    name: 'Estonia',
    code: 'EE',
    color: '#ed9fa3',
  },
  {
    name: 'Ethiopia',
    code: 'ET',
    color: '#dd1b3d',
  },
  {
    name: 'Falkland Islands (Malvinas)',
    code: 'FK',
    color: '#4c0ba6',
  },
  {
    name: 'Faroe Islands',
    code: 'FO',
    color: '#a90b62',
  },
  {
    name: 'Fiji',
    code: 'FJ',
    color: '#045003',
  },
  {
    name: 'Finland',
    code: 'FI',
    color: '#b9b1f4',
  },
  {
    name: 'France',
    code: 'FR',
    color: '#b7356c',
  },
  {
    name: 'French Guiana',
    code: 'GF',
    color: '#122a60',
  },
  {
    name: 'French Polynesia',
    code: 'PF',
    color: '#2a36c1',
  },
  {
    name: 'French Southern Territories',
    code: 'TF',
    color: '#aa3b8d',
  },
  {
    name: 'Gabon',
    code: 'GA',
    color: '#44fd57',
  },
  {
    name: 'Gambia',
    code: 'GM',
    color: '#4d65db',
  },
  {
    name: 'Georgia',
    code: 'GE',
    color: '#01ef9a',
  },
  {
    name: 'Germany',
    code: 'DE',
    color: '#740a74',
  },
  {
    name: 'Ghana',
    code: 'GH',
    color: '#047778',
  },
  {
    name: 'Gibraltar',
    code: 'GI',
    color: '#9ba14f',
  },
  {
    name: 'Greece',
    code: 'GR',
    color: '#bca5f8',
  },
  {
    name: 'Greenland',
    code: 'GL',
    color: '#db302f',
  },
  {
    name: 'Grenada',
    code: 'GD',
    color: '#52bd7a',
  },
  {
    name: 'Guadeloupe',
    code: 'GP',
    color: '#7a8b3b',
  },
  {
    name: 'Guam',
    code: 'GU',
    color: '#2f6924',
  },
  {
    name: 'Guatemala',
    code: 'GT',
    color: '#42c20e',
  },
  {
    name: 'Guernsey',
    code: 'GG',
    color: '#180f6e',
  },
  {
    name: 'Guinea',
    code: 'GN',
    color: '#4a4100',
  },
  {
    name: 'Guinea-Bissau',
    code: 'GW',
    color: '#96c8e7',
  },
  {
    name: 'Guyana',
    code: 'GY',
    color: '#95e5ca',
  },
  {
    name: 'Haiti',
    code: 'HT',
    color: '#6feb5c',
  },
  {
    name: 'Heard Island and Mcdonald Islands',
    code: 'HM',
    color: '#f21e26',
  },
  {
    name: 'Holy See (Vatican City State)',
    code: 'VA',
    color: '#96178e',
  },
  {
    name: 'Honduras',
    code: 'HN',
    color: '#ec6795',
  },
  {
    name: 'Hong Kong',
    code: 'HK',
    color: '#3c0760',
  },
  {
    name: 'Hungary',
    code: 'HU',
    color: '#647cfa',
  },
  {
    name: 'Iceland',
    code: 'IS',
    color: '#e26cd1',
  },
  {
    name: 'India',
    code: 'IN',
    color: 'orange',
  },
  {
    name: 'Indonesia',
    code: 'ID',
    color: '#f7bcda',
  },
  {
    name: 'Iran, Islamic Republic Of',
    code: 'IR',
    color: '#0b28d7',
  },
  {
    name: 'Iraq',
    code: 'IQ',
    color: '#579183',
  },
  {
    name: 'Ireland',
    code: 'IE',
    color: '#2d176c',
  },
  {
    name: 'Isle of Man',
    code: 'IM',
    color: '#1f73e6',
  },
  {
    name: 'Israel',
    code: 'IL',
    color: '#734d0a',
  },
  {
    name: 'Italy',
    code: 'IT',
    color: '#750f44',
  },
  {
    name: 'Jamaica',
    code: 'JM',
    color: '#fa67c1',
  },
  {
    name: 'Japan',
    code: 'JP',
    color: '#aca7b8',
  },
  {
    name: 'Jersey',
    code: 'JE',
    color: '#0bd42e',
  },
  {
    name: 'Jordan',
    code: 'JO',
    color: '#1f6cd7',
  },
  {
    name: 'Kazakhstan',
    code: 'KZ',
    color: '#a1d423',
  },
  {
    name: 'Kenya',
    code: 'KE',
    color: '#8168f6',
  },
  {
    name: 'Kiribati',
    code: 'KI',
    color: '#6fd14c',
  },
  {
    name: 'Korea, Democratic PeopleS Republic of',
    code: 'KP',
    color: '#4b3b59',
  },
  {
    name: 'Korea, Republic of',
    code: 'KR',
    color: '#a74e1d',
  },
  {
    name: 'Kuwait',
    code: 'KW',
    color: '#df6f68',
  },
  {
    name: 'Kyrgyzstan',
    code: 'KG',
    color: '#db08f5',
  },
  {
    name: 'Lao PeopleS Democratic Republic',
    code: 'LA',
    color: '#734a2e',
  },
  {
    name: 'Latvia',
    code: 'LV',
    color: '#da7222',
  },
  {
    name: 'Lebanon',
    code: 'LB',
    color: '#9b01a8',
  },
  {
    name: 'Lesotho',
    code: 'LS',
    color: '#f2ce05',
  },
  {
    name: 'Liberia',
    code: 'LR',
    color: '#033b70',
  },
  {
    name: 'Libyan Arab Jamahiriya',
    code: 'LY',
    color: '#796937',
  },
  {
    name: 'Liechtenstein',
    code: 'LI',
    color: '#ae76b3',
  },
  {
    name: 'Lithuania',
    code: 'LT',
    color: '#4160cd',
  },
  {
    name: 'Luxembourg',
    code: 'LU',
    color: '#fa4c7c',
  },
  {
    name: 'Macao',
    code: 'MO',
    color: '#79719f',
  },
  {
    name: 'Macedonia, The Former Yugoslav Republic of',
    code: 'MK',
    color: '#b3fd5c',
  },
  {
    name: 'Madagascar',
    code: 'MG',
    color: '#0b35a3',
  },
  {
    name: 'Malawi',
    code: 'MW',
    color: '#6ed04d',
  },
  {
    name: 'Malaysia',
    code: 'MY',
    color: '#8d430f',
  },
  {
    name: 'Maldives',
    code: 'MV',
    color: '#bc764a',
  },
  {
    name: 'Mali',
    code: 'ML',
    color: '#0e4348',
  },
  {
    name: 'Malta',
    code: 'MT',
    color: '#9c7968',
  },
  {
    name: 'Marshall Islands',
    code: 'MH',
    color: '#80a224',
  },
  {
    name: 'Martinique',
    code: 'MQ',
    color: '#9c43f5',
  },
  {
    name: 'Mauritania',
    code: 'MR',
    color: '#8ee487',
  },
  {
    name: 'Mauritius',
    code: 'MU',
    color: '#dce0d1',
  },
  {
    name: 'Mayotte',
    code: 'YT',
    color: '#726d74',
  },
  {
    name: 'Mexico',
    code: 'MX',
    color: '#ec04dc',
  },
  {
    name: 'Micronesia, Federated States of',
    code: 'FM',
    color: '#124c2b',
  },
  {
    name: 'Moldova, Republic of',
    code: 'MD',
    color: '#c87340',
  },
  {
    name: 'Monaco',
    code: 'MC',
    color: '#c5973c',
  },
  {
    name: 'Mongolia',
    code: 'MN',
    color: '#d247cb',
  },
  {
    name: 'Montenegro',
    code: 'ME',
    color: '#b9a459',
  },
  {
    name: 'Montserrat',
    code: 'MS',
    color: '#f527b7',
  },
  {
    name: 'Morocco',
    code: 'MA',
    color: '#08ea8c',
  },
  {
    name: 'Mozambique',
    code: 'MZ',
    color: '#efb414',
  },
  {
    name: 'Myanmar',
    code: 'MM',
    color: '#de4cf2',
  },
  {
    name: 'Namibia',
    code: 'NA',
    color: '#e0cdcf',
  },
  {
    name: 'Nauru',
    code: 'NR',
    color: '#b7b959',
  },
  {
    name: 'Nepal',
    code: 'NP',
    color: '#e22a95',
  },
  {
    name: 'Netherlands',
    code: 'NL',
    color: '#8a8f05',
  },
  {
    name: 'Netherlands Antilles',
    code: 'AN',
    color: '#42f1e6',
  },
  {
    name: 'New Caledonia',
    code: 'NC',
    color: '#01b06b',
  },
  {
    name: 'New Zealand',
    code: 'NZ',
    color: '#6b0696',
  },
  {
    name: 'Nicaragua',
    code: 'NI',
    color: '#28d52c',
  },
  {
    name: 'Niger',
    code: 'NE',
    color: '#fed2ad',
  },
  {
    name: 'Nigeria',
    code: 'NG',
    color: '#15895d',
  },
  {
    name: 'Niue',
    code: 'NU',
    color: '#aa1f7a',
  },
  {
    name: 'Norfolk Island',
    code: 'NF',
    color: '#e7b196',
  },
  {
    name: 'Northern Mariana Islands',
    code: 'MP',
    color: '#a2e497',
  },
  {
    name: 'Norway',
    code: 'NO',
    color: '#c4bbaf',
  },
  {
    name: 'Oman',
    code: 'OM',
    color: '#da2cdd',
  },
  {
    name: 'Pakistan',
    code: 'PK',
    color: 'green',
  },
  {
    name: 'Palau',
    code: 'PW',
    color: '#6220b0',
  },
  {
    name: 'Palestinian Territory, Occupied',
    code: 'PS',
    color: '#ccf2e8',
  },
  {
    name: 'Panama',
    code: 'PA',
    color: '#82aaa6',
  },
  {
    name: 'Papua New Guinea',
    code: 'PG',
    color: '#0ddc82',
  },
  {
    name: 'Paraguay',
    code: 'PY',
    color: '#dcb3e8',
  },
  {
    name: 'Peru',
    code: 'PE',
    color: '#46e477',
  },
  {
    name: 'Philippines',
    code: 'PH',
    color: '#cff10e',
  },
  {
    name: 'Pitcairn',
    code: 'PN',
    color: '#91503a',
  },
  {
    name: 'Poland',
    code: 'PL',
    color: '#63f6bc',
  },
  {
    name: 'Portugal',
    code: 'PT',
    color: '#cd3308',
  },
  {
    name: 'Puerto Rico',
    code: 'PR',
    color: '#718c47',
  },
  {
    name: 'Qatar',
    code: 'QA',
    color: '#86026a',
  },
  {
    name: 'Reunion',
    code: 'RE',
    color: '#60ff0f',
  },
  {
    name: 'Romania',
    code: 'RO',
    color: '#d552da',
  },
  {
    name: 'Russian Federation',
    code: 'RU',
    color: '#2fefab',
  },
  {
    name: 'RWANDA',
    code: 'RW',
    color: '#fc0dd0',
  },
  {
    name: 'Saint Helena',
    code: 'SH',
    color: '#1f8aed',
  },
  {
    name: 'Saint Kitts and Nevis',
    code: 'KN',
    color: '#9e1666',
  },
  {
    name: 'Saint Lucia',
    code: 'LC',
    color: '#8badb0',
  },
  {
    name: 'Saint Pierre and Miquelon',
    code: 'PM',
    color: '#8e343f',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    code: 'VC',
    color: '#cabda1',
  },
  {
    name: 'Samoa',
    code: 'WS',
    color: '#6cbf30',
  },
  {
    name: 'San Marino',
    code: 'SM',
    color: '#2ad7b1',
  },
  {
    name: 'Sao Tome and Principe',
    code: 'ST',
    color: '#2943f6',
  },
  {
    name: 'Saudi Arabia',
    code: 'SA',
    color: '#2e1a9e',
  },
  {
    name: 'Senegal',
    code: 'SN',
    color: '#4622bc',
  },
  {
    name: 'Serbia',
    code: 'RS',
    color: '#b9163b',
  },
  {
    name: 'Seychelles',
    code: 'SC',
    color: '#f64320',
  },
  {
    name: 'Sierra Leone',
    code: 'SL',
    color: '#522764',
  },
  {
    name: 'Singapore',
    code: 'SG',
    color: '#7c2779',
  },
  {
    name: 'Slovakia',
    code: 'SK',
    color: '#83cb37',
  },
  {
    name: 'Slovenia',
    code: 'SI',
    color: '#938d0a',
  },
  {
    name: 'Solomon Islands',
    code: 'SB',
    color: '#ffcd73',
  },
  {
    name: 'Somalia',
    code: 'SO',
    color: '#02f59a',
  },
  {
    name: 'South Africa',
    code: 'ZA',
    color: '#81b250',
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    code: 'GS',
    color: '#f4abfa',
  },
  {
    name: 'Spain',
    code: 'ES',
    color: '#7a4330',
  },
  {
    name: 'Sri Lanka',
    code: 'LK',
    color: '#cd60dc',
  },
  {
    name: 'Sudan',
    code: 'SD',
    color: '#549053',
  },
  {
    name: 'Suriname',
    code: 'SR',
    color: '#253f97',
  },
  {
    name: 'Svalbard and Jan Mayen',
    code: 'SJ',
    color: '#2b7226',
  },
  {
    name: 'Swaziland',
    code: 'SZ',
    color: '#11f444',
  },
  {
    name: 'Sweden',
    code: 'SE',
    color: '#2bb1ec',
  },
  {
    name: 'Switzerland',
    code: 'CH',
    color: '#5fe864',
  },
  {
    name: 'Syrian Arab Republic',
    code: 'SY',
    color: '#1bd348',
  },
  {
    name: 'Taiwan, Province of China',
    code: 'TW',
    color: '#d05d0f',
  },
  {
    name: 'Tajikistan',
    code: 'TJ',
    color: '#35e239',
  },
  {
    name: 'Tanzania, United Republic of',
    code: 'TZ',
    color: '#cf3998',
  },
  {
    name: 'Thailand',
    code: 'TH',
    color: '#2f21d5',
  },
  {
    name: 'Timor-Leste',
    code: 'TL',
    color: '#e435ec',
  },
  {
    name: 'Togo',
    code: 'TG',
    color: '#44f57d',
  },
  {
    name: 'Tokelau',
    code: 'TK',
    color: '#65c247',
  },
  {
    name: 'Tonga',
    code: 'TO',
    color: '#479b40',
  },
  {
    name: 'Trinidad and Tobago',
    code: 'TT',
    color: '#4ecf8c',
  },
  {
    name: 'Tunisia',
    code: 'TN',
    color: '#1aee22',
  },
  {
    name: 'Turkey',
    code: 'TR',
    color: '#12808a',
  },
  {
    name: 'Turkmenistan',
    code: 'TM',
    color: '#a3b4ff',
  },
  {
    name: 'Turks and Caicos Islands',
    code: 'TC',
    color: '#2464f5',
  },
  {
    name: 'Tuvalu',
    code: 'TV',
    color: '#581e7b',
  },
  {
    name: 'Uganda',
    code: 'UG',
    color: '#5c7c65',
  },
  {
    name: 'Ukraine',
    code: 'UA',
    color: '#7cf714',
  },
  {
    name: 'United Arab Emirates',
    code: 'AE',
    color: '#9a79ed',
  },
  {
    name: 'United Kingdom',
    code: 'GB',
    color: '#e02dc0',
  },
  {
    name: 'United States',
    code: 'US',
    color: 'blue',
  },
  {
    name: 'United States Minor Outlying Islands',
    code: 'UM',
    color: '#bdef48',
  },
  {
    name: 'Uruguay',
    code: 'UY',
    color: '#005b99',
  },
  {
    name: 'Uzbekistan',
    code: 'UZ',
    color: '#2611cd',
  },
  {
    name: 'Vanuatu',
    code: 'VU',
    color: '#b5571f',
  },
  {
    name: 'Venezuela',
    code: 'VE',
    color: '#7ccc45',
  },
  {
    name: 'Viet Nam',
    code: 'VN',
    color: '#f8557a',
  },
  {
    name: 'Virgin Islands, British',
    code: 'VG',
    color: '#05d17b',
  },
  {
    name: 'Virgin Islands, U.S.',
    code: 'VI',
    color: '#96393a',
  },
  {
    name: 'Wallis and Futuna',
    code: 'WF',
    color: '#6c712d',
  },
  {
    name: 'Western Sahara',
    code: 'EH',
    color: '#850c2a',
  },
  {
    name: 'Yemen',
    code: 'YE',
    color: '#c8e9f4',
  },
  {
    name: 'Zambia',
    code: 'ZM',
    color: '#01dd1e',
  },
  {
    name: 'Zimbabwe',
    code: 'ZW',
    color: '#b12cdc',
  },
];
