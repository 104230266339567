import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useTheme } from '@mui/material/styles';

export function Step2({
  toCurrencyNetworks,
  toCurrency,
  loading,
  getCoinDetails,
  selectedNetwork,
  setSelectedNetwork,
  trxHash,
  isMemo,
  setTag,
  memoErr,
  IsSwapDisabled,
  handleCheckMinimum,
  swapInProgress,
  receiverAddress,
  setReceiverAddress,
  receiverErr,
}) {
  const theme = useTheme();
  const [swapText, setSwapText] = useState('Started Swap');
  const [index, setIndex] = useState(0);
  const is13Pro = useMediaQuery(theme.breakpoints.between(389, 400));
  const isMobile = useMediaQuery(theme.breakpoints.between(320, 480));

  const array = [
    'Swap in progress',
    'Your patience is appreciated',
    'Please do not close the browser',
  ];

  useEffect(() => {
    let interval;
    // console.log(swapText);
    if (swapInProgress) {
      interval = setTimeout(() => {
        let cri = index;
        setIndex(cri + 1);
        setSwapText(array[index % array.length]);
      }, 2500);
      // setSwapText(array[index % array.length])
    } else {
      setSwapText('Swap');
    }
    return () => {
      clearInterval(interval);
    };
  }, [swapText]);
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        overflow: isMemo ? 'hidden' : '',
        height: isMemo && isMobile ? '35vh' : '',
        gap: isMobile ? '10px' : '',
      }}
    >
      <div
        style={{
          display: 'flex',
          background: '#222141',
          height: '2.4rem',
          // justifyContent: 'space-around',
          alignItems: 'center',
          borderRadius: '8px',
          width: '90%',
          margin: '0px 20px 5px 20px',
          padding: '10px',
        }}
      >
        <TextField
          variant='standard'
          InputProps={{
            disableUnderline: true,
            style: { fontSize: 12 },
          }}
          placeholder='Enter the receiving wallet address'
          sx={{
            '& input::placeholder': {
              fontSize: '12px',
            },
            width: '100%',
            border: receiverErr ? '1px solid red' : 'none',
          }}
          value={receiverAddress}
          onChange={e => setReceiverAddress(e.target.value)}
        />
      </div>
      {isMemo && (
        <div
          style={{
            display: 'flex',
            background: '#222141',
            height: '2.4rem',
            justifyContent: 'space-around',
            alignItems: 'center',
            borderRadius: '8px',
            width: '90%',
            margin: '0px 20px 5px 20px',
            padding: '10px',
          }}
        >
          <TextField
            variant='standard'
            InputProps={{
              disableUnderline: true,
            }}
            onChange={e => setTag(e.target.value)}
            placeholder='Enter Memo/Tag'
            sx={{
              width: '100%',
              border: memoErr ? '1px solid red' : 'none',
              '& input::placeholder': {
                fontSize: '12px',
              },
            }}
            // value={value || 0}
            // onChange={e => setValue(e.target.value)}
          />
        </div>
      )}

      <div
        style={{
          display: 'flex',
          background: '#222141',
          height: '2.4rem',
          justifyContent: 'space-around',
          alignItems: 'center',
          borderRadius: '8px',
          width: '90%',
          margin: '0px 20px 5px 20px',
          padding: '10px',
        }}
      >
        {loading ? (
          <div style={{ width: 'fit-content', margin: 'auto' }}>
            <CircularProgress />
          </div>
        ) : toCurrency &&
          !toCurrency.includes('Select') &&
          toCurrencyNetworks &&
          Object.keys(toCurrencyNetworks).length < 2 ? (
          <Button
            variant='outlined'
            sx={{ width: 'fit-content', margin: 'auto', color: 'white' }}
            onClick={() => getCoinDetails()}
          >
            Try again
          </Button>
        ) : (
          <TextField
            select
            value={loading ? 'not loaded' : selectedNetwork}
            onChange={e => setSelectedNetwork(e.target.value)}
            variant='standard'
            InputProps={{
              disableUnderline: true,
            }}
            sx={{ width: '100%' }}
            SelectProps={{
              sx: {
                outline: 'none',
                // background: '#1f2a50',
                '& .MuiSelect-select': {
                  display: 'flex',
                  gap: '10px',
                  paddingTop: '5px',
                  background: 'transparent',
                },
              },
            }}
          >
            {toCurrencyNetworks &&
              Object.keys(toCurrencyNetworks).length > 0 &&
              toCurrencyNetworks.map(
                network =>
                  network.withdrawEnable && (
                    <MenuItem
                      sx={{ gap: '10px', width: '100%' }}
                      key={network.network + '-toCurrency'}
                      value={network}
                    >
                      <Box
                        component='div'
                        sx={{ display: 'flex', gap: '15px' }}
                      >
                        <Typography sx={{ fontSize: '12px', lineHeight: '2' }}>
                          {network.network}{' '}
                        </Typography>
                        {network.name && (
                          <Typography
                            sx={{
                              color: '#f219a1',
                              fontSize: '12px',
                              lineHeight: '24px',
                            }}
                          >
                            - {network.name}
                          </Typography>
                        )}
                      </Box>
                    </MenuItem>
                  )
              )}
          </TextField>
        )}
      </div>

      <Button
        onClick={handleCheckMinimum}
        disabled={IsSwapDisabled() || swapInProgress}
        style={{
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background:
            IsSwapDisabled() || swapInProgress
              ? 'grey'
              : 'linear-gradient(108.51deg, #144FF1 -29.26%, #18A9F7 100.22%, #F219A1 100.23%)',
          fontSize: '12px',
          lineHeight: '24px',
          letterSpacing: '-0.014em',
          color: '#FFFFFF',
          height: '5vh',
          borderRadius: '5px',
          width: '90%',
          margin: 'auto',
        }}
      >
        {swapText}
      </Button>
      <TextField
        variant='standard'
        sx={{
          '& input::placeholder': {
            fontSize: '12px',
          },
          width: '90%',
          margin: 'auto',
        }}
        fullWidth
        value={trxHash}
        onClick={() => {
          navigator && navigator.clipboard.writeText(trxHash);
        }}
        placeholder='Your trx hash will display here..'
        disabled
        // className={classes.inputHeight}
        InputProps={{
          disableUnderline: true,
          endAdornment: (
            <IconButton>
              <ContentCopyIcon sx={{ width: '18px', height: '18px' }} />
            </IconButton>
          ),
        }}
      />
    </div>
  );
}
