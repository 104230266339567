import React from 'react';
import { CardComponent } from '../../Card-modal';
import { Typography, Box, Divider } from '@mui/material';

export function TotalVolume({ salesvolume, isDesktop, isMiniDesktop, isMac }) {
  return (
    <Box
      sx={{
        height: isDesktop
          ? '34.5vh'
          : isMiniDesktop
          ? '27.7vh'
          : isMac
          ? '38.001vh'
          : '26.5vh',
      }}
    >
      <Divider />
      <Box>
        <Typography
          sx={{
            textAlign: 'center',
            color: '#F219A1',
            fontSize: '24px',
            position: 'relative',
            top: isDesktop ? '15vh' : '10vh',
          }}
        >
          {salesvolume && salesvolume
            ? '$' + salesvolume?.toLocaleString()
            : '$0'}
        </Typography>
      </Box>
    </Box>
  );
}
