import { FormControl, MenuItem, Select } from '@mui/material';
import React from 'react';
import { capitalize } from '../../helpers/utilities';

export function Menu({ item, axis, setAxis }) {
  const handleChange = event => {
    setAxis(event.target.value);
  };
  return (
    <div>
      <FormControl size='large'>
        <Select
          labelId='demo-simple-select-autowidth-label'
          id='demo-simple-select-autowidth'
          value={axis}
          onChange={handleChange}
          sx={{
            maxHeight: '35px',
            fontSize: '12px',
            color: '#858688',
            borderRadius: '10px',
            background: '#FFFFFF',
            borderColor: '1px solid rgba(133, 134, 136, 0.16)',
            '.MuiSelect-icon': { color: '#858688' },
          }}
        >
          {item.map(col => (
            <MenuItem value={col} sx={{ fontSize: '12px' }}>
              {capitalize(col)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
