import React from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Button,
  Modal,
  Typography,
  useMediaQuery,
  Stack,
} from '@mui/material';
//import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const style = width => ({
  background: '#26263A',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: width || '40%',
  overflow: 'auto',
  border: 0,
  maxHeight: '630px',
  borderRadius: '20px',
  boxShadow: 5,
  p: 4,
  '@media (max-width: 767px)': {
    width: '95%',
  },
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

export const VentiAlert = ({ open, setOpen, handleConfirm }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(767));
  const space = isMobile ? 1 : 3;
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      disableAutoFocus
      disableEnforceFocus
    >
      <Box sx={style('405px')}>
        {/* <Box sx={{ float: 'right' }}>
          <IconButton onClick={() => setOpen(false)}>
            <CloseOutlinedIcon />
          </IconButton>
        </Box> */}

        <Box marginTop={1.5}>
          <Box component='div'>
            <Stack direction='column' alignItems='center'>
              <img
                src='/images/Group 1760.svg'
                alt='exclamation'
                width='40px'
              />
              <Typography
                sx={{
                  width: 'fit-content',
                  margin: 'auto',
                  mb: '10px',
                  mt: '10px',
                  fontSize: '16px',
                }}
              >
                Are you sure?
              </Typography>
              <Typography
                sx={{
                  width: 'fit-content',
                  margin: 'auto',
                  marginBottom: '20px',
                  textAlign: 'center',
                  opacity: '0.5',
                  fontSize: '14px',
                }}
              >
                Please ensure you have selected the correct network of the token
                you want to receive. You will not be able to undo this process
              </Typography>
              <Stack direction='row' alignItems='center' spacing={space}>
                <Button
                  variant='contained'
                  onClick={handleConfirm && handleConfirm}
                  sx={{
                    color: 'white',
                    background:
                      'linear-gradient(108.51deg, #F219A1 38.85%, #AD0CF8 100.2%, #FE007E 100.22%)',
                    '&:hover': {
                      color: 'white',
                      background:
                        'linear-gradient(108.51deg, #F219A1 38.85%, #AD0CF8 100.2%, #FE007E 100.22%)',
                    },
                    borderRadius: '5px',
                  }}
                >
                  Yes, confirm
                </Button>
                <Button
                  variant='contained'
                  sx={{
                    background: '#2F2F43',
                    color: 'white',
                    '&:hover': { background: '#2F2F43', color: 'white' },
                    borderRadius: '5px',
                  }}
                  onClick={() => setOpen(false)}
                >
                  No, Go back
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
