import { useCallback } from 'react';
import { ethers } from 'ethers';
import * as solanaWeb3 from '@solana/web3.js';
import Toastify from 'toastify-js';
import { USDC_ABI, USDT_ABI } from '../../utils/abi';
import { Fee, MsgSend } from '@terra-money/terra.js';
import {
  useWallet,
  useConnectedWallet,
  ConnectType,
} from '@terra-money/wallet-provider';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { TOKEN_ADDRESS_ALL } from '../../tokens';
import Web3 from 'web3';
import FUNCTION_UTILS from '../generic-functions/index';
import axios from 'axios';
import * as SolanaWeb3 from '@solana/web3.js';

export const useSwapHook = () => {
  const { connect, status, network } = useWallet();
  const connectedWallet = useConnectedWallet();

  const swapWalletConnect = useCallback(
    async (web3States: any, amount: string, walletAddress: string) => {
      const amountSent = ethers.utils.parseEther(amount);
      // console.log('AmountSent = ', amountSent);
      const walletConnectProvider = new WalletConnectProvider({
        infuraId: process.env.REACT_APP_INFURA_ID,
        // infuraId: '3a50db1a56194279814ff1ef6591a94b',
      });
      await walletConnectProvider.enable();
      const web3 = new ethers.providers.Web3Provider(walletConnectProvider);
      const signer = web3.getSigner();
      const tx = await signer.sendTransaction({
        to: walletAddress,
        value: amountSent,
      });
      // console.log(' 111 = ', amountSent);
      const hash = tx.hash;
      return hash;
    },
    []
  );

  const swapETHchain = useCallback(
    async (amount: string, walletAddress: any, connector: any) => {
      try {
        const fromProvider = await connector.getProvider();
        // await window.ethereum.request({ method: 'eth_requestAccounts' });
        const amountSent = ethers.utils.parseEther(amount);
        const provider = new ethers.providers.Web3Provider(fromProvider);
        const signer = provider.getSigner();
        console.log('signer = ', provider);
        const tx = await signer.sendTransaction({
          to: walletAddress,
          value: amountSent,
        });
        const hash = tx.hash;
        return hash;
      } catch (error) {
        console.log('ERROR = ', error);
        return false;
      }
    },
    []
  );

  const swapTrustchain = useCallback(
    async (amount: string, walletAddress: any) => {
      try {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const amountSent = ethers.utils.parseEther(amount);
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        console.log('signer = ', provider);
        const tx = await signer.sendTransaction({
          to: walletAddress,
          value: amountSent,
        });
        const hash = tx.hash;
        return hash;
      } catch (error) {
        console.log('ERROR = ', error);
        return false;
      }
    },
    []
  );

  const swapBnbExtensionchain = useCallback(
    async (amount: string, walletAddress: string) => {
      const amountSent = ethers.utils.parseEther(amount);
      const provider = new ethers.providers.Web3Provider(window.BinanceChain);
      const signer = provider.getSigner();
      const tx = await signer.sendTransaction({
        to: walletAddress,
        value: amountSent,
      });
      const hash = tx.hash;
      console.log('hash = ', hash);
      return hash;
    },
    []
  );

  const swapMaticChain = useCallback(
    async (amount: string, walletAddress: string) => {
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      const amountSent = ethers.utils.parseEther(amount);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const tx = await signer.sendTransaction({
        to: walletAddress,
        value: amountSent,
      });
      console.log('6');
      const hash = tx.hash;
      return hash;
    },
    []
  );

  const swapBSCchainTokens = useCallback(
    async (
      amount: string,
      walletAddress: string,
      tokenName:
        | 'USDT'
        | 'USDC'
        | 'FTM'
        | 'BETH'
        | 'BUSD'
        | 'AAVE'
        | 'MATIC'
        | 'AXS'
        | 'ANT'
        | 'XRP'
        | 'TRX'
        | 'ADA',
      chainId: 56 | 1 | 250 | 43114 | 137 | 10 | 1313161554 | 42161
    ) => {
      try {
        // await window.ethereum.request({ method: 'eth_requestAccounts' });
        const provider = new ethers.providers.Web3Provider(window.BinanceChain);
        const signer = await provider.getSigner();
        const contract = new ethers.Contract(
          TOKEN_ADDRESS_ALL[tokenName][chainId]?.address,
          tokenName === 'USDT' ? USDT_ABI : USDC_ABI,
          signer
        );
        const transfer = await contract.transfer(
          walletAddress,
          ethers.utils.parseUnits(amount)
        );
        const hash = transfer ? transfer.hash : '';
        console.log('Hash = ', hash);
        return hash;
      } catch (error) {
        console.log('Error = ', error);
        return false;
      }
    },
    []
  );

  const swapETHchainTokens = useCallback(
    async (
      amount: string,
      walletAddress: string,
      tokenName:
        | 'USDT'
        | 'USDC'
        | 'FTM'
        | 'BETH'
        | 'BUSD'
        | 'AAVE'
        | 'MATIC'
        | 'AXS'
        | 'ANT'
        | 'XRP'
        | 'TRX'
        | 'ADA',
      chainId: 56 | 1 | 250 | 43114 | 137 | 10 | 1313161554 | 42161,
      connector: any
    ) => {
      try {
        const fromProvider = await connector.getProvider();
        // await window.ethereum.request({ method: 'eth_requestAccounts' });
        const provider = new ethers.providers.Web3Provider(fromProvider);
        const signer = await provider.getSigner();
        const contract = new ethers.Contract(
          TOKEN_ADDRESS_ALL[tokenName][chainId]?.address,
          tokenName === 'USDT' ? USDT_ABI : USDC_ABI,
          signer
        );
        const transfer = await contract.transfer(
          walletAddress,
          ethers.utils.parseUnits(amount)
        );
        const hash = transfer ? transfer.hash : '';
        console.log('Hash = ', hash);
        return hash;
      } catch (error: any) {
        if (error?.message) {
          FUNCTION_UTILS.showNotification(error?.message);
        }
        console.log('Error = ', error);
        return false;
      }
    },
    []
  );

  const swapTrustChainTokens = useCallback(
    async (
      amount: string,
      walletAddress: string,
      tokenName:
        | 'USDT'
        | 'USDC'
        | 'FTM'
        | 'BETH'
        | 'BUSD'
        | 'AAVE'
        | 'MATIC'
        | 'AXS'
        | 'ANT'
        | 'XRP'
        | 'TRX'
        | 'ADA',
      chainId: 56 | 1 | 250 | 43114 | 137 | 10 | 1313161554 | 42161
    ) => {
      try {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = await provider.getSigner();
        const contract = new ethers.Contract(
          TOKEN_ADDRESS_ALL[tokenName][chainId]?.address,
          tokenName === 'USDT' ? USDT_ABI : USDC_ABI,
          signer
        );
        const transfer = await contract.transfer(
          walletAddress,
          ethers.utils.parseUnits(amount)
        );
        const hash = transfer ? transfer.hash : '';
        console.log('Hash = ', hash);
        return hash;
      } catch (error: any) {
        if (error?.message) {
          FUNCTION_UTILS.showNotification(error?.message);
        }
        console.log('Error = ', error);
        return false;
      }
    },
    []
  );

  const swapAssetWalletConnect = useCallback(
    async (
      amount: string,
      walletAddress: string,
      tokenName:
        | 'USDT'
        | 'USDC'
        | 'FTM'
        | 'BETH'
        | 'BUSD'
        | 'AAVE'
        | 'MATIC'
        | 'AXS'
        | 'ANT'
        | 'XRP'
        | 'TRX'
        | 'ADA',
      chainId: 56 | 1 | 250 | 43114 | 137 | 10 | 1313161554 | 42161,
      web3States: any
    ) => {
      try {
        const walletConnectProvider = new WalletConnectProvider({
          infuraId: process.env.REACT_APP_INFURA_ID,
        });
        await walletConnectProvider.enable();
        const web3 = new ethers.providers.Web3Provider(walletConnectProvider);
        const signer = web3.getSigner();
        const contract = new ethers.Contract(
          TOKEN_ADDRESS_ALL[tokenName][chainId]?.address,
          tokenName === 'USDT' ? USDT_ABI : USDC_ABI,
          signer
        );
        const transfer = await contract.transfer(
          walletAddress,
          ethers.utils.parseUnits(amount)
        );
        const hash = transfer ? transfer.hash : '';
        console.log('Hash = ', hash);
        return hash;
      } catch (error) {
        console.log('ERROR = ', error);
        return false;
      }
    },
    []
  );

  const swapSolanaChain = useCallback(
    async (amount: string, walletAddress: string) => {
      try {
        console.log('amount = ', amount, walletAddress);
        await window.solana.connect();
        const provider = window?.solana;
        if (!provider || !provider.publicKey) {
          Toastify({
            text: 'No Solana Wallet found or Connect to solana wallet',
            className: 'info',
            style: {
              background: 'linear-gradient(to right, #cc3300 0%, #ff3399 100%)',
            },
          }).showToast();
          return;
        }
        const NETWORK = solanaWeb3.clusterApiUrl('mainnet-beta');
        const connection = new solanaWeb3.Connection(NETWORK);
        provider.connect({ onlyIfTrusted: true });
        if (!window.solana) {
          // console.log('NO SOLANA WALLET FOUND!!');
          return;
        }
        // console.log('amount 2 = ', amount);
        const transfer = await solanaWeb3.SystemProgram.transfer({
          fromPubkey: provider.publicKey,
          toPubkey: new solanaWeb3.PublicKey(walletAddress),
          lamports: parseFloat(amount) * 10 ** 9,
        });

        let transaction = new solanaWeb3.Transaction().add(transfer);
        const anyTransaction = transaction;
        anyTransaction.recentBlockhash = (
          await connection.getLatestBlockhash()
        ).blockhash;

        if (!transaction) return;
        transaction.feePayer = provider.publicKey;

        let signed = await provider.signTransaction(transaction);
        let hash = await connection.sendRawTransaction(signed.serialize());
        await connection.confirmTransaction(hash);
        return hash || '';
      } catch (error: any) {
        if (error?.message) {
          FUNCTION_UTILS.showNotification(error?.message);
        }
        console.log('ERROR = ', error);
        return false;
      }
    },
    []
  );

  const swapSolanaChainAssets = useCallback(
    async (
      amount: string,
      walletAddress: string,
      tokenName:
        | 'USDT'
        | 'USDC'
        | 'FTM'
        | 'BETH'
        | 'BUSD'
        | 'AAVE'
        | 'MATIC'
        | 'AXS'
        | 'ANT'
        | 'XRP'
        | 'TRX'
        | 'ADA'
    ) => {
      // console.log('amount = ', amount);
      await window.solana.connect();
      const connection = new SolanaWeb3.Connection(
        SolanaWeb3.clusterApiUrl('mainnet-beta')
      );
      const ownerPublicKey = new SolanaWeb3.PublicKey(walletAddress);

      // public key from the token contract address
      const provider = window?.solana;
      const tokenAddress: string =
        TOKEN_ADDRESS_ALL[tokenName][1399811149].address;
      console.log('tokenAddress = ', tokenAddress, provider);
      const tokenPublicKey = new SolanaWeb3.PublicKey(tokenAddress);

      const balance = await connection.getParsedTokenAccountsByOwner(
        ownerPublicKey,
        { mint: tokenPublicKey }
      );
      const tokenBalance =
        balance.value[0]?.account.data.parsed.info.tokenAmount.uiAmount;
      console.log('tokenBalance = ', tokenBalance);
      // provider
      //   .contract()
      //   .at(tokenAddress)
      //   .then((res: any) => {
      //     console.log('RES = ', res);
      //     const hash = res.hash;
      //     return hash;
      //   })
      //   .catch((err: any) => {
      //     console.log('ERR = ', err);
      //     return false;
      //   });
      // console.log('contract = ', contract);
    },
    []
  );

  const swapTrxChain = useCallback(
    async (amount: string, walletAddress: string) => {
      await window.tronWeb.request({ method: 'tron_requestAccounts' });
      var tronWeb = window.tronWeb;
      const amountSend = tronWeb.toSun(amount);
      var tx = await tronWeb.transactionBuilder.sendTrx(
        walletAddress,
        amountSend,
        window.tronWeb.defaultAddress.base58
      );
      var signedTx = await tronWeb.trx.sign(tx);
      var broastTx = await tronWeb.trx.sendRawTransaction(signedTx);
      const hash = broastTx.txid;
      return hash;
    },
    []
  );
  const swapTrxChainAssets = useCallback(
    async (
      amount: string,
      walletAddress: string,
      tokenName:
        | 'USDT'
        | 'USDC'
        | 'FTM'
        | 'BETH'
        | 'BUSD'
        | 'AAVE'
        | 'MATIC'
        | 'AXS'
        | 'ANT'
        | 'XRP'
        | 'TRX'
        | 'ADA'
    ) => {
      try {
        await window.tronWeb.request({ method: 'tron_requestAccounts' });
        var tronWeb = window.tronWeb;
        const tokenAddress: string =
          TOKEN_ADDRESS_ALL[tokenName][999999999].address;
        const contract = await tronWeb.contract().at(tokenAddress);
        const amountSend = tronWeb.toSun(amount);
        contract
          .transfer(walletAddress, amountSend)
          .send()
          .then((res: any) => {
            console.log('RES = ', res);
            const hash = res.hash ? res.hash : res;
            return hash;
          })
          .catch((err: any) => {
            console.log('ERR = ', err);
            return false;
          });
      } catch (error) {
        return false;
      }
    },
    []
  );

  const swapTerraChain = useCallback(
    async (amount: string, walletAddress: string) => {
      if (parseInt(amount) < 1) {
        Toastify({
          text: 'Please enter amount at least 1.',
          className: 'error',
          style: {
            background: 'linear-gradient(to right, #cc3300 0%, #ff3399 100%)',
          },
        }).showToast();
        return false;
      } else {
        if (!status && !network) {
          Toastify({
            text: 'No Terra Station Wallet found.',
            className: 'error',
            style: {
              background: 'linear-gradient(to right, #cc3300 0%, #ff3399 100%)',
            },
          }).showToast();
          return;
        }
        if (!connectedWallet) {
          connect(ConnectType.EXTENSION, 'station');
        }
        const result = await connectedWallet?.post({
          fee: new Fee(1000000, '200000uluna'),
          msgs: [
            new MsgSend(connectedWallet.walletAddress, walletAddress, {
              uluna: parseInt(amount) * 10 ** 6,
            }),
          ],
        });
        return result?.result?.txhash || '';
      }
    },
    [connect, status, network]
  );

  const getSolanaTokenBalance = useCallback(
    async (
      walletAddress: string,
      tokenName:
        | 'USDT'
        | 'USDC'
        | 'FTM'
        | 'BETH'
        | 'BUSD'
        | 'AAVE'
        | 'MATIC'
        | 'AXS'
        | 'ANT'
        | 'XRP'
        | 'TRX'
        | 'ADA'
    ) => {
      try {
        await window.tronWeb.request({ method: 'tron_requestAccounts' });
        var tronWeb = window.tronWeb;
        const tokenAddress: string =
          TOKEN_ADDRESS_ALL[tokenName][999999999].address;
        const tokenData = TOKEN_ADDRESS_ALL[tokenName][999999999];
        // console.log('tokenAddress = ', tokenAddress);
        const contract = await tronWeb.contract().at(tokenAddress);
        // console.log('contract = ', contract);
        const balance = await contract.balanceOf(walletAddress).call();
        console.log('Balance = ', balance.toString());
        let returnObj = {
          balance: balance.toString(),
          tokenData,
        };
        return returnObj;
      } catch (error) {
        console.log('Error = ', error);
        return false;
      }
    },
    []
  );

  return {
    getSolanaTokenBalance,
    swapETHchain,
    swapETHchainTokens,
    swapSolanaChain,
    swapTrxChain,
    swapTerraChain,
    swapWalletConnect,
    swapMaticChain,
    swapBnbExtensionchain,
    swapBSCchainTokens,
    swapAssetWalletConnect,
    swapTrxChainAssets,
    swapSolanaChainAssets,
    swapTrustchain,
    swapTrustChainTokens,
  };
};
