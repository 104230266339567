import React from 'react';
import { Card, CardContent, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
export function CardComponent({ title, children, secondElement }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between(320, 480));
  return (
    <Card
      style={{
        background:
          ' url(frosted-glass-texture-as-background-frosted-glass-texture-as-background-interior-design-decoration-111091129.jpg), linear-gradient(253.09deg, rgba(217, 217, 217, 0.15) -7.53%, rgba(217, 217, 217, 0) 97.04%)',
        backdropFilter: 'blur(50px)',
        overflow: 'hidden',
        border: '0.5px solid #E2E2E21D',
        marginTop: isMobile ? '20px' : '10px',
        marginBottom: isMobile ? '20px' : '10px',
        marginLeft: isMobile ? '20px' : '',
        marginRight: isMobile ? '20px' : '',
      }}
    >
      <CardContent
        sx={{
          paddingTop: '10px',
          paddingBottom: '10px',
          display: secondElement && 'flex',
          justifyContent: secondElement && 'space-between',
        }}
      >
        <Typography
          sx={{
            fontWeight: '600',
            fontSize: '14px',
          }}
        >
          {title}
        </Typography>
        {secondElement && secondElement}
      </CardContent>
      <div>{children}</div>
    </Card>
  );
}
