import React from 'react';
import {
  Typography,
  Stack,
  Box,
  Button,
  Tabs,
  Tab,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
const DesktopTabs = [
  {
    title: 'Swap',
    value: 0,
  },
  {
    title: 'VentiData',
    value: 1,
  },
  {
    title: 'Swap Info',
    value: 2,
  },
];
const MobileTabs = [
  {
    title: 'Swap',
    value: 0,
  },
  {
    title: 'Charts',
    value: 3,
  },
  {
    title: 'News',
    value: 4,
  },
  {
    title: 'Assets',
    value: 5,
  },
];
export function CoustomTabs({ value, setValue }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between(320, 480));
  const isTabletMini = useMediaQuery(theme.breakpoints.between(600, 1280));

  return (
    <>
      <Stack
        direction='column'
        justifyContent='center'
        alignItems='center'
        sx={{
          width: '100%',
          marginTop: '10px',
          marginBottom: '10px',
          position: isMobile || isTabletMini ? 'fixed' : '',
          bottom: isMobile || isTabletMini ? '50px' : '',
        }}
      >
        <Stack
          direction='row'
          justifyContent='center'
          alignItems='center'
          sx={{
            background: '#303043',
            borderRadius: '12px',
            maxWidth: '610px',
            border: '0.5px solid rgba(226, 226, 226, 0.114)',
          }}
        >
          <Tabs
            value={value}
            onChange={(e, index) => setValue(index)}
            style={{ maxHeight: '20px', overflow: 'hidden' }}
            TabIndicatorProps={{
              style: {
                background: 'none',
              },
            }}
          >
            {!isMobile && !isTabletMini
              ? DesktopTabs.map(col => (
                  <Tab
                    label={col.title}
                    value={col.value}
                    sx={{
                      '&.Mui-selected': {
                        background: '#19192F',
                        color: '#FFF',
                      },
                      borderRadius: '8px',
                      fontSize: '12px',
                      minHeight: '10px',
                      marginLeft: '5px',
                      marginRight: '10px',
                      position: 'relative',
                      top: '5px',
                      left: '5px',
                      right: '10px',
                      bottom: '5px',
                    }}
                  />
                ))
              : MobileTabs.map(col => (
                  <Tab
                    label={col.title}
                    value={col.value}
                    sx={{
                      '&.Mui-selected': {
                        background: '#19192F',
                        color: '#FFF',
                      },
                      borderRadius: '8px',
                      fontSize: '12px',
                      minHeight: '10px',
                      position: 'relative',
                      top: '5px',
                      left: '5px',
                      right: '10px',
                      bottom: '5px',
                    }}
                  />
                ))}
          </Tabs>
        </Stack>
      </Stack>
      <Typography
        sx={{
          fontSize: '12px',
          textAlign: 'center',
          position: isMobile || isTabletMini ? 'fixed' : '',
          bottom: isMobile || isTabletMini ? '10px' : '',
          left: isTabletMini ? '150px' : '',
          right: isTabletMini ? '150px' : '',
        }}
      >
        © All Rights Reserved VentiSwap™ 2022 Support: support@ventiswap.com
        {window.location.hostname === 'd21mg36l4veju.cloudfront.net' ||
        window.location.hostname === 'dev.ventiswap.com' ? (
          <Typography sx={{ fontSize: '12px', display: 'inline-block' }}>
            (Dev v 0.13)
          </Typography>
        ) : (
          <></>
        )}
      </Typography>
    </>
  );
}
