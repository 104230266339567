import { Box, Button, useMediaQuery } from '@mui/material';
import React, { useRef } from 'react';
import { InputToken } from './inputToken';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { useTheme } from '@mui/material/styles';
export function Step1({
  fromCurrency,
  toCurrency,
  amount,
  amountReceive,
  setAmount,
  poolsList,
  poolsListSecondary,
  setToCurrency,
  setFromCurrency,
  handleSwitchBtn,
  setSwitchState,
  setSwapStep,
  web3States,
  fromUsdRate,
  switchState,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between(320, 480));
  const isGenericMobile = useMediaQuery(theme.breakpoints.down(767));
  const is13Pro = useMediaQuery(theme.breakpoints.between(389, 400));
  const isTabletMini = useMediaQuery(theme.breakpoints.between(600, 1280));
  const ref = useRef();

  return (
    <>
      <Box
        component='div'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          overflow: isMobile ? '' : 'hidden',
          height: isMobile || isTabletMini ? '' : '24vh',
        }}
      >
        <InputToken
          currency={fromCurrency}
          value={amount}
          setValue={setAmount}
          list={poolsList}
          setCurrency={setFromCurrency}
          web3States={web3States}
          fromUsdRate={fromUsdRate}
          setSwitchState={setSwitchState}
          switchState={switchState}
        />
        <div id='first' ref={ref} style={{ margin: 'auto' }}>
          {' '}
        </div>

        <InputToken
          readOnly={true}
          currency={toCurrency}
          value={amountReceive}
          setValue={''}
          list={poolsListSecondary}
          setCurrency={setToCurrency}
          fromUsdRate={fromUsdRate}
          valueToSent={amount}
          fromCurrency={fromCurrency}
        />
        <Button
          onClick={() => setSwapStep(1)}
          style={{
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textDecoration: 'none',
            background:
              amount &&
              amount > 0 &&
              !fromCurrency.includes('Select') &&
              !toCurrency.includes('Select')
                ? 'linear-gradient(108.51deg, #F219A1 53.69%, #AD0CF8 100.22%, #FE007E 100.23%)'
                : 'grey',
            fontSize: isMobile || isTabletMini ? '14px' : '12px',
            color: '#FFFFFF',
            height: isMobile || isTabletMini ? '100%' : '5vh',
            borderRadius: '5px',
            width: '90%',
            margin: is13Pro
              ? '25px 20px 25px 20px'
              : isMobile
              ? '25px 20px 25px 20px'
              : isTabletMini
              ? '5px 20px 15px 20px'
              : 'auto',
          }}
          disabled={
            !amount ||
            amount === 0 ||
            fromCurrency.includes('Select') ||
            toCurrency.includes('Select')
          }
        >
          Continue
        </Button>
      </Box>
      <Box
        component='div'
        sx={{
          zIndex: 100,
          cursor: 'pointer',
          background: 'linear-gradient(0deg, #FE007E, #FE007E)',
          position: 'absolute',
          width: isMobile ? '50.79px' : '37.79px',
          height: isMobile ? '50.79px' : '37.79px',
          borderRadius: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          bottom: isMobile ? '23.5vh' : '',
          left: ref && ref.current && ref.current.offsetLeft - 24,
          // left: position?.x ? position.x : isMobile ? '44%' : '14vw',
          // top: position?.y
          //   ? position.y
          //   : is13Pro
          //   ? '30%'
          //   : isGenericMobile
          //   ? '38%'
          //   : '10vh',
          top: isGenericMobile
            ? ref.current.offsetTop - 40
            : ref && ref.current && ref.current.offsetTop - 28,
          // top: is13Pro ? '30%' : isGenericMobile ? '38%' : '10vh',
          order: 4,
        }}
        onClick={handleSwitchBtn}
      >
        <ImportExportIcon
          sx={{
            cursor: 'pointer',
            fontSize: '33px',
          }}
        />
      </Box>
    </>
  );
}
